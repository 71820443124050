import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import MLogo from '../../../../assets/images/logoNew.svg'
import './SideMenuLayout.css'
import rootMenus from './RootMenu'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  selectedItemAtom,
  selectedSubIndexAtom,
  menuOpenIndex,
  collapseStatus,
  aadObjectId,
  loader,
  routesValues,
  isPaneOpen,
  jobTitleSlide,
  tabIndex,
  madeChanges,
} from '../../recoil/atoms/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'
import fetchSelector from '../../recoil/selectors/selectors'
import { useConfirm } from 'material-ui-confirm'
import { ConfirmationPopupContent } from '../confirmationPopup/ConfirmationPopupContent'

const drawerWidth = 250

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: 'rgb(18,40,76)',
  color: 'white',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  backgroundColor: 'rgb(18,40,76)',
  color: 'white',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

function MiniDrawer() {
  const location = useLocation()
  const [open, setOpen] = React.useState(true)
  const [openIndex, setOpenIndex] = useRecoilState(menuOpenIndex)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [hoveredIndex, setHoveredIndex] = React.useState(-1)
  const [selectedItem, setSelectedItem] = useRecoilState(selectedItemAtom)
  const azureID = useRecoilValue(aadObjectId)
  const [userDetailsData, setUserDetailsData] = React.useState(null)
  const getData = useRecoilValue(fetchSelector)
  const [loading, setLoading] = useRecoilState(loader)
  const [routeValues, setRouteValues] = useRecoilState(routesValues)
  const [keysArray, setKeysArray] = React.useState([])
  const panContent = useRecoilValue(isPaneOpen)
  const jobContent = useRecoilValue(jobTitleSlide)
  const [tabSelectedIndex, setTabSelectedIndex] = useRecoilState(tabIndex)
  const APIEndPoints = useAPIEndpoints()
  const confirmAlert = useConfirm()
  const hasChanges = useRecoilValue(madeChanges)
  console.log('hasChanges SideMenu-->', hasChanges)
  React.useEffect(() => {
    setLoading(true)

    const getUserDetails = async () => {
      await getData(
        APIEndPoints.GetUserDetails.method,
        ` ${
          APIEndPoints.GetUserDetails.url
        }?aadObjectID=${azureID}&active=${true}`
      )
        .then((response) => {
          setUserDetailsData([...response?.data])
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          setLoading(false)
        })
    }
    getUserDetails()
  }, [])

  React.useEffect(() => {
    if (userDetailsData) {
      const totalValue = userDetailsData[0]?.roles?.map(
        (role) => role?.aadValue
      )
      setRouteValues(totalValue)
    }
  }, [userDetailsData])
  let rolesArray = []
  if (userDetailsData) {
    rolesArray = userDetailsData[0]?.roles?.map((role) => role?.aadValue)
  }
  const menuAccessRoles = {
    dashBoard: [
      'AccountManagement.Clients.Read',
      'AccountManagement.Clients.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
      'AccountManagement.Vendors.Read',
      'AccountManagement.Vendors.ReadWrite',
      'Magnus.Admin',
      'Agents.Read',
      'Agents.ReadWrite',
      'Audit.Read',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
      'Case.Cases.Read',
      'Case.Cases.ReadWrite',
      'Case.Communication.Read',
      'Case.Communication.ReadWrite',
      'Case.Court.Read',
      'Case.Court.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
      'Case.Quality.Read',
      'Case.Quality.ReadWrite',
      'Case.Read',
      'Case.ReadWrite',
      'Config.Accounts.Read',
      'Config.Accounts.ReadWrite',
      'Config.Agents.Read',
      'Config.Agents.ReadWrite',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.DataReturn.Read',
      'Config.DataReturn.ReadWrite',
      'Config.General.Read',
      'Config.General.ReadWrite',
      'Config.Lettering.Read',
      'Config.Lettering.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
      'Config.Workflow.Read',
      'Config.Workflow.ReadWrite',
      'Dashboard.Read',
      'Dashboard.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
      'DataReturn.Files.Read',
      'DataReturn.Files.ReadWrite',
      'DataReturn.Schedule.Read',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.Template.Read',
      'DataReturn.Template.ReadWrite',
      'Finance.Agent.Read',
      'Finance.Client.Read',
      'Finance.Read',
      'Finance.Vendor.Read',
      'Lettering.AgentLetterOrders.Read',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.AgentLetters.Read',
      'Lettering.AgentLetters.ReadWrite',
      'Lettering.AgentLetterUsage.Read',
      'Lettering.AgentLetterUsage.ReadWrite',
      'Lettering.ClientLetters.Read',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
      'Magnus.User',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Roles.Read',
      'UserManagement.Roles.ReadWrite',
      'UserManagement.Users.Read',
      'UserManagement.Users.ReadWrite',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Stages.Read',
      'Workflow.Stages.ReadWrite',
      'Workflow.Workflows.Read',
      'Workflow.Workflows.ReadWrite',
    ],
    configuration: [
      'Magnus.Admin',
      'Config.All.Read',
      'Config.All.ReadWrite',
      'Config.General.Read',
      'Config.General.ReadWrite',
      'Config.Agents.Read',
      'Config.Agents.ReadWrite',
      'Config.Lettering.Read',
      'Config.Lettering.ReadWrite',
      'Config.Accounts.Read',
      'Config.Accounts.ReadWrite',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.DataReturnFiles.Read',
      'Config.DataReturnFiles.ReadWrite',
      'Config.Workflow.Read',
      'Config.Workflow.ReadWrite',
    ],
    general: [
      'Magnus.Admin',
      'Config.General.Read',
      'Config.General.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    agents: [
      'Magnus.Admin',
      'Config.Agents.Read',
      'Config.Agents.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    lettering: [
      'Magnus.Admin',
      'Config.Lettering.Read',
      'Config.Lettering.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    accounts: [
      'Magnus.Admin',
      'Config.Accounts.Read',
      'Config.Accounts.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],

    caseManagement: [
      'Magnus.Admin',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    configWorkflow: [
      'Magnus.Admin',
      'Config.Workflow.Read',
      'Config.Workflow.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
      'Config.Workflow.Read',
      'Config.Workflow.ReadWrite',
    ],
    dataReturnfiles: [
      'Magnus.Admin',
      'Config.DataReturn.Read',
      'Config.DataReturn.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    agentManagement: ['Magnus.Admin', 'Agents.Read', 'Agents.ReadWrite'],
    accountManagement: [
      'Magnus.Admin',
      'AccountManagement.Vendors.Read',
      'AccountManagement.Vendors.ReadWrite',
      'AccountManagement.Clients.Read',
      'AccountManagement.Clients.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
    ],

    vendors: [
      'Magnus.Admin',
      'AccountManagement.Vendors.Read',
      'AccountManagement.Vendors.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
    ],
    client: [
      'Magnus.Admin',
      'AccountManagement.Clients.Read',
      'AccountManagement.Clients.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
    ],
    userManagement: [
      'Magnus.Admin',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Users.Read',
      'UserManagement.Users.ReadWrite',
      'UserManagement.Roles.Read',
      'UserManagement.Roles.ReadWrite',
    ],

    users: [
      'Magnus.Admin',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Users.Read',
      'UserManagement.Users.ReadWrite',
    ],

    roles: [
      'Magnus.Admin',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Roles.Read',
      'UserManagement.Roles.ReadWrite',
    ],
    letteringMain: [
      'Magnus.Admin',
      'Lettering.AgentLetterOrders.Read',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.AgentLetters.Read',
      'Lettering.AgentLetters.ReadWrite',
      'Lettering.ClientLetters.Read',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.AgentLetterUsage.Read',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],

    agentLetterOrder: [
      'Magnus.Admin',
      'Lettering.AgentLetterOrders.Read',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],

    clientLetter: [
      'Magnus.Admin',
      'Lettering.ClientLetters.Read',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    agentUsage: [
      'Magnus.Admin',
      'Lettering.AgentLetterUsage.Read',
      'Lettering.AgentLetterUsage.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    caseManagementMain: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Cases.Read',
      'Case.Cases.ReadWrite',
      'Case.Communication.Read',
      'Case.Communication.ReadWrite',
      'Case.Court.Read',
      'Case.Court.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
      'Case.Quality.Read',
      'Case.Quality.ReadWrite',
    ],
    cases: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Cases.Read',
      'Case.Cases.ReadWrite',
    ],
    communication: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Communication.Read',
      'Case.Communication.ReadWrite',
    ],
    courtBooking: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Court.Read',
      'Case.Court.ReadWrite',
    ],
    planning: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
    ],
    appointmentScheduler: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
    ],
    qualityControl: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Quality.Read',
      'Case.Quality.ReadWrite',
    ],
    workflowMain: [
      'Magnus.Admin',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Workflows.Read',
      'Workflow.Workflows.ReadWrite',
      'Workflow.Stages.Read',
      'Workflow.Stages.ReadWrite',
    ],
    workflow: [
      'Magnus.Admin',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Workflows.Read',
      'Workflow.Workflows.ReadWrite',
    ],
    stages: [
      'Magnus.Admin',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Stages.Read',
      'Workflow.Stages.ReadWrite',
    ],
    finance: [
      'Magnus.Admin',
      'Finance.Read',
      'Finance.Agent.Read',
      'Finance.Client.Read',
      'Finance.Vendor.Read',
    ],
    agentPayment: ['Magnus.Admin', 'Finance.Read', 'Finance.Agent.Read'],
    clientInvoice: ['Magnus.Admin', 'Finance.Read', 'Finance.Client.Read'],
    financeVendor: ['Magnus.Admin', 'Finance.Read', 'Finance.Vendor.Read'],
    // dataReturn: ['Magnus.Admin'],
    dataReturnMain: [
      'Magnus.Admin',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
      'DataReturn.Files.Read',
      'DataReturn.Files.ReadWrite',
      'DataReturn.Schedule.Read',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.Template.Read',
      'DataReturn.Template.ReadWrite',
    ],
    templateReturn: [
      'Magnus.Admin',
      'DataReturn.Template.Read',
      'DataReturn.Template.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
    ],
    scheduleDataReturn: [
      'Magnus.Admin',
      'DataReturn.Schedule.Read',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
    ],
    auditLog: ['Magnus.Admin', 'Audit.Read'],
    returnFile: [
      'Magnus.Admin',
      'DataReturn.Files.Read',
      'DataReturn.Files.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
    ],
  }

  // const keysArray = Object.keys(accessRoles)
  const accessRoles = {}
  React.useEffect(() => {
    if (rolesArray && rolesArray.length > 0) {
      Object.keys(menuAccessRoles).forEach((menuKey) => {
        const allowedRoles = menuAccessRoles[menuKey]
        const hasAccess = rolesArray.some((role) => allowedRoles.includes(role))

        if (hasAccess) {
          accessRoles[menuKey] = allowedRoles.filter((role) =>
            rolesArray.includes(role)
          )
        }
      })
    } else {
      console.error('rolesArray is either undefined or empty.')
    }
    setKeysArray(() => Object.keys(accessRoles))
  }, [userDetailsData])

  function filterMenu(menu) {
    if (keysArray?.includes(menu.menuItemId)) {
      if (menu.menus) {
        menu.menus = menu.menus.filter((subMenu) =>
          keysArray.includes(subMenu.menuItemId)
        )
      }
      return true
    }
    return false
  }
  const filteredRootMenus = rootMenus.filter(filterMenu)

  const [selectedSubIndex, setSelectedSubIndex] =
    useRecoilState(selectedSubIndexAtom)
  const initialCollapseState = filteredRootMenus.reduce((acc, menu) => {
    if (menu.menuItemId) {
      acc[menu.menuItemId] = false
    }
    return acc
  }, {})
  const [menuState, setMenuState] = useRecoilState(collapseStatus)
  const navigate = useNavigate()
  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    const currentPath = location?.pathname
    const menuItemIndex = filteredRootMenus.findIndex(
      (route) => route?.url === currentPath
    )

    let submenuItemIndex = -1
    let parentMenuIndex = -1
    filteredRootMenus.forEach((route, index) => {
      if (route?.menus) {
        const subIndex = route.menus.findIndex(
          (submenu) => submenu?.url === currentPath
        )
        if (subIndex !== -1) {
          submenuItemIndex = subIndex
          parentMenuIndex = index
        }
      }
    })

    const urlPath = location?.pathname
    const trimmedPath = urlPath.startsWith('/') ? urlPath.slice(1) : urlPath

    if (submenuItemIndex !== -1) {
      if (
        !urlPath.startsWith('/edit') &&
        !urlPath.startsWith('/add') &&
        !urlPath.startsWith('/view')
      ) {
        setSelectedSubIndex(trimmedPath)
      }
      setSelectedItem(parentMenuIndex)
      setMenuState(filteredRootMenus[parentMenuIndex]?.menuItemId)
    } else if (menuItemIndex !== -1) {
      setSelectedItem(menuItemIndex)
      setMenuState((prevOpenMenu) =>
        prevOpenMenu === filteredRootMenus[menuItemIndex]?.menuItemId
          ? null
          : filteredRootMenus[menuItemIndex]?.menuItemId
      )
    }
  }, [location.pathname])

  const clearSubmenuSessionStorage = () => {
    sessionStorage.clear('selectedSubIndex')
    sessionStorage.removeItem('collapseStates')
  }

  const handleClick = (index, item, menuItemId, url) => (event) => {
    const hasSubmenus = filteredRootMenus[index]?.menus?.length > 0
    if (hasSubmenus) {
      setSelectedItem(index)
    }
    setTabSelectedIndex(0)
    const newIndex = openIndex === index ? -1 : index
    // sessionStorage.setItem('openIndex', newIndex)
    setOpenIndex(newIndex)

    if (!hasSubmenus) {
      clearSubmenuSessionStorage()
      sessionStorage.clear()
    }

    if (hasSubmenus) {
      if (!open) {
        handlePopoverOpen(event, index)
      }
    }

    setMenuState((prevOpenMenu) =>
      prevOpenMenu === menuItemId ? null : menuItemId
    )
    const urlPath = location?.pathname
    const trimmedPath = urlPath.startsWith('/') ? urlPath.slice(1) : urlPath
    if (hasSubmenus) {
      if (
        !urlPath.startsWith('/edit') &&
        !urlPath.startsWith('/view') &&
        !urlPath.startsWith('/add')
      ) {
        setSelectedSubIndex(trimmedPath)
      }
    }
  }

  const handlePopoverOpen = (event, index) => {
    if (hoveredIndex !== index) {
      setSelectedSubIndex(-1)
    }
    setAnchorEl(event.currentTarget)
    setHoveredIndex(index)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setHoveredIndex(-1)
  }

  const handleSubItemClick = (event, index, menuItemId) => {
    setSelectedSubIndex(menuItemId)
    const url = filteredRootMenus[selectedItem]?.menus[index]?.url
    navigate(url)
    // sessionStorage.setItem('selectedSubIndex', JSON?.stringify({ index, url }))
    setTabSelectedIndex(0)
  }

  const handleMenuClick = (url, index) => {
    const hasSubmenus = filteredRootMenus[index]?.menus?.length > 0
    if (!hasSubmenus) {
      navigate(url)
    }
  }
  const openPopover = Boolean(anchorEl)
  const checkAllowNavigation = () => {
    const dataSaveWarnMessage =
      'Your work is unsaved. Are you sure you want to cancel?'
    if (hasChanges) {
      return confirmAlert({
        ...ConfirmationPopupContent({ message: dataSaveWarnMessage }),
      })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    } else return true
  }
  return (
    <div
      className={`MuiDrawer pointer_${
        panContent || jobContent ? 'disable' : ''
      } drawer_${open ? 'open' : 'close'}`}
    >
      <Drawer className="mainMUIDrawer" variant="permanent" open={open}>
        <DrawerHeader className="sideMenu-header">
          <div className="sideNav-Header">
            <IconButton
              className="menuBtn"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <div className="logo-img">
              <img
                className="logo"
                style={{
                  ...(!open && { display: 'none' }),
                }}
                src={MLogo}
                alt="Logo"
              />
            </div>
          </div>
        </DrawerHeader>
        <List>
          {filteredRootMenus.map(
            (
              {
                name,
                menus = [],
                img: ImageComponent,
                imgInactive: ImageComponentInactive,
                url,
                menuItemId,
                backGround,
              },
              index,
              item
            ) => (
              <>
                <Tooltip title={!open ? name : ''} placement="right">
                  <ListItem
                    disablePadding
                    selected={selectedItem === index}
                    onClick={handleClick(index, item, menuItemId, url)}
                    // onClick={() => {
                    //   const hasSubmenus =
                    //     filteredRootMenus[index]?.menus?.length > 0
                    //   const allow = !hasSubmenus && checkAllowNavigation()
                    //   console.log('Line732', allow, hasSubmenus)
                    //   // if (allow || hasSubmenus) {
                    //   handleClick(index, item, menuItemId, url)
                    //   // }
                    // }}
                    sx={{
                      display: 'block',
                      '&.Mui-selected': {
                        backgroundColor:
                          backGround === 'white'
                            ? 'white'
                            : 'rgba(12, 24, 54, 1)',
                        color: backGround === 'white' ? 'black' : 'white',
                        borderLeft: '5px solid rgb(2,119,189)',
                      },
                    }}
                  >
                    <ListItemButton
                      className="listBtn"
                      sx={{
                        minHeight: 48,
                        justifyContent: 'space-between',
                        px: 2.5,
                      }}
                      onClick={async () => {
                        console.log('Line753')
                        const allow =
                          menus?.length < 1 && (await checkAllowNavigation())
                        allow && handleMenuClick(url, index)
                      }}
                    >
                      <ListItemIcon>
                        <img
                          className="menuListIcon"
                          src={
                            selectedItem === index
                              ? ImageComponent
                              : ImageComponentInactive
                          }
                          // src={ImageComponent}
                          alt={name}
                        />
                      </ListItemIcon>
                      <ListItemText primary={name} />
                      {menus?.length > 0 &&
                        (openIndex === index ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                {menus?.length > 0 &&
                  (!open ? (
                    <Popover
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      disableRestoreFocus
                      className="popUp-Menu"
                    >
                      <List>
                        {filteredRootMenus[hoveredIndex]?.menus?.map(
                          ({ name, url = '/' }, i) => (
                            <ListItem
                              key={i}
                              selected={selectedSubIndex === menuItemId}
                              onClick={async (event) => {
                                console.log('Line799')
                                const allow = await checkAllowNavigation()
                                return (
                                  allow &&
                                  handleSubItemClick(event, i, menuItemId)
                                )
                              }}
                              // sx={{
                              //   '&.Mui-selected': {
                              //     color: 'black',
                              //   },
                              // }}
                              className={
                                selectedSubIndex === menuItemId
                                  ? 'selected-subitem'
                                  : ''
                              }
                            >
                              <ListItemButton
                                onClick={async () => {
                                  console.log('Line819')
                                  const allow = await checkAllowNavigation()
                                  if (allow) {
                                    navigate(url)
                                    setAnchorEl(null)
                                  }
                                }}
                                sx={{ pl: 4 }}
                              >
                                <ListItemText primary={name} />
                              </ListItemButton>
                            </ListItem>
                          )
                        )}
                      </List>
                    </Popover>
                  ) : (
                    <Collapse
                      // in={collapseOpen[menuItemId] || false}
                      in={menuState === menuItemId}
                      timeout="auto"
                      unmountOnExit
                      // onEnter={() => navigateToFirstSubitem(index)}
                    >
                      <List
                        className="collapseList"
                        component="div"
                        disablePadding
                      >
                        {menus.map(({ name, menuItemId, url = '/' }, i) => {
                          return (
                            <Tooltip
                              title={!open ? name : ''}
                              placement="right"
                            >
                              <ListItem
                                selected={selectedSubIndex === menuItemId}
                                onClick={async (event) => {
                                  const allow = await checkAllowNavigation()
                                  console.log('Line857', allow)
                                  return (
                                    allow &&
                                    handleSubItemClick(event, i, menuItemId)
                                  )
                                }}
                                className={
                                  selectedSubIndex === menuItemId
                                    ? 'selected-subitem'
                                    : ''
                                }
                              >
                                <ListItemButton
                                  onClick={async () => {
                                    const allow = await checkAllowNavigation()
                                    console.log('Line879', allow)
                                    return allow && navigate(url)
                                  }}
                                  sx={{ pl: 4 }}
                                >
                                  <ListItemText primary={name} />
                                </ListItemButton>
                              </ListItem>
                            </Tooltip>
                          )
                        })}
                      </List>
                    </Collapse>
                  ))}
              </>
            )
          )}
        </List>
      </Drawer>
    </div>
  )
}
export default MiniDrawer
