import React from 'react'
import Button from '@mui/material/Button'
import './Button.css'
function ButtonComponent({
  variant,
  color,
  text,
  label,
  className,
  onClick,
  startIcon,
  disabled,
  size,
  endIcon,
}) {
  return (
    <div className="buttonContainer">
      <Button
        variant={variant}
        color={color}
        label={label}
        className={`button_type_${className}`}
        //withBackground
        //withoutBackground
        //disabled
        onClick={onClick}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size}
      >
        {text}
      </Button>
    </div>
  )
}

export default ButtonComponent
