import React, { useEffect } from 'react'
import backgroundImage from '../../../assets/images/magnus_landingIMG_new.jpg'
import M_Logo from '../../../assets/images/loginPage_M-logo.png'
import magnusLogo_new from '../../../assets/images/magnusLogo_new.png'
import './LoginPage.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  loginRequestData,
  sessionExpiredStatus,
} from '../../components/recoil/atoms/atoms'
import MarstonIcon from '../../../assets/images/marstonIcon_new.png'
import MS_icon from '../../../assets/images/MS_icon.svg'
import VectorIcon from '../../../assets/images/VectorIcon_new.png'
import { useMsal } from '@azure/msal-react'

const LoginPage = () => {
  const { instance, accounts } = useMsal()
  const AuthScope = useRecoilValue(loginRequestData)
  const [sessionExpired, setSessionExpired] =
    useRecoilState(sessionExpiredStatus)

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...AuthScope,
        prompt: 'create',
      })
      .catch((error) => console.error('error-->', error))
  }
  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    setSessionExpired(false)
  }, [])
  return (
    <div
      className="loginPage-container"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    >
      <div className="logo-container">
        <img src={M_Logo} alt="M Logo" />
        <div className="ADO_login-container">
          <div className="ADO_login-container-child">
            <img src={magnusLogo_new} alt="M Logo" className="magnusLogo_new" />
            <div className="loginPage-child signIn" onClick={handleRedirect}>
              <img src={MS_icon} className="MS_icon" alt="MS_icon" />
              <div className="signIn-title">Sign in with Microsoft</div>
              <img src={VectorIcon} className="VectorIcon" alt="VectorIcon" />
            </div>
            <div className="loginPage-child">
              <div className="poweredBy-title">Powered by</div>
              <img
                src={MarstonIcon}
                className="MarstonIcon"
                alt="MarstonIcon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
