import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import '../loading/LoadingComponent.css'

const SpinnerComponent = () => {
  const handleClose = () => {
    // setLoading(false)
  }
  return (
    <Backdrop
      id="LoaderOnIndexJS"
      className="loadingControl"
      open={true}
      onClick={handleClose}
      sx={{ color: '#fff' }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default SpinnerComponent
