import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { RecoilRoot } from 'recoil'
import { msalConfig } from './configs/authConfigs'
import ConfigLoader from './configs/ConfigLoader'
import SignInBridge from './views/pages/signInBridge/SignInBridge'

const root = ReactDOM.createRoot(document.getElementById('root'))
const getRedirectUri = () => {
  const currentUrl = window.location.hostname
  console.log('CurrentUrl-->', currentUrl)
  if (currentUrl === 'localhost') {
    return 'http://localhost:3000/'
  } else if (
    currentUrl === 'as-engage-magnus-dev-frontend-001.azurewebsites.net'
  ) {
    return 'https://as-engage-magnus-dev-frontend-001.azurewebsites.net/'
  } else if (currentUrl === 'dev.magnus-cms.com') {
    return 'https://dev.magnus-cms.com/'
  } else if (
    currentUrl === 'as-engage-magnus-uat-frontend-001.azurewebsites.net'
  ) {
    return 'https://as-engage-magnus-uat-frontend-001.azurewebsites.net/'
  } else if (currentUrl === 'uat.magnus-cms.com') {
    return 'https://uat.magnus-cms.com/'
  }
  return null
}
console.log('RedirectURL-->', getRedirectUri())
root.render(
  <RecoilRoot>
    <ConfigLoader
      ready={(props) => {
        const authorityURL = `${props?.VariableSettings?.B2BAuthority}`
        msalConfig.auth.redirectUri =
          getRedirectUri() || props?.VariableSettings?.RedirectUIURL
        msalConfig.auth.clientId = props?.VariableSettings?.B2BClientID
        msalConfig.auth.authority = authorityURL
        msalConfig.auth.knownAuthorities = [authorityURL]
        msalConfig.auth.muiGridKey = props?.VariableSettings?.MUIGridKey
        msalConfig.auth.apiURL = props?.VariableSettings?.CoreDataUIURL
        msalConfig.auth.AuthScope = props?.VariableSettings?.AuthScope
        return <SignInBridge appConfig={props} msalConfig={msalConfig} />
      }}
    />
  </RecoilRoot>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
