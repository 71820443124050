import { redirectApiUrl } from '../../views/components/recoil/atoms/atoms'
import { useRecoilValue } from 'recoil'

const useAPIEndpoints = () => {
  const api = useRecoilValue(redirectApiUrl)
  const APIEndPoints = {
    GetUsers: {
      method: 'get',
      url: `${api}User/GetUsers`,
    },
    GetAADUser: {
      method: 'get',
      url: `${api}User/GetAADUser`,
    },
    GetJobTitles: {
      method: 'get',
      url: `${api}User/GetJobTitles`,
    },
    GetJobTitleDetails: {
      method: 'get',
      url: `${api}User/GetJobTitleDetails`,
    },
    GetRegion: {
      method: 'get',
      url: `${api}Agent/GetRegions`,
    },
    GetEquipmentTypes: {
      method: 'get',
      url: `${api}Agent/GetEquipmentTypes`,
    },
    GetEmploymentTypes: {
      method: 'get',
      url: `${api}Agent/GetEmploymentTypes`,
    },
    GetStatuses: {
      method: 'get',
      url: `${api}Agent/GetStatuses`,
    },
    GetSkillSets: {
      method: 'get',
      url: `${api}Agent/GetSkillSets`,
    },
    GetNoteTypes: {
      method: 'get',
      url: `${api}Agent/GetNoteTypes`,
    },
    GetMobileApps: {
      method: 'get',
      url: `${api}Agent/GetMobileApps`,
    },
    GetTraining: {
      method: 'get',
      url: `${api}Agent/GetTraining`,
    },
    GetAgentDetails: {
      method: 'get',
      url: `${api}Agent/GetAgentDetails`,
    },
    GetAgents: {
      method: 'get',
      url: `${api}Agent/GetAgents`,
    },
    GetUserDetails: {
      method: 'get',
      url: `${api}User/GetUserDetails`,
    },
    GetRoles: {
      method: 'get',
      url: `${api}User/GetRoles`,
    },
    GetJobTitleRoles: {
      method: 'get',
      url: `${api}User/GetJobTitleRoles`,
    },
    GetRoleDetails: {
      method: 'get',
      url: `${api}User/GetRoleDetails`,
    },
    GetVendorData: {
      method: 'get',
      url: `${api}vendors`,
    },
    AddVendorData: {
      method: 'post',
      url: `${api}vendors`,
    },
    GetCountries: {
      method: 'get',
      url: `${api}Agent/GetCountries`,
    },
    GetClientTypes: {
      method: 'get',
      url: `${api}Client/GetClientTypes`,
    },
    GetVendorTypes: {
      method: 'get',
      url: `${api}vendors/types`,
    },
    GetUserRoles: {
      method: 'get',
      url: `${api}User/GetUserRoles`,
    },
    GetVendorDetails: {
      method: 'get',
      url: `${api}vendors`,
    },
    GetVendorDocumentTypes: {
      method: 'get',
      url: `${api}vendors/document-types`,
    },
    GetVendorDocuments: {
      method: 'get',
      url: `${api}vendors/documents`,
    },
    GetClientDocuments: {
      method: 'get',
      url: `${api}Client/GetClientDocuments`,
    },
    GetEmploymentTypeDetails: {
      method: 'get',
      url: `${api}Agent/GetEmploymentTypeDetails`,
    },
    GetClientData: {
      method: 'get',
      url: `${api}Client/GetClients`,
    },
    GetClientDetails: {
      method: 'get',
      url: `${api}Client/GetClientDetails?clientID`,
    },
    GetClientDocumentTypes: {
      method: 'get',
      url: `${api}Client/GetDocumentTypes`,
    },
    GetWorkflowServices: {
      method: 'get',
      url: `${api}workflows`,
    },
    GetVendorStatus: {
      method: 'get',
      url: `${api}vendors/status-types`,
    },
    GetSkillSetDetails: {
      method: 'get',
      url: `${api}Agent/GetSkillSetDetails`,
    },
    GetStatusDetails: {
      method: 'get',
      url: `${api}Agent/GetStatusDetails`,
    },
    GetNoteTypeDetails: {
      method: 'get',
      url: `${api}Agent/GetNoteTypeDetails`,
    },
    GetTrainingDetails: {
      method: 'get',
      url: `${api}Agent/GetTrainingDetails`,
    },
    GetMobileAppDetails: {
      method: 'get',
      url: `${api}Agent/GetMobileAppDetails`,
    },
    GetCoverageAreas: {
      method: 'get',
      url: `${api}Agent/GetCoverageAreas`,
    },
    GetClients: {
      method: 'get',
      url: `${api}Client/GetClients`,
    },
    GetDocuments: {
      method: 'get',
      url: `${api}Agent/GetDocuments`,
    },
    GetEquipmentStatuses: {
      method: 'get',
      url: `${api}Agent/GetEquipmentStatusTypes`,
    },
    GetClientTypeValue: {
      method: 'get',
      url: `${api}Client/GetClientTypes`,
    },
    GetAccClientStatus: {
      method: 'get',
      url: `${api}Client/GetClientStatusTypes`,
    },
    GetAccVendorStatus: {
      method: 'get',
      url: `${api}vendors/status-types`,
    },
    GetAgentLetterOrders: {
      method: 'get',
      url: `${api}Lettering/GetAgentLetterOrders`,
    },
    GetAgentLetterOrderDetails: {
      method: 'get',
      url: `${api}Lettering/GetAgentLetterOrderDetails`,
    },
    GetClientDetailsType: {
      method: 'get',
      url: `${api}Client/GetClientTypeDetails`,
    },
    GetClientStatusDetails: {
      method: 'get',
      url: `${api}Client/GetClientStatusTypeDetails`,
    },
    GetVendorStatusDetails: {
      method: 'get',
      url: `${api}vendors/status-types`,
    },
    GetCategories: {
      method: 'get',
      url: `${api}Lettering/GetCategories`,
    },
    GetCategoryDetails: {
      method: 'get',
      url: `${api}Lettering/GetCategoryDetails`,
    },
    GetDistributionTypes: {
      method: 'get',
      url: `${api}Lettering/GetDistributionTypes`,
    },
    GetDistributionTypeDetails: {
      method: 'get',
      url: `${api}Lettering/GetDistributionTypeDetails`,
    },
    GetPackTypes: {
      method: 'get',
      url: `${api}Lettering/GetPackTypes`,
    },
    GetPackTypeDetails: {
      method: 'get',
      url: `${api}Lettering/GetPackTypeDetails`,
    },
    GetBookmarkMappings: {
      method: 'get',
      url: `${api}Lettering/GetBookmarkMappings`,
    },
    GetBookmarkMappingDetails: {
      method: 'get',
      url: `${api}Lettering/GetBookmarkMappingDetails`,
    },
    GetClientWorkflows: {
      method: 'get',
      url: `${api}workflows/client-workstream-workflow?ClientWorkstreamID`,
    },
    GetClientStatusTypes: {
      method: 'get',
      url: `${api}Client/GetClientStatusTypes`,
    },
    GetVendorTypeValue: {
      method: 'get',
      url: `${api}vendors/types`,
    },
    GetCoverageAreaNames: {
      method: 'get',
      url: `${api}Agent/GetCoverageAreaNames`,
    },
    GetRegionDetails: {
      method: 'get',
      url: `${api}Agent/GetRegionDetails`,
    },
    GetCoverageAreaDetails: {
      method: 'get',
      url: `${api}Agent/GetCoverageAreaDetails`,
    },
    GetDocumentTypeDetails: {
      method: 'get',
      url: `${api}Client/GetDocumentTypeDetails`,
    },
    GetVendorTypeDetails: {
      method: 'get',
      url: `${api}vendors/document-types`,
    },
    // GetVendorTypeValue: {
    //   method: 'get',
    //   url: `${api}vendors/types`,
    // },
    GetDocumentDetails: {
      method: 'get',
      url: `${api}Agent/GetDocumentDetails`,
    },
    GetEquipmentTypeDetailsView: {
      method: 'get',
      url: `${api}Agent/GetEquipmentTypeDetails`,
    },
    GetCaseDetails: {
      method: 'get',
      url: `${api}Case/GetCaseDetails`,
    },
    GetWorkflowCategories: {
      method: 'get',
      url: `${api}workflows/stage/categories`,
    },
    GetWorkflowStages: {
      method: 'get',
      url: `${api}workflows/stages`,
    },
    GetAllWorkflowsInSystem: {
      method: 'get',
      url: `${api}workflows`,
    },
    GetWorkflowWorkType: {
      method: 'get',
      url: `${api}workflows/worktype?enabled=true`,
    },
    GetWorkflowBranchConditions: {
      method: 'get',
      url: `${api}workflows/stage/branches/conditions`,
    },
    GetClientLogoSAS: {
      method: 'get',
      url: `${api}Client/GetClientLogoUploadSASToken`,
    },
    GetClientDocSAS: {
      method: 'get',
      url: `${api}Client/GetClientDocumentUploadSASToken`,
    },
    GetVendorLogoSAS: {
      method: 'get',
      url: `${api}vendors/logo-sas-token`,
    },
    GetVendorDocSAS: {
      method: 'get',
      url: `${api}vendors/documents/sas-token`,
    },
    GetAgentLogoSAS: {
      method: 'get',
      url: `${api}Agent/GetAgentDocumentUploadSASToken`,
    },
    ReadDocSAS: {
      method: 'get',
      url: `${api}File/GenerateReadSAS`,
    },
    GetAgentEquipment: {
      method: 'get',
      url: `${api}Agent/GetAgentEquipment`,
    },
    GetAllAgentStatus: {
      method: 'get',
      url: `${api}Agent/GetStatuses`,
    },
    GetSingleAgentStatus: {
      method: 'get',
      url: `${api}Agent/GetAgentStatuses`,
    },
    AddClient: {
      method: 'post',
      url: `${api}Client/AddClient`,
    },
    UpdateClient: {
      method: 'put',
      url: `${api}Client/UpdateClient`,
    },
    AddClientContacts: {
      method: 'post',
      url: `${api}Client/AddClientContacts`,
    },
    UpdateClientContacts: {
      method: 'put',
      url: `${api}Client/UpdateClientContacts`,
    },
    AddClientWorkflow: {
      method: 'post',
      url: `${api}workflows/client-workflow`,
    },
    AddClientDoc: {
      method: 'post',
      url: `${api}Client/AddClientDocuments`,
    },
    UpdateClientDoc: {
      method: 'put',
      url: `${api}Client/UpdateClientDocuments`,
    },
    AddWorkflow: {
      method: 'post',
      url: `${api}workflows`,
    },
    ConfigAddRegion: {
      method: 'post',
      url: `${api}Agent/AddRegion`,
    },
    ConfigAddCoverageArea: {
      method: 'post',
      url: `${api}Agent/AddCoverageArea`,
    },
    GetCaseStatusesId: {
      method: 'get',
      url: `${api}Case/GetCaseStatuses`,
    },
    GetProperties: {
      method: 'get',
      url: `${api}workflows/properties`,
    },
    GetBranches: {
      method: 'get',
      url: `${api}workflows/branches`,
    },
    GetAddStages: {
      method: 'post',
      url: `${api}workflows/stages`,
    },
    GetAddProperties: {
      method: 'post',
      url: `${api}workflows/stages/required-properties`,
    },
    GetAddBranches: {
      method: 'post',
      url: `${api}workflows/stages/required-branches`,
    },
    GetAddEquipment: {
      method: 'post',
      url: `${api}Agent/AddEquipmentType`,
    },
    GetUpdateEquipment: {
      method: 'put',
      url: `${api}Agent/UpdateEquipmentType`,
    },
    GetAddEmployee: {
      method: 'post',
      url: `${api}Agent/AddEmploymentType`,
    },
    GetUpdateEmployee: {
      method: 'put',
      url: `${api}Agent/UpdateEmploymentType`,
    },
    GetAddDocument: {
      method: 'post',
      url: `${api}Agent/AddDocument`,
    },
    GetUpdateDocument: {
      method: 'put',
      url: `${api}Agent/UpdateDocument`,
    },
    GetAddMobile: {
      method: 'post',
      url: `${api}Agent/AddMobileApp`,
    },
    GetUpdateMobile: {
      method: 'put',
      url: `${api}Agent/UpdateMobileApp`,
    },
    GetAddNotes: {
      method: 'post',
      url: `${api}Agent/AddNoteType`,
    },
    GetUpdateNotes: {
      method: 'put',
      url: `${api}Agent/updateNoteType`,
    },
    GetAddSkillSet: {
      method: 'post',
      url: `${api}Agent/AddSkillSet`,
    },
    GetUpdateSkillSet: {
      method: 'put',
      url: `${api}Agent/UpdateSkillSet`,
    },
    GetAddStatus: {
      method: 'post',
      url: `${api}Agent/AddStatus`,
    },
    GetUpdateStatus: {
      method: 'put',
      url: `${api}Agent/UpdateStatus`,
    },
    GetAddTraining: {
      method: 'post',
      url: `${api}Agent/AddTraining`,
    },
    GetUpdateTraining: {
      method: 'put',
      url: `${api}Agent/UpdateTraining`,
    },
    GetAddClientType: {
      method: 'post',
      url: `${api}Client/AddClientType`,
    },
    GetUpdateClientType: {
      method: 'put',
      url: `${api}Client/UpdateClientType`,
    },
    GetAddClientDocument: {
      method: 'post',
      url: `${api}Client/AddClientDocumentType`,
    },
    GetUpdateClientDocument: {
      method: 'put',
      url: `${api}Client/UpdateClientDocumentType`,
    },
    GetAddClientStatus: {
      method: 'post',
      url: `${api}Client/AddStatus`,
    },
    GetUpdateClientStatus: {
      method: 'put',
      url: `${api}Client/UpdateClientStatus`,
    },
    GetAddVendorDocument: {
      method: 'post',
      url: `${api}vendors/document-types`,
    },
    GetUpdateVendorDocument: {
      method: 'put',
      url: `${api}vendors/document-types`,
    },
    GetAddVendorStatus: {
      method: 'post',
      url: `${api}vendors/status-types`,
    },
    GetUpdateVendorStatus: {
      method: 'put',
      url: `${api}vendors/status-types`,
    },
    GetAddVendorType: {
      method: 'post',
      url: `${api}vendors/types`,
    },
    GetUpdateVendorType: {
      method: 'put',
      url: `${api}vendors/types`,
    },
    GetUpdateEquipmentRegion: {
      method: 'put',
      url: `${api}Agent/UpdateRegion`,
    },
    GetCaseAgents: {
      method: 'post',
      url: `${api}Case/GetCases`,
    },
    GetOrderStatuses: {
      method: 'get',
      url: `${api}Lettering/GetOrderStatuses`,
    },
    AddOrderStatus: {
      method: 'post',
      url: `${api}Lettering/AddOrderStatus`,
    },
    UpdateOrderStatus: {
      method: 'put',
      url: `${api}Lettering/UpdateOrderStatus`,
    },
    GetOrderStatusTypeDetails: {
      method: 'get',
      url: `${api}Lettering/GetOrderStatusTypeDetails`,
    },
    GetAddAgent: {
      method: 'post',
      url: `${api}Agent/AddAgent`,
    },
    GetAddAgentCoverageArea: {
      method: 'post',
      url: `${api}Agent/AddAgentCoverageAreas`,
    },
    GetAddAgentSkillSets: {
      method: 'post',
      url: `${api}Agent/AddAgentSkillSets`,
    },
    GetAddAgentTrainings: {
      method: 'post',
      url: `${api}Agent/AddAgentTrainings`,
    },
    GetAddAgentEquipment: {
      method: 'post',
      url: `${api}Agent/AddAgentEquipment`,
    },
    GetAddAgentsCompanyIDs: {
      method: 'post',
      url: `${api}Agent/AddAgentsCompanyIDs`,
    },
    GetAddAgentsMobileApps: {
      method: 'post',
      url: `${api}Agent/AddAgentsMobileApps`,
    },
    GetAddAgentDocuments: {
      method: 'post',
      url: `${api}Agent/AddAgentDocuments`,
    },
    GetAddAgentNote: {
      method: 'post',
      url: `${api}Agent/AddAgentNote`,
    },
    GetAddAgentEmploymentType: {
      method: 'post',
      url: `${api}Agent/AddAgentEmploymentType`,
    },
    GetAddAgentStatus: {
      method: 'post',
      url: `${api}Agent/AddAgentStatus`,
    },
    GetAddAgentDetails: {
      method: 'post',
      url: `${api}Agent/AddAgentDetails`,
    },
    UpdateAgentsCoverageAreas: {
      method: 'put',
      url: `${api}Agent/UpdateAgentsCoverageAreas`,
    },
    UpdateAgentsSkillSets: {
      method: 'put',
      url: `${api}Agent/UpdateAgentsSkillSets`,
    },
    UpdateAgentsCompanyIDs: {
      method: 'put',
      url: `${api}Agent/UpdateAgentsCompanyIDs`,
    },
    UpdateAgentsMobileApps: {
      method: 'put',
      url: `${api}Agent/UpdateAgentsMobileApps`,
    },
    GetAddUserDelegation: {
      method: 'post',
      url: `${api}User/AddUserDelegation`,
    },
    GetAddUser: {
      method: 'post',
      url: `${api}User/AddUser`,
    },
    GetAddUserRoles: {
      method: 'post',
      url: `${api}User/AddUserRoles`,
    },
    UpdateUserRoles: {
      method: 'put',
      url: `${api}User/UpdateUserRoles`,
    },
    GetAddUserDetails: {
      method: 'post',
      url: `${api}User/AddUserDetails`,
    },
    GetAddJobTitle: {
      method: 'post',
      url: `${api}User/AddJobTitle`,
    },
    GetAddJobTitleRoles: {
      method: 'post',
      url: `${api}User/AddJobTitleRoles`,
    },
    GetUpdateJobTitle: {
      method: 'put',
      url: `${api}User/UpdateJobTitle`,
    },
    GetUpdateRole: {
      method: 'put',
      url: `${api}User/UpdateRole`,
    },
    GetAddRole: {
      method: 'post',
      url: `${api}User/AddRole`,
    },
    GetAddCategory: {
      method: 'post',
      url: `${api}Lettering/AddCategory`,
    },
    GetUpdateCategory: {
      method: 'put',
      url: `${api}Lettering/UpdateCategory`,
    },
    GetAddBookmarkMapping: {
      method: 'post',
      url: `${api}Lettering/AddBookmarkMapping`,
    },
    GetUpdateBookmarkMapping: {
      method: 'put',
      url: `${api}Lettering/UpdateBookmarkMapping`,
    },
    GetUpdateDistributionType: {
      method: 'put',
      url: `${api}Lettering/UpdateDistributionType`,
    },
    GetAddDistributionType: {
      method: 'post',
      url: `${api}Lettering/AddDistributionType`,
    },
    GetUpdatePackType: {
      method: 'put',
      url: `${api}Lettering/UpdatePackType`,
    },
    GetAddPackType: {
      method: 'post',
      url: `${api}Lettering/AddPackType`,
    },
    GetClientWorkflowLetters: {
      method: 'get',
      url: `${api}Lettering/GetClientWorkflowLetters`,
      url: `${api}Lettering/GetClientWorkstreamLetters`,
    },
    GetAgentUsageSummary: {
      method: 'get',
      url: `${api}Lettering/GetAgentUsageSummary`,
    },
    GetAddAgentUsage: {
      method: 'post',
      url: `${api}Lettering/AddAgentUsage`,
    },
    GetAgentUsageSummaryBoxes: {
      method: 'get',
      url: `${api}Lettering/GetAgentUsageSummaryBoxes`,
    },
    GetAgentUsageHistory: {
      method: 'get',
      url: `${api}Lettering/GetAgentUsageHistory`,
    },
    GetAgentPaymentSummaries: {
      method: 'get',
      url: `${api}finance/agent-invoices/summaries`,
    },
    GetAgentPaymentSummariesById: {
      method: 'get',
      url: `${api}finance/agent-invoices/agent`,
    },
    GetAgentPaymentInvoicesById: {
      method: 'get',
      url: `${api}finance/agent-invoices`,
    },
    GetClientPaymentSummaries: {
      method: 'get',
      url: `${api}finance/client-invoices/summaries`,
    },
    GetClientWorkStreamById: {
      method: 'get',
      url: `${api}finance/client-invoices/workstream`,
    },
    GetClientInvoiceById: {
      method: 'get',
      url: `${api}finance/client-invoices`,
    },
    GetVendorPaymentSummaries: {
      method: 'get',
      url: `${api}finance/Vendor-invoices/summaries`,
    },
    GetVendorInvoices: {
      method: 'get',
      url: `${api}finance/Vendor-invoices/vendor`,
    },
    GetVendorInvoicesItems: {
      method: 'get',
      url: `${api}finance/Vendor-invoices`,
    },
    GetSelectedBookmarks: {
      method: 'get',
      url: `${api}Lettering/ExtractLetterBookmarks`,
    },
    GetClientWorkflowLetterDetails: {
      method: 'get',
      url: `${api}Lettering/GetClientWorkflowLetterDetails`,
      url: `${api}Lettering/GetClientWorkstreamLetterDetails`,
    },
    GetLetteringSASToken: {
      method: 'get',
      url: `${api}Lettering/GetClientWorkflowLetterUploadSASToken`,
    },
    GetLetteringQRCode: {
      method: 'get',
      url: `${api}Lettering/GenerateQRCode`,
    },
    AddAgentLetterOrderDetails: {
      method: 'post',
      url: `${api}Lettering/AddAgentLetterOrderDetails`,
    },
    DeleteAgentLetterOrderDetails: {
      method: 'delete',
      url: `${api}Lettering/DeleteAgentLetterOrderDetails`,
    },
    AddAgentLetterOrderStatuses: {
      method: 'post',
      url: `${api}Lettering/AddAgentLetterOrderStatuses`,
    },
    AddAgentLetterOrders: {
      method: 'post',
      url: `${api}Lettering/AddAgentLetterOrders`,
    },
    GetCommunicationTypes: {
      method: 'get',
      url: `${api}communication/types`,
    },
    AddCommunicationTypes: {
      method: 'post',
      url: `${api}communication/types`,
    },
    UpdateCommunicationTypes: {
      method: 'put',
      url: `${api}communication/types`,
    },

    GetCommunicationCategories: {
      method: 'get',
      url: `${api}communication/categories`,
    },
    AddCommunicationCategories: {
      method: 'post',
      url: `${api}communication/categories`,
    },
    UpdateCommunicationCategories: {
      method: 'put',
      url: `${api}communication/categories`,
    },

    GetConfigWorkflowStageProperties: {
      method: 'get',
      url: `${api}workflows/properties`,
    },
    AddConfigWorkflowStageProperties: {
      method: 'post',
      url: `${api}workflows/properties`,
    },
    UpdateConfigWorkflowStageProperties: {
      method: 'put',
      url: `${api}workflows/properties`,
    },

    GetConfigWorkflowStageBranches: {
      method: 'get',
      url: `${api}workflows/branches`,
    },
    AddConfigWorkflowStageBranches: {
      method: 'post',
      url: `${api}workflows/branches`,
    },
    UpdateConfigWorkflowStageBranches: {
      method: 'put',
      url: `${api}workflows/branches`,
    },
    AddClientWorkflowLetters: {
      method: 'post',
      url: `${api}Lettering/AddClientWorkflowLetters`,
      url: `${api}Lettering/AddClientWorkstreamLetters`,
    },
    AddClientWorkflowLetterDetails: {
      method: 'post',
      url: `${api}Lettering/AddClientWorkflowLetterDetails`,
      url: `${api}Lettering/AddClientWorkstreamLetterDetails`,
    },
    GetNoteCategories: {
      method: 'get',
      url: `${api}Case/GetNoteCategories`,
    },
    GetJobTypes: {
      method: 'get',
      url: `${api}planning/job-types`,
    },
    GetStatusesQualityControl: {
      method: 'get',
      url: `${api}QualityControl/GetStatuses`,
    },
    GetOutcomeCategories: {
      method: 'get',
      url: `${api}QualityControl/GetOutcomeCategories`,
    },
    GetOutcomeCategoryDetails: {
      method: 'get',
      url: `${api}QualityControl/GetOutcomeCategoryDetails`,
    },
    GetOutcomes: {
      method: 'get',
      url: `${api}QualityControl/GetOutcomes`,
    },
    GetOutcomeDetails: {
      method: 'get',
      url: `${api}QualityControl/GetOutcomeDetails`,
    },
    GetCourts: {
      method: 'get',
      url: `${api}courts`,
    },
    GetCourtDetails: {
      method: 'get',
      url: `${api}courts/slots`,
    },
    GetSlots: {
      method: 'get',
      url: `${api}courts/slots`,
    },
    GetSlotAvailable: {
      method: 'get',
      url: `${api}courts/slots/available`,
    },
    GetRecurringSlot: {
      method: 'get',
      url: `${api}courts/slots/recurring`,
    },
    AddCourt: {
      method: 'post',
      url: `${api}courts`,
    },
    AddSingleSlot: {
      method: 'post',
      url: `${api}courts/slots`,
    },
    AddRecurringSlot: {
      method: 'post',
      url: `${api}courts/slots/recurring`,
    },
    EditSingleSlot: {
      method: 'put',
      url: `${api}courts/slots`,
    },
    DeleteSingleSlot: {
      method: 'delete',
      url: `${api}courts/slots`,
    },
    DeleteRecurringSlot: {
      method: 'delete',
      url: `${api}courts/slots/recurring`,
    },
    EditCourt: {
      method: 'put',
      url: `${api}courts`,
    },
    AddJobType: {
      method: 'post',
      url: `${api}planning/job-types`,
    },
    EditJobType: {
      method: 'put',
      url: `${api}planning/job-types`,
    },
    AddCaseStatus: {
      method: 'post',
      url: `${api}QualityControl/AddStatus`,
    },
    EditCaseStatus: {
      method: 'post',
      url: `${api}QualityControl/UpdateStatus`,
    },
    AddOutcome: {
      method: 'post',
      url: `${api}QualityControl/AddOutcome`,
    },
    EditOutcome: {
      method: 'post',
      url: `${api}QualityControl/UpdateOutcome`,
    },
    AddOutcomeCategory: {
      method: 'post',
      url: `${api}QualityControl/AddOutcomeCategory`,
    },
    EditOutcomeCategory: {
      method: 'put',
      url: `${api}QualityControl/UpdateOutcomeCategory`,
    },
    GetClientWorkFlowsList: {
      method: 'get',
      url: `${api}workflows/client-workstream-workflow`,
    },
    GetUpdateStages: {
      method: 'put',
      url: `${api}workflows/stages`,
    },
    GetPostCategories: {
      method: 'post',
      url: `${api}workflows/stage/categories`,
    },
    GetUpdateCategories: {
      method: 'put',
      url: `${api}workflows/stage/categories`,
    },
    GetBranchCondition: {
      method: 'get',
      url: `${api}workflows/stage/branches/conditions`,
    },
    NewWorkflowVersion: {
      method: 'post',
      url: `${api}workflows/version`,
    },
    GetTemplateType: {
      method: 'get',
      url: `${api}return/templates/types`,
    },
    UpdateTemplateType: {
      method: 'put',
      url: `${api}return/templates/types`,
    },
    AddTemplateType: {
      method: 'post',
      url: `${api}return/templates/types`,
    },
    getPlanningJobs: {
      method: 'get',
      url: `${api}planning/jobs`,
    },
    getJobTypes: {
      method: 'get',
      url: `${api}planning/job-types`,
    },
    addPlanningJobs: {
      method: 'post',
      url: `${api}planning/jobs`,
    },
    updatePlanningJobs: {
      method: 'put',
      url: `${api}planning/jobs`,
    },
    addPlanningSkillsets: {
      method: 'post',
      url: `${api}planning/job/required-skillsets`,
    },
    getPlannedSkillset: {
      method: 'get',
      url: `${api}planning/jobs/planned`,
    },
    GetUpdateProperty: {
      method: 'put',
      url: `${api}workflows/stages/required-properties`,
    },
    GetReturnTemplate: {
      method: 'get',
      url: `${api}return/templates`,
    },
    GetReturnTemplateTypes: {
      method: 'get',
      url: `${api}return/templates/types`,
    },
    GetAddTemplates: {
      method: 'post',
      url: `${api}return/templates`,
    },
    GetUpdateTemplates: {
      method: 'put',
      url: `${api}return/templates`,
    },
    GetUpdateBranches: {
      method: 'put',
      url: `${api}workflows/stages/required-branches`,
    },
    GetSchedules: {
      method: 'get',
      url: `${api}return/schedules`,
    },
    GetAddSchedule: {
      method: 'post',
      url: `${api}return/schedules`,
    },
    GetDeleteSchedule: {
      method: 'delete',
      url: `${api}return/schedules`,
    },
    DeleteUserDelegation: {
      method: 'delete',
      url: `${api}User/DeleteUserDelegation`,
    },
    GetCases: {
      method: 'post',
      url: `${api}Case/GetCases`,
    },
    GetBooking: {
      method: 'get',
      url: `${api}courts/booking`,
    },
    GetAwaiting: {
      method: 'get',
      url: `${api}courts/booking/awaiting`,
    },
    AddBooking: {
      method: 'post',
      url: `${api}courts/booking`,
    },
    DeleteCourtBooking: {
      method: 'delete',
      url: `${api}courts/booking`,
    },
    GetDashboardSingleUser: {
      method: 'get',
      url: `${api}dashboard/user-widgets`,
    },
    AddCommunicationHistory: {
      method: 'post',
      url: `${api}communication/history`,
    },
    GetCommunicationHistory: {
      method: 'get',
      url: `${api}communication/history`,
    },
    AddCaseDoc: {
      method: 'post',
      url: `${api}Case/AddCaseAttachment`,
    },
    GetCaseSas: {
      method: 'get',
      url: `${api}Case/GetAttachmentUploadSASToken`,
    },
    GetAuditLogs: {
      method: 'get',
      url: `${api}audits/users`,
    },
    AddCaseNote: {
      method: 'post',
      url: `${api}Case/AddNote`,
    },
    GetReturnFileGenerated: {
      method: 'get',
      url: `${api}return/files-generated?clientWorkstreamID`,
    },
    GetAddReturnFileGenerated: {
      method: 'post',
      url: `${api}return/files-generated?clientWorkstreamID`,
    },
    GetAddBranchCondition: {
      method: 'post',
      url: `${api}/workflows/stage/categories`,
    },
    GetUpdateBranchCondition: {
      method: 'put',
      url: `${api}/workflows/stage/categories`,
    },
    AddPlan: {
      method: 'post',
      url: `${api}planning/jobs/planned`,
    },
    addPlanningSingleJob: {
      method: 'post',
      url: `${api}planning/job`,
    },
    GetCaseJobDetails: {
      method: 'get',
      url: `${api}QualityControl/GetCaseJobDetails`,
    },
    GetQualityClientWorkflowOutcomes: {
      method: 'get',
      url: `${api}QualityControl/GetClientWorkstreamOutcomes`,
    },
    AddCaseStatusAndClientWorkflowOutcome: {
      method: 'post',
      url: `${api}QualityControl/AddCaseStatusAndClientWorkstreamOutcome`,
    },
    GetUpdateCoverageArea: {
      method: 'put',
      url: `${api}/Agent/UpdateCoverageArea`,
    },
    GetDeleteJobTitleRoles: {
      method: 'put',
      url: `${api}User/UpdateJobTitleRoles`,
    },
    GetCasesAwaitingQualityCheck: {
      method: 'get',
      url: `${api}QualityControl/GetCasesAwaitingQualityCheck`,
    },
    GetQualityCaseStatus: {
      method: 'get',
      url: `${api}QualityControl/GetCaseStatuses`,
    },
    viewVendorDoc: {
      method: 'get',
      url: `${api}vendors/documents/download`,
    },
    GetClientLogo: {
      method: 'get',
      url: `${api}Client/GetClientLogo?clientID`,
    },
    ViewClientDoc: {
      method: 'get',
      url: `${api}Client/GetClientDocumentFile?clientDocumentID`,
    },
    GetVendorLogo: {
      method: 'get',
      url: `${api}vendors/logo`,
    },
    GetWorkstreams: {
      method: 'get',
      url: `${api}Client/workstreams`,
    },
    AddClientWorkstreams: {
      method: 'post',
      url: `${api}Client/client-workstreams`,
    },
    UpdateClientWorkstreams: {
      method: 'put',
      url: `${api}Client/client-workstreams`,
    },
    GetClientWorkstreams: {
      method: 'get',
      url: `${api}Client/client-workstreams`,
    },
    AddWorkstreamWorkflow: {
      method: 'post',
      url: `${api}workflows/client-workstream-workflow`,
    },
    AddWorkStream: {
      method: 'post',
      url: `${api}Client/workstreams`,
    },
    EditWorkStream: {
      method: 'put',
      url: `${api}Client/workstreams`,
    },
    GetCaseAttachmentFile: {
      method: 'get',
      url: `${api}Case/GetCaseAttachmentFile?caseAttachmentID`,
    },
    ViewAgentDoc: {
      method: 'get',
      url: `${api}Agent/GetAgentDocumentFile`,
    },
    GetClientWorkflowLetterFile: {
      method: 'get',
      url: `${api}Lettering/GetClientWorkflowLetterFile`,
    },
  }
  return APIEndPoints
}

export default useAPIEndpoints
