import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
})
const menuLayout = atom({
  key: 'menuLayout',
  default: true,
})

const selectedMenu = atom({
  key: 'selectedMenu',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

const loader = atom({
  key: 'loader',
  default: false,
})
const popupLoader = atom({
  key: 'popupLoader',
  default: false,
})
const isPaneOpen = atom({
  key: 'panStatus',
  default: false,
})
const dialogPopUp = atom({
  key: 'dialogPopUp',
  default: false,
})
const loginStatus = atom({
  key: 'loginStatus',
  default: true,
})
const popOpen = atom({
  key: 'popOpen',
  default: false,
})
const jobTitleSlide = atom({
  key: 'jobTitleSlide',
  default: false,
})
const toastMessage = atom({
  key: 'ToastMessage',
  default: false,
})
const tabsHeaderTitle = atom({
  key: 'tabsHeaderTitle',
  default: false,
})
const auditSlide = atom({
  key: 'auditSlide',
  default: false,
})
const runNowSlide = atom({
  key: 'runNowSlide',
  default: false,
})

const selectedItemAtom = atom({
  key: 'selectedItem',
  default: 0,
  effects_UNSTABLE: [persistAtom],
})

const selectedSubIndexAtom = atom({
  key: 'selectedSubIndex',
  default: -1,
  effects_UNSTABLE: [persistAtom],
})

const collapseOpenAtom = atom({
  key: 'collapseOpen',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

const scheduleAction = atom({
  key: 'scheduleAction',
  default: '',
})

const schedulerDate = atom({
  key: 'schedulerDate',
  default: {
    startDate: '',
    endDate: '',
  },
})
const addReportScheduler = atom({
  key: 'addReportScheduler',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

const addReportStore = atom({
  key: 'addReportStore',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const getErrorCode = atom({
  key: 'getErrorCode',
  default: null,
})
const menuOpenIndex = atom({
  key: 'menuOpenIndex',
  default: -1,
})
const subItemState = atom({
  key: 'subItemState',
  default: { index: null, url: null },
})
const collapseStatus = atom({
  key: 'collapseStatus',
  default: {},
  effects_UNSTABLE: [persistAtom],
})
const aadObjectId = atom({
  key: 'aadObjectId',
  default: null,
})
const routesValues = atom({
  key: 'routesValues',
  default: null,
})
const userIdValue = atom({
  key: 'userIdValue',
  default: null,
})
const tabIndex = atom({
  key: 'tabIndex',
  default: 0,
  effects_UNSTABLE: [persistAtom],
})
const loggedUserDetails = atom({
  key: 'loggedUserDetails',
  default: null,
})
const equipmentStatus = atom({
  key: 'equipmentStatus',
  default: {},
  effects_UNSTABLE: [persistAtom],
})
const userSendID = atom({
  key: 'userSendID',
  default: null,
})
const redirectApiUrl = atom({
  key: 'redirectApiUrl',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const bearer = atom({
  key: 'bearer',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const loginRequestData = atom({
  key: 'loginRequestData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const sessionExpiredStatus = atom({
  key: 'sessionExpiredStatus',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
const configData = atom({
  key: 'configData',
  default: null,
})
const madeChanges = atom({
  key: 'madeChanges',
  default: false,
})
export {
  menuLayout,
  selectedMenu,
  loader,
  isPaneOpen,
  dialogPopUp,
  loginStatus,
  popOpen,
  jobTitleSlide,
  toastMessage,
  auditSlide,
  tabsHeaderTitle,
  runNowSlide,
  selectedItemAtom,
  selectedSubIndexAtom,
  collapseOpenAtom,
  scheduleAction,
  schedulerDate,
  addReportStore,
  addReportScheduler,
  getErrorCode,
  menuOpenIndex,
  subItemState,
  collapseStatus,
  aadObjectId,
  routesValues,
  userIdValue,
  tabIndex,
  loggedUserDetails,
  equipmentStatus,
  userSendID,
  redirectApiUrl,
  popupLoader,
  bearer,
  loginRequestData,
  configData,
  sessionExpiredStatus,
  madeChanges,
}
