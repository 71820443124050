import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import '../autoComplete/AutoComplete.css'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: '100%',
            // Other styles for the Autocomplete root element
          },
          option: {
            // Customize options in the dropdown
            color: outerTheme.palette.text.primary,
            '&.Mui-focused': {
              backgroundColor: outerTheme.palette.action.hover,
            },
          },
          paper: {
            // Customize the dropdown menu
            boxShadow: outerTheme.shadows[1],
            borderRadius: 4,
            border: `1px solid ${outerTheme.palette.divider}`,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: outerTheme.palette.primary.main,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .${outlinedInputClasses.notchedOutline}': {
              borderColor: outerTheme.palette.primary.light,
            },
            '&.Mui-focused .${outlinedInputClasses.notchedOutline}': {
              borderColor: outerTheme.palette.primary.main,
            },
            '&.Mui-error .${outlinedInputClasses.notchedOutline}': {
              borderColor: outerTheme.palette.error.main,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: outerTheme.palette.action.disabled,
            },
          },
        },
      },
    },
  })

export default function AutoCompleteComponent({
  options,
  label,
  placeholder,
  size,
  initialValue,
  selectChangeHandler,
  onSelect,
  clearSelectedValue,
  error,
  className,
  disabled,
  ...rest
}) {
  const outerTheme = useTheme()

  const [selectedOptions, setSelectedOptions] = React.useState(
    initialValue?.title?.length > 0 ? initialValue : null
  )

  const handleSelectChange = (event, newValue) => {
    setSelectedOptions(newValue)
    if (selectChangeHandler) {
      selectChangeHandler(event, newValue, rest)
    }
  }
  React.useEffect(() => {
    if (clearSelectedValue) {
      setSelectedOptions(null)
    }
  }, [clearSelectedValue])
  React.useEffect(() => {
    setSelectedOptions(initialValue)
  }, [initialValue])
  return (
    <div
      className={`autoComplete_main TextFieldParent ${
        error && 'TextFieldError'
      } ${className}`}
    >
      <ThemeProvider theme={customTheme(outerTheme)}>
        <Autocomplete
          sx={{ width: 300 }}
          options={
            options?.length > 0
              ? options?.sort((a, b) =>
                  a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
                )
              : []
          }
          getOptionLabel={(option) => option?.title || ''}
          isOptionEqualToValue={(option, value) => {
            const idOptions = Object?.entries(option)
              .filter(([key]) => key.toLowerCase().includes('id'))
              .map(([, value]) => value)
            const idValues =
              value && typeof value === 'object'
                ? Object.entries(value)
                    .filter(([key]) => key.toLowerCase().includes('id'))
                    .map(([, val]) => val)
                : []
            return idOptions[0] === idValues[0]
          }}
          onChange={handleSelectChange}
          value={selectedOptions}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              error={error}
              focused
              placeholder={placeholder}
              {...params}
              label={label}
              inputProps={{
                ...params.inputProps,
              }}
              size="small"
            />
          )}
          size={size}
          onSelect={onSelect}
          disabled={disabled}
        />
      </ThemeProvider>
    </div>
  )
}
