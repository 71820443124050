import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import './TimelineStep.css'

const TimelineStep = ({ timelineContent, timelineHead }) => {
  return (
    <div className="timeLine-Container">
      <div className="timeLineHead">{timelineHead}</div>
      {timelineContent &&
        timelineContent?.map((val) => {
          return (
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem sx={{}}>
                <TimelineSeparator>
                  <TimelineDot className="timelineDot" />
                  <TimelineConnector className="timelineConnector" />
                </TimelineSeparator>
                <TimelineContent>
                  <div className="timeLine-Content">
                    <div className="timeLineFirst-value">
                      {val?.dateValue === '' ? '' : `${val?.dateValue}`}
                    </div>
                    <div className="timeLineSecond-value">
                      {val?.updatedUserName} {val?.action}
                    </div>
                    <div className="timeLineSecond-value">
                      {val?.userMessage} {val?.action}
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          )
        })}
    </div>
  )
}

export default TimelineStep
