import React, { useEffect, useState } from 'react'
import { getErrorCode, routesValues } from '../components/recoil/atoms/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useNavigate, useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import ErrorBoundaryBase from '../components/base/errorPage/erroBoundary/ErrorBoundaryBase'

const RouteMiddleware = ({ allowedRoutes, path, children }) => {
  const [error, setError] = useRecoilState(getErrorCode)
  const routeValues = useRecoilValue(routesValues)
  const location = useLocation()
  const [pathValues, setPathValues] = useState(null)
  const [childrenPermitted, setChildrenPermitted] = useState(true)

  const menuAccessRoles = {
    dashBoard: [
      'AccountManagement.Clients.Read',
      'AccountManagement.Clients.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
      'AccountManagement.Vendors.Read',
      'AccountManagement.Vendors.ReadWrite',
      'Magnus.Admin',
      'Agents.Read',
      'Agents.ReadWrite',
      'Audit.Read',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
      'Case.Cases.Read',
      'Case.Cases.ReadWrite',
      'Case.Communication.Read',
      'Case.Communication.ReadWrite',
      'Case.Court.Read',
      'Case.Court.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
      'Case.Quality.Read',
      'Case.Quality.ReadWrite',
      'Case.Read',
      'Case.ReadWrite',
      'Config.Accounts.Read',
      'Config.Accounts.ReadWrite',
      'Config.Agents.Read',
      'Config.Agents.ReadWrite',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.DataReturn.Read',
      'Config.DataReturn.ReadWrite',
      'Config.General.Read',
      'Config.General.ReadWrite',
      'Config.Lettering.Read',
      'Config.Lettering.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
      'Config.Workflow.Read',
      'Config.Workflow.ReadWrite',
      'Dashboard.Read',
      'Dashboard.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
      'DataReturn.Files.Read',
      'DataReturn.Files.ReadWrite',
      'DataReturn.Schedule.Read',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.Template.Read',
      'DataReturn.Template.ReadWrite',
      'Finance.Agent.Read',
      'Finance.Client.Read',
      'Finance.Read',
      'Finance.Vendor.Read',
      'Lettering.AgentLetterOrders.Read',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.AgentLetters.Read',
      'Lettering.AgentLetters.ReadWrite',
      'Lettering.AgentLetterUsage.Read',
      'Lettering.AgentLetterUsage.ReadWrite',
      'Lettering.ClientLetters.Read',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
      'Magnus.User',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Roles.Read',
      'UserManagement.Roles.ReadWrite',
      'UserManagement.Users.Read',
      'UserManagement.Users.ReadWrite',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Stages.Read',
      'Workflow.Stages.ReadWrite',
      'Workflow.Workflows.Read',
      'Workflow.Workflows.ReadWrite',
    ],
    general: [
      'Magnus.Admin',
      'Config.General.Read',
      'Config.General.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    addCoverageArea: ['Magnus.Admin'],
    editCoverageArea: ['Magnus.Admin'],
    addRegion: [
      'Magnus.Admin',
      'Config.General.ReadWrite',
      'Config.All.ReadWrite',
    ],
    editRegion: [
      'Magnus.Admin',
      'Config.General.ReadWrite',
      'Config.All.ReadWrite',
    ],
    editEquipment: [
      'Magnus.Admin',
      'Config.General.ReadWrite',
      'Config.All.ReadWrite',
    ],
    addStages: [
      'Magnus.Admin',
      'Config.General.ReadWrite',
      'Config.All.ReadWrite',
      'Stages.ReadWrite',
      'Workflow.ReadWrite',
      'Workflow.Stages.ReadWrite',
    ],
    editStages: [
      'Magnus.Admin',
      'Config.General.ReadWrite',
      'Config.All.ReadWrite',
      'Stages.ReadWrite',
      'Workflow.ReadWrite',
      'Workflow.Stages.ReadWrite',
    ],
    agents: [
      'Magnus.Admin',
      'Config.Agents.Read',
      'Config.Agents.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    lettering: [
      'Magnus.Admin',
      'Config.Lettering.Read',
      'Config.Lettering.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    accounts: [
      'Magnus.Admin',
      'Config.Accounts.Read',
      'Config.Accounts.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    caseManagement: [
      'Magnus.Admin',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    configWorkflow: [
      'Magnus.Admin',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    caseManagement: [
      'Magnus.Admin',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    configWorkflow: [
      'Magnus.Admin',
      'Config.CaseManagement.Read',
      'Config.CaseManagement.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    dataReturnfiles: [
      'Magnus.Admin',
      'Config.DataReturnFiles.Read',
      'Config.DataReturnFiles.ReadWrite',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    addCourt: [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ],
    viewCourts: [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
      'Config.All.Read',
      'Config.CaseManagement.Read',
    ],
    editCourt: [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ],
    agentManagement: ['Magnus.Admin', 'Agents.Read', 'Agents.ReadWrite'],
    configWorkflow: [
      'Magnus.Admin',
      'Agents.Read',
      'Agents.ReadWrite',
      'Config.Workflow.Read',
      'Config.All.Read',
      'Config.All.ReadWrite',
    ],
    addAgent: ['Magnus.Admin', 'Agents.ReadWrite'],
    editAgent: ['Magnus.Admin', 'Agents.ReadWrite'],
    vendors: [
      'Magnus.Admin',
      'AccountManagement.Vendors.Read',
      'AccountManagement.Vendors.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
    ],
    addVendors: [
      'Magnus.Admin',
      'AccountManagement.All.ReadWrite',
      'AccountManagement.Vendors.ReadWrite',
    ],
    editVendors: [
      'Magnus.Admin',
      'AccountManagement.All.ReadWrite',
      'AccountManagement.Vendors.ReadWrite',
    ],
    client: [
      'Magnus.Admin',
      'AccountManagement.Clients.Read',
      'AccountManagement.Clients.ReadWrite',
      'AccountManagement.All.Read',
      'AccountManagement.All.ReadWrite',
    ],
    addClients: [
      'Magnus.Admin',
      'AccountManagement.All.ReadWrite',
      'AccountManagement.Clients.ReadWrite',
    ],
    editClients: [
      'Magnus.Admin',
      'AccountManagement.All.ReadWrite',
      'AccountManagement.Clients.ReadWrite',
    ],
    users: [
      'Magnus.Admin',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Users.Read',
      'UserManagement.Users.ReadWrite',
    ],
    addUser: [
      'Magnus.Admin',
      'UserManagement.All.ReadWrite',
      'UserManagement.Users.ReadWrite',
    ],
    editUser: [
      'Magnus.Admin',
      'UserManagement.All.ReadWrite',
      'UserManagement.Users.ReadWrite',
    ],

    roles: [
      'Magnus.Admin',
      'UserManagement.All.Read',
      'UserManagement.All.ReadWrite',
      'UserManagement.Roles.Read',
      'UserManagement.Roles.ReadWrite',
    ],
    editJobTitle: [
      'Magnus.Admin',
      'UserManagement.All.ReadWrite',
      'UserManagement.Roles.ReadWrite',
    ],
    finance: [
      'Magnus.Admin',
      'finance.All.Read',
      'finance.All.ReadWrite',
      'finance.Roles.Read',
      'finance.Roles.ReadWrite',
    ],
    agentPayment: [
      'Magnus.Admin',
      'agentPayment.All.Read',
      'agentPayment.All.ReadWrite',
      'agentPayment.Roles.Read',
      'agentPayment.Roles.ReadWrite',
      'Finance.Agent.Read',
      'Finance.Read',
    ],
    clientInvoice: [
      'Magnus.Admin',
      'clientInvoice.All.Read',
      'clientInvoice.All.ReadWrite',
      'clientInvoice.Roles.Read',
      'clientInvoice.Roles.ReadWrite',
      'Finance.Client.Read',
      'Finance.Read',
    ],
    financeVendor: [
      'Magnus.Admin',
      'financeVendor.All.Read',
      'financeVendor.All.ReadWrite',
      'financeVendor.Roles.Read',
      'financeVendor.Roles.ReadWrite',
      'Finance.Vendor.Read',
      'Finance.Read',
    ],
    agentLetterOrder: [
      'Magnus.Admin',
      'Lettering.AgentLetterOrders.Read',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    clientLetter: [
      'Magnus.Admin',
      'Lettering.ClientLetters.Read',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    addClientLetter: [
      'Magnus.Admin',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.All.ReadWrite',
    ],
    viewClientLetter: [
      'Magnus.Admin',
      'Lettering.ClientLetters.ReadWrite',
      'Lettering.All.ReadWrite',
    ],
    ClientLetter: [
      'Magnus.Admin',
      'Lettering.AgentLetterOrders.Read',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],

    agentUsage: [
      'Magnus.Admin',
      'Lettering.AgentLetterUsage.Read',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    cases: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Cases.Read',
      'Case.Cases.ReadWrite',
    ],
    communication: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Communication.Read',
      'Case.Communication.ReadWrite',
    ],
    courtBooking: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Court.Read',
      'Case.Court.ReadWrite',
    ],
    planning: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
    ],
    addplanning: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
    ],
    editplan: ['Magnus.Admin', 'Case.ReadWrite', 'Case.Planning.ReadWrite'],
    appointmentScheduler: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
    ],
    addappointment: [
      'Magnus.Admin',
      'Case.ReadWrite',
      'Case.Appointment.ReadWrite',
    ],
    editappointment: [
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
    ],
    qualityControl: [
      'Magnus.Admin',
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Quality.Read',
      'Case.Quality.ReadWrite',
    ],
    caseDetails: [
      'Magnus.Admin',
      'Magnus.Admin',
      'Case.Read',
      'Case.ReadWrite',
      'Case.Cases.Read',
      'Case.Cases.ReadWrite',
      'Case.Court.Read',
      'Case.Court.ReadWrite',
      'Case.Planning.Read',
      'Case.Planning.ReadWrite',
      'Case.Appointment.Read',
      'Case.Appointment.ReadWrite',
      'Case.Communication.Read',
      'Case.Communication.ReadWrite',
    ],
    workflow: [
      'Magnus.Admin',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Workflows.Read',
      'Workflow.Workflows.ReadWrite',
    ],
    addWorkflow: [
      'Magnus.Admin',
      'Workflow.ReadWrite',
      'Workflow.Workflows.ReadWrite',
    ],
    editWorkflow: [
      'Magnus.Admin',
      'Workflow.ReadWrite',
      'Workflow.Workflows.ReadWrite',
    ],
    stages: [
      'Magnus.Admin',
      'Workflow.Read',
      'Workflow.ReadWrite',
      'Workflow.Stages.Read',
      'Workflow.Stages.ReadWrite',
    ],
    dataReturn: [
      'Magnus.Admin',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
      'DataReturn.Files.Read',
      'DataReturn.Files.ReadWrite',
      'DataReturn.Schedule.Read',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.Template.Read',
      'DataReturn.Template.ReadWrite',
    ],
    templateReturn: [
      'Magnus.Admin',
      'DataReturn.Template.Read',
      'DataReturn.Template.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
    ],
    editReport: [
      'Magnus.Admin',
      'DataReturn.ReadWrite',
      'DataReturn.Template.ReadWrite',
    ],
    addDataReport: [
      'Magnus.Admin',
      'DataReturn.ReadWrite',
      'DataReturn.Template.ReadWrite',
    ],
    viewSingleAgentDetails: [
      'Magnus.Admin',
      'Lettering.AgentLetterUsage.Read',
      'Lettering.AgentLetterUsage.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    addAgentLetterOrder: [
      'Magnus.Admin',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    EditAgentLetterOrder: [
      'Magnus.Admin',
      'Lettering.AgentLetterOrders.ReadWrite',
      'Lettering.All.Read',
      'Lettering.All.ReadWrite',
    ],
    scheduleDataReturn: [
      'Magnus.Admin',
      'DataReturn.Schedule.Read',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
    ],
    addSchedule: [
      'Magnus.Admin',
      'DataReturn.Schedule.ReadWrite',
      'DataReturn.ReadWrite',
    ],
    auditLog: ['Magnus.Admin', 'Audit.Read'],
    returnFile: [
      'Magnus.Admin',
      'DataReturn.Files.Read',
      'DataReturn.Files.ReadWrite',
      'DataReturn.Read',
      'DataReturn.ReadWrite',
    ],
  }

  /*   useEffect(() => {
    function filterMenuAndPathMap(
      param_finalValue,
      param_menuAndPathMapAtomData
    ) {
      return Object.keys(param_menuAndPathMapAtomData)
        .filter((key) => param_finalValue.includes(key))
        .reduce((obj, key) => {
          obj[key] = param_menuAndPathMapAtomData[key]
          return obj
        }, {})
    }

    const filteredMenuAndPathMapAtomData = filterMenuAndPathMap(
      finalValue,
      menuAndPathMapAtomData
    )
    console.log(
      'filteredMenuAndPathMapAtomData',
      filteredMenuAndPathMapAtomData
    )

    setPathValues(filteredMenuAndPathMapAtomData)
    // hasPermitted(filteredMenuAndPathMapAtomData)
  }, [routeValues]) */

  //

  const functionOrder = () => {
    // const [childrenPermitted, setChildrenPermitted] = useState(false)

    // filter path from menuAccessRoles as per roles
    const finalValue = [] // Arrayf of strimg contained menuAccess keys
    for (const key in menuAccessRoles) {
      const filteredRoles = menuAccessRoles[key].filter((role) =>
        routeValues.includes(role)
      )
      if (filteredRoles.length > 0) {
        finalValue.push(key)
      }
    }
    // mat
    const menuAndPathMapAtomData = {
      dashBoard: ['/'],
      general: ['/general'],
      addCoverageArea: ['/addcoveragearea'],
      editCoverageArea: ['/editcoveragearea'],
      addRegion: ['/addregions'],
      addStages: ['/addstages'],
      editStages: ['/editstages'],
      editRegion: ['/editregion'],
      editEquipment: ['/editequipment'],
      agents: ['/agents'],
      lettering: ['/lettering'],
      accounts: ['/accounts'],
      caseManagement: ['/casemanagement'],
      addCourt: ['/addcourt'],
      editCourt: ['/editcourt'],
      viewCourts: ['/viewcourts'],
      configWorkflow: ['/configworkflow'],
      dataReturnfiles: ['/datareturnfiles'],
      configWorkflow: ['/configworkflow'],
      agentManagement: ['/agentmanagement'],
      addAgent: ['/addagent'],
      editAgent: ['/editagent'],
      client: ['/client'],
      editClients: ['/editclients'],
      addClients: ['/addclients'],
      vendors: ['/vendors'],
      addVendors: ['/addvendors'],
      editVendors: ['/editvendors'],
      users: ['/users'],
      addUser: ['/adduser'],
      editUser: ['/edituser'],
      roles: ['/roles'],
      // editUserRole: ['/edituserrole'],
      editJobTitle: ['/editjobtitle'],
      agentLetterOrder: ['/agentletterorder'],
      addAgentLetterOrder: ['/addagentletterorder'],
      EditAgentLetterOrder: ['/editagentletterorder'],
      clientLetter: ['/clientletter'],
      addClientLetter: ['/addclientletter'],
      // editClientLetter: ['/editclientletter'],
      viewClientLetter: ['/viewclientletter'],
      agentUsage: ['/agentusage'],
      cases: ['/cases'],
      communication: ['/communication'],
      courtBooking: ['/courtbooking'],
      planning: ['/planning'],
      addplanning: ['/addplanning'],
      editplan: ['/editplan'],
      appointmentScheduler: ['/appointmentscheduler'],
      addappointment: ['/addappointment'],
      editappointment: ['/editappointment'],
      qualityControl: ['/qualitycontrol'],
      caseDetails: ['/casedetails'],
      stages: ['/stages'],
      workflow: ['/workflow'],
      addWorkflow: ['/addworkflow'],
      editWorkflow: ['/editworkflow'],
      viewSingleAgentDetails: ['/viewsingleagentdetails'],
      agentPayment: ['/agentpayment'],
      clientInvoice: ['/clientinvoice'],
      financeVendor: ['/financevendor'],
      dataReturn: ['/datareturn'],
      templateReturn: ['/templatereturn'],
      editReport: ['/editreport'],
      addDataReport: ['/adddatareport'],
      scheduleDataReturn: ['/scheduledatareturn'],
      addSchedule: ['/addschedule'],
      auditLog: ['/auditlog'],
      returnFile: ['/returnfile'],
    }
    const filteredMenuAndPathMapAtomData = Object.keys(menuAndPathMapAtomData)
      .filter((key) => finalValue.includes(key))
      .reduce((obj, key) => {
        obj[key] = menuAndPathMapAtomData[key]
        return obj
      }, {})
    //
    // console.log('RouterMiddlewarefilter', filteredMenuAndPathMapAtomData)
    setPathValues(filteredMenuAndPathMapAtomData)

    // if (permitted) {
    //   setChildrenPermitted(true)
    // } else {
    //   setChildrenPermitted(false)
    // }
  }

  useEffect(() => {
    if (routeValues && Array.isArray(routeValues) && routeValues?.length > 0)
      functionOrder()
    // console.log('RouterMiddlewareroutes', routeValues)
  }, [routeValues, children])
  // console.log('childrenPermitted', childrenPermitted)
  if (
    routeValues &&
    Array.isArray(routeValues) &&
    routeValues?.length > 0 &&
    pathValues
  ) {
    let permitted = false
    for (const [key, value] of Object.entries(pathValues)) {
      if (value?.includes(location?.pathname.toLocaleLowerCase())) {
        permitted = true
        return children
      }
    }
    if (!permitted) {
      setError(404)
    }
    return null
  }
}
export default RouteMiddleware
