import React, { useEffect, useState } from 'react'
import './Notification.css'
import Link from '@mui/material/Link'
import Arrow from '../../../../assets/images/Arrow-small.svg'
import NotifyDataDummy from './NotifyMessages'

const Notification = () => {
  const unreadItemsLength = NotifyDataDummy.filter(
    (item) => item.status === 'Unread'
  ).length

  const [counts, setCounts] = useState(unreadItemsLength)
  const [NotifyData, setNotifyData] = useState([])

  useEffect(() => {
    setNotifyData(NotifyDataDummy)
  }, [counts])

  const handleNewMessage = () => {
    const unreadItems = NotifyDataDummy.filter(
      (item) => item.status === 'Unread'
    )
    setNotifyData(unreadItems)
  }
  const handleAllMessage = () => {
    setNotifyData(NotifyDataDummy)
  }
  const handleMarkAllRead = () => {
    const updatedNotifyData = NotifyData?.map((item) => {
      return { ...item, status: 'Read' }
    })
    setNotifyData(updatedNotifyData)
  }

  const handleStateChange = (status, id) => {
    const updatedNotifyData = NotifyData?.map((item) => {
      if (item.id === id) {
        return { ...item, status: 'Read' }
      }
      return item
    })
    setNotifyData(updatedNotifyData)
  }

  return (
    <div className="notificationPopUp">
      <div className="notifyHeader">
        <div className="notifySideHead">Notification</div>
        <div className="notificationButton">
          <div className="viewStatusContainer">
            <div className="notifyNew" onClick={handleNewMessage}>
              <div className="newButton">UNREAD ({counts})</div>
            </div>
            <div className="notifyAll" onClick={handleAllMessage}>
              <div className="allButton">All</div>
            </div>
          </div>
          <Link style={{ cursor: 'pointer' }} onClick={handleMarkAllRead}>
            Mark all as read
          </Link>
        </div>
      </div>
      <div className="notifyMain-Container">
        {NotifyData &&
          NotifyData?.map((data) => {
            return (
              <div
                className={`notifyMessage-Container ${data?.status}`}
                onClick={() => handleStateChange(data?.status, data?.id)}
              >
                <div className="notifyMessages">
                  <div className="notifierName">{data?.userName}</div>
                  <div className="notifyUpdates">
                    <div className="notifyUpdates">{data?.updateMessage}</div>
                    <div className="notifyTimezone">
                      <div className="notifyUpdateTime">{data?.updateTime}</div>
                      <div className="notifyDot-Container">
                        <div className="notifyDot"></div>
                        <div className="notifyUserPage">{data?.userPage}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={Arrow} />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Notification
