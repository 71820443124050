import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import RoutesFile from '../views/routes/Routes'
import Header from '../views/components/custom/header/HeaderFile'
import LoadingComponent from '../views/components/base/loading/LoadingComponent'
import LoginPage from '../views/pages/loginPage/LoginPage'
import {
  aadObjectId,
  loginStatus,
  getErrorCode,
  loader,
  loggedUserDetails,
  redirectApiUrl,
  bearer,
  loginRequestData,
  configData,
  sessionExpiredStatus,
} from '../views/components/recoil/atoms/atoms'
import SideMenu from '../views/components/custom/menuLayout/SideMenuLayout'
import {
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
  MsalProvider,
} from '@azure/msal-react'
import ErrorBoundaryBase from '../views/components/base/errorPage/erroBoundary/ErrorBoundaryBase'
import fetchSelector from '../views/components/recoil/selectors/selectors'
import useAPIEndpoints from '../models/api/apiEndpoints'
import ButtonComponent from '../views/components/base/button/Button'
import PopupLoadingComponent from '../views/components/base/popupLoader/PopupLoader'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import Unauthorized from '../views/components/base/errorPage/Error401'
import AccessDenied from '../views/components/base/errorPage/Error403'
import { jwtDecode } from 'jwt-decode'
import { ConfirmProvider } from 'material-ui-confirm'

export function App({ instance, msalConfig }) {
  useEffect(() => {
    const bodyStyles = document.body.style

    bodyStyles.setProperty('--bodyBackgroundColor', '#f2f4f8')
    bodyStyles.setProperty('--content-div-backgroundColor', '#ffffff')
    bodyStyles.setProperty('--content-div-padding', '24px')
    bodyStyles.setProperty('--secondHeader-FontWeight', '500')
    bodyStyles.setProperty('--default-font-family', 'Roboto')
    bodyStyles.setProperty('--default-font-field-color', 'rgba(0, 0, 0, 0.6)')
    bodyStyles.setProperty('--default-font-size', '0.75rem')
    bodyStyles.setProperty('--header-font-size', '1.5rem')
    bodyStyles.setProperty('--title-FontSize', '1.125rem')
    bodyStyles.setProperty('--sub-title-FontSize', '0.875rem')
    bodyStyles.setProperty('--default-border-color', '#dde1e6')
    bodyStyles.setProperty('--default-border-size', '1px solid #dde1e6')
    bodyStyles.setProperty('--grid-column-title-fontsize', '0.80rem')
    bodyStyles.setProperty('--grid-row-text-fontsize', '0.75rem')
    bodyStyles.setProperty('--grid-title-fontWeight', '600')
    bodyStyles.setProperty('--grid-cellContent-fontWeight', '400')
    bodyStyles.setProperty('--grid-text-color', '#000000DE')
    bodyStyles.setProperty('--grid-cellContent-marked-color', '#0277BD')
    bodyStyles.setProperty('--button-text-font-weight', '600')
    bodyStyles.setProperty('--button-text-font-size', '0.75rem')
    bodyStyles.setProperty('--button-padding', '2px 10px 2px 10px')
    bodyStyles.setProperty('--button-border-radius', '4px')
    bodyStyles.setProperty('--text-marked-color', '#1f3c88')
    bodyStyles.setProperty('--default-border-radius', '4px')
    bodyStyles.setProperty('--right-panel-slider-width', '60%')
    bodyStyles.setProperty(
      '--box-shadow',
      '0px 9px 46px 8px #0000001f, 0px 24px 38px 3px #00000024,0px 11px 15px -7px #00000033'
    )
  }, [])

  return (
    <MsalProvider instance={instance}>
      <WrapperView msalConfig={msalConfig} />
    </MsalProvider>
  )
}

export function WrapperView({ msalConfig }) {
  const [loginStatusValue, setLoginStatusValue] = useRecoilState(loginStatus)
  const [azureID, setAzureID] = useRecoilState(aadObjectId)
  const { instance, accounts } = useMsal()
  const userName = accounts[0]?.username
  const [userDetailsData, setUserDetailsData] = useState([])
  const [, setLoading] = useRecoilState(loader)
  const getData = useRecoilValue(fetchSelector)
  const [, setLoggedUserData] = useRecoilState(loggedUserDetails)
  const APIEndPoints = useAPIEndpoints()
  const [apiURL, setApiURL] = useRecoilState(redirectApiUrl)
  const [bearerToken, setBearerToken] = useRecoilState(bearer)
  const [AuthScope, setAuthScope] = useRecoilState(loginRequestData)
  const [unauthorized, setUnauthorized] = useState(false)
  const [tokenExpired, setTokenExpired] = useState(false)
  const [sessionExpired, setSessionExpired] =
    useRecoilState(sessionExpiredStatus)
  const [msalConfigAtom, setMsalConfigAtom] = useRecoilState(configData)

  useEffect(() => {
    setApiURL(`${msalConfig?.auth?.apiURL}/api/`)
    setAuthScope({
      scopes: [msalConfig?.auth?.AuthScope],
      loginHint: '',
    })
    setMsalConfigAtom(msalConfig)
  }, [msalConfig, accounts])

  useEffect(() => {
    const savedToken = localStorage.getItem('bearerToken')
    if (savedToken) {
      setBearerToken(savedToken)
    }
  }, [])

  useEffect(() => {
    let token = localStorage.getItem('bearerToken')
    let decodedToken = token && jwtDecode(token)
    let currentDate = new Date()

    // JWT exp is in seconds
    if (decodedToken?.exp * 1000 < currentDate?.getTime()) {
      setTokenExpired(true)
    } else {
      setTokenExpired(false)
    }
    const bearerToken = localStorage.getItem('bearerToken')
    accounts[0]?.localAccountId &&
      bearerToken &&
      !tokenExpired &&
      getUserDetails(accounts[0]?.localAccountId)
  }, [accounts, bearerToken, tokenExpired])

  useEffect(() => {
    let tokenRefreshInterval

    if (accounts[0]) {
      setLoginStatusValue(false)
      setAzureID(accounts[0]?.localAccountId)

      const refreshToken = () => {
        instance
          .acquireTokenSilent({
            ...AuthScope,
            account: accounts[0],
          })
          .then((response) => {
            localStorage.setItem('bearerToken', response?.accessToken)
            setBearerToken(response?.accessToken)
            setUnauthorized(false)
            setLoginStatusValue(false)
            setSessionExpired(false)
            const decodedToken = jwtDecode(response?.accessToken)
            const expirationTime = decodedToken?.exp * 1000 - Date.now()
            const refreshTime = expirationTime - 5 * 60 * 1000 // Refresh 5 minutes before token expiration

            clearTimeout(tokenRefreshInterval) // Clear previous interval
            tokenRefreshInterval = setTimeout(refreshToken, refreshTime)
          })
          .catch((error) => {
            setUnauthorized(true)
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(AuthScope)
            } else {
              console.error('Token acquisition failed:', error)
            }
          })
      }

      refreshToken()

      return () => {
        clearTimeout(tokenRefreshInterval)
      }
    }
  }, [accounts, instance, AuthScope, setBearerToken])

  useEffect(() => {
    let idleTimeout
    const idleTimeLimit = 55 * 60 * 1000 // 55 minutes
    const resetIdleTimer = () => {
      clearTimeout(idleTimeout)
      idleTimeout = setTimeout(() => {
        setSessionExpired(true)
      }, idleTimeLimit)
    }

    window.onload = resetIdleTimer
    document.onmousemove = resetIdleTimer
    document.onkeypress = resetIdleTimer

    return () => {
      clearTimeout(idleTimeout) // Cleanup on unmount
    }
  }, [])
  const logOutHandler = async () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/'
  }

  const getError = useRecoilValue(getErrorCode)
  const errorComponents = [400, 401, 403, 404, 405, 500, 503, 504]
  const getUserDetails = async (uuID) => {
    setLoading(true)
    await getData(
      APIEndPoints.GetUserDetails.method,
      ` ${APIEndPoints.GetUserDetails.url}?aadObjectID=${uuID}&active=${true}`
    )
      .then((response) => {
        setLoading(false)
        setUserDetailsData(response?.data)
        setLoggedUserData(response?.data)
        response?.status === 401 && setUnauthorized(401)
        response?.status === 403 && setUnauthorized(403)
      })
      .catch((error) => {
        setUnauthorized(403)
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }

  const getUserStatus = () => {
    return unauthorized === 401 ? (
      <Unauthorized />
    ) : unauthorized === 403 ? (
      <AccessDenied />
    ) : azureID && !userDetailsData ? (
      <LoginPage />
    ) : (
      <div></div>
    )
  }
  return (
    <>
      {tokenExpired ? (
        <LoginPage />
      ) : sessionExpired ? (
        <div className="inActive-content--main">
          <div className="inActive-content-app" style={{ fontSize: '14px' }}>
            Session expired, please login again
          </div>
          <ButtonComponent
            className="withBackground"
            text="Back to Login"
            onClick={logOutHandler}
          />
        </div>
      ) : (
        <>
          <AuthenticatedTemplate>
            <ConfirmProvider>
              {!sessionExpired && userName ? (
                <HashRouter>
                  <LoadingComponent />
                  <PopupLoadingComponent />
                  {getError && errorComponents.includes(getError) ? (
                    <ErrorBoundaryBase errorCode={getError} />
                  ) : azureID &&
                    userDetailsData &&
                    userDetailsData[0]?.active === true ? (
                    <>
                      <SideMenu />
                      <div className="main_page">
                        <Header />
                        <RoutesFile />
                      </div>
                    </>
                  ) : (
                    getUserStatus()
                  )}
                </HashRouter>
              ) : (
                ((loginStatusValue && !accounts[0]?.localAccountId) ||
                  unauthorized ||
                  !bearerToken) &&
                !sessionExpired && <LoginPage />
              )}
            </ConfirmProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {loginStatusValue &&
              !accounts[0]?.localAccountId &&
              !sessionExpired && <LoginPage />}
          </UnauthenticatedTemplate>
        </>
      )}
    </>
  )
}
