// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-message-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toast-message-main
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
  width: 275px !important;
  background-color: green;
  min-width: 0;
}
.toast-message-main.unsuccessfull
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
  width: 275px !important;
  background-color: red;
  min-width: 0;
}
.toast-message-content-header-left {
  display: flex;
  align-items: center;
  gap: 5px;
}
.toast-message-content-message {
  margin-left: 25px;
}
.toast-message-content-message.unsuccessfull {
  margin-left: 5px;
}
.toast-message-main .toastClosIcon:hover {
  cursor: pointer;
}
.toast-message-main.unsuccessfull .MuiSnackbarContent-message {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/toastMessage/ToastMessage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;;EAEE,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;AACd;AACA;;EAEE,uBAAuB;EACvB,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;AACb","sourcesContent":[".toast-message-content-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.toast-message-main\n  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {\n  width: 275px !important;\n  background-color: green;\n  min-width: 0;\n}\n.toast-message-main.unsuccessfull\n  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {\n  width: 275px !important;\n  background-color: red;\n  min-width: 0;\n}\n.toast-message-content-header-left {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n.toast-message-content-message {\n  margin-left: 25px;\n}\n.toast-message-content-message.unsuccessfull {\n  margin-left: 5px;\n}\n.toast-message-main .toastClosIcon:hover {\n  cursor: pointer;\n}\n.toast-message-main.unsuccessfull .MuiSnackbarContent-message {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
