import React from 'react'
import backgroundImage from '../../../../assets/images/loginPage_M-logo.png'
import error400 from '../../../../assets/images/Error/Error400.svg'
import './ErrorLayout.css'
import ButtonComponent from '../button/Button'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getErrorCode } from '../../recoil/atoms/atoms'

const Error400 = () => {
  const [errorNull, setErrorNull] = useRecoilState(getErrorCode)
  const navigate = useNavigate()
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
      }}
      className="errorContainer"
    >
      <div className="errorImg404">
        <img src={error400} alt="Error 400" />
        <div className="errorMessage400">Bad Request Error</div>
        <div className="error400Msg-Container">
          <div className="errorMessage">
            You have entered the website URL with a wrong format.
          </div>
          <div className="errorMessage">
            Try again with proper URL structure excluding unwanted Symbols.
          </div>
        </div>
        <div className="errorButton-Container">
          <ButtonComponent
            className="withBackground"
            text="Retry"
            onClick={() => window.location.reload(false)}
          />
          <ButtonComponent
            className="errorPageButton"
            text="Back to Home"
            onClick={() => {
              navigate('/')
              setErrorNull(null)
              localStorage.clear()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Error400
