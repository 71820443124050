import React, { useState } from 'react'
import HeaderTab from '../../../components/base/tabs/Tabs'
import '../general/General.css'
import Courts from './courts/Courts'
import NoteCategory from './NoteCategory'
import JobType from './JobType'
import WorkOutcomeCategory from './WorkOutcomeCategory'
import Status from './Status'
import WorkOutcome from './WorkOutcome'
import CommunicationCategory from './communicationCategory/CommunicationCategory'
import CommunicationType from './comunicationType/CommunicationType'
function CaseManagement() {
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
  const tabSelectionChangeHandler = (event, selectedIndex) => {
    setTabSelectedIndex(selectedIndex)
  }

  const tabDataSource = [
    {
      tabTitle: 'COURTS',
      tabContent: <Courts />,
    },
    {
      tabTitle: 'NOTE CATEGORIES',
      tabContent: <NoteCategory />,
    },
    {
      tabTitle: 'STATUSES',
      tabContent: <Status />,
    },
    {
      tabTitle: 'JOB TYPES',
      tabContent: <JobType />,
    },
    {
      tabTitle: 'WORK OUTCOME CATEGORIES',
      tabContent: <WorkOutcomeCategory />,
    },

    {
      tabTitle: 'WORK OUTCOMES',
      tabContent: <WorkOutcome />,
    },
    {
      tabTitle: 'COMMUNICATION TYPES',
      tabContent: <CommunicationType />,
    },
    {
      tabTitle: 'COMMUNICATION CATEGORIES',
      tabContent: <CommunicationCategory />,
    },
  ]

  return (
    <div className="generalConfigParent-Container">
      <div className="generalConfig-TabContainer">
        <HeaderTab
          orientation="vertical"
          dataSource={tabDataSource}
          selectedTabIndex={tabSelectedIndex}
          onChangeHandler={tabSelectionChangeHandler}
          TabIndicatorProps={{
            style: {
              marginTop: 28,
              height: 2,
              backgroundColor: 'rgba(31, 60, 136, 1)',
              width: '100%',
            },
          }}
        />
      </div>
    </div>
  )
}

export default CaseManagement
