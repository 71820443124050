import React from 'react'
import HeaderTab from '../../../components/base/tabs/Tabs'
import '../general/General.css'
import Category from './Category'
import DistributionType from './DistributionType'
import PacketType from './PacketType'
import Bookmarks from './Bookmark'
import { tabIndex } from '../../../components/recoil/atoms/atoms'
import { useRecoilState } from 'recoil'
import OrderStatus from './OrderStatus'

function Lettering() {
  const [tabSelectedIndex, setTabSelectedIndex] = useRecoilState(tabIndex)
  const tabSelectionChangeHandler = (event, selectedIndex) => {
    setTabSelectedIndex(selectedIndex)
  }

  const tabDataSource = [
    {
      tabTitle: 'CATEGORIES',
      tabContent: <Category />,
    },
    {
      tabTitle: 'DISTRIBUTION TYPES',
      tabContent: <DistributionType />,
    },
    {
      tabTitle: 'PACKET TYPES',
      tabContent: <PacketType />,
    },
    {
      tabTitle: 'BOOKMARK MAPPINGS',
      tabContent: <Bookmarks />,
    },
    {
      tabTitle: 'ORDER STATUSES',
      tabContent: <OrderStatus />,
    },
  ]

  return (
    <div>
      <div className="lettering-grid-container">
        <HeaderTab
          orientation="vertical"
          dataSource={tabDataSource}
          selectedTabIndex={tabSelectedIndex}
          onChangeHandler={tabSelectionChangeHandler}
          TabIndicatorProps={{
            style: {
              marginTop: 28,
              height: 2,
              backgroundColor: 'rgba(31, 60, 136, 1)',
              width: '100%',
            },
          }}
        />
      </div>
    </div>
  )
}

export default Lettering
