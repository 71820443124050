import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import MuiGrid from '../../../components/base/grid/Grid'
import { loader } from '../../../components/recoil/atoms/atoms'
import fetchSelector from '../../../components/recoil/selectors/selectors'
import SearchEngine from '../../../components/base/searchBox/SearchBar'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'

function NoteCategory() {
  const APIEndPoints = useAPIEndpoints()
  const getData = useRecoilValue(fetchSelector)
  const [employmentList, setEmploymentList] = useState([])
  const [loading, setLoading] = useRecoilState(loader)
  const [rowData, setRowData] = useState([])
  const [filteredRows, setFilteredRows] = useState(employmentList)

  useEffect(() => {
    setLoading(true)

    const getEmploymentTypes = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetNoteCategories.method,
          APIEndPoints.GetNoteCategories.url
        )
        setLoading(false)
        setEmploymentList(
          response.data?.map((value) => ({
            id: value?.id,
            categoryName: value?.category,
          }))
        )
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }

    getEmploymentTypes()
  }, [])

  useEffect(() => {
    const rows = employmentList?.map((employmentList, index) => {
      return {
        id: employmentList?.id,
        categoryName: employmentList?.categoryName,
      }
    })

    setRowData(rows)
  }, [employmentList])

  console.log('rowData-->', rowData)

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
    },
    {
      field: 'categoryName',
      headerName: 'Category Name',
      flex: 1,
    },
  ]

  return (
    <div className="employmentContent">
      <div className="employmentHeader">Note Categories</div>
      <div className="employmentContainer">
        <div>
          <SearchEngine
            value=""
            originalRows={employmentList}
            setRows={setFilteredRows}
            className="Usersearch-engine"
          />{' '}
        </div>
      </div>
      <MuiGrid
        rowData={filteredRows}
        columnData={columns}
        setRowData={setEmploymentList}
      />
    </div>
  )
}

export default NoteCategory
