// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiFormLabel-root {
  color: black !important;
  z-index: 1 !important;
  font-size: var(--default-font-size) !important;
}
/* .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--default-border-color) !important;
}
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}
label#standard-error-label {
  color: #d32f2f !important;
} */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.MuiInputBase-sizeSmall {
  color: black !important;
}
.TextFieldParent.TextFieldError .MuiFormLabel-root {
  color: #d32f2f !important;
}

.TextFieldParent .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--default-border-color) !important;
}
svg.MuiSvgIcon-root.eyeIcon {
  height: 18px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/textBox/TextField.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,8CAA8C;AAChD;AACA;;;;;;;;GAQG;AACH;EACE,uBAAuB;AACzB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wDAAwD;AAC1D;AACA;EACE,YAAY;AACd;AACA;;;;EAIE,qDAAqD;AACvD","sourcesContent":[".MuiFormLabel-root {\n  color: black !important;\n  z-index: 1 !important;\n  font-size: var(--default-font-size) !important;\n}\n/* .Mui-focused .MuiOutlinedInput-notchedOutline {\n  border-color: var(--default-border-color) !important;\n}\nfieldset.MuiOutlinedInput-notchedOutline {\n  border-color: #d32f2f !important;\n}\nlabel#standard-error-label {\n  color: #d32f2f !important;\n} */\n.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.MuiInputBase-sizeSmall {\n  color: black !important;\n}\n.TextFieldParent.TextFieldError .MuiFormLabel-root {\n  color: #d32f2f !important;\n}\n\n.TextFieldParent .Mui-disabled .MuiOutlinedInput-notchedOutline {\n  border: 2px solid var(--default-border-color) !important;\n}\nsvg.MuiSvgIcon-root.eyeIcon {\n  height: 18px;\n}\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n  -webkit-box-shadow: 0 0 0 30px white inset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
