import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import ButtonComponent from '../../../components/base/button/Button'
import DataGrid from '../../../components/base/grid/Grid'
import AddOutlinedIcon from '../../../../assets/images/addIconPlus.svg'
import {
  aadObjectId,
  dialogPopUp,
  loader,
  loggedUserDetails,
  popOpen,
  toastMessage,
} from '../../../components/recoil/atoms/atoms'
import '../accounts/Accounts.css'
import TextBox from '../../../components/base/textBox/TextField'
import editIcon from '../../../../assets/images/EditFilled.png'
import SwitchButton from '../../../components/base/switch/SwitchButton'
import TimelineStep from '../../../components/base/timeLine/TimelineStep'
import SidePopup from '../../../components/base/popper/SidePopup'
import SaveIcon from '../../../../assets/images/Save_Icon_Grid.svg'
import Popup from '../../../components/base/popup/Popup'
import Cancel from '../../../../assets/images/CancelIcon_Grid.png'
import SearchEngine from '../../../components/base/searchBox/SearchBar'
import fetchSelector from '../../../components/recoil/selectors/selectors'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'
import '../agent/agentPages/Employment.css'
import ToastMessage from '../../../components/base/toastMessage/ToastMessage'
import './CaseManagement.css'
import AutoCompleteComponent from '../../../components/base/autoComplete/AutoComplete'

function WorkOutcome() {
  const APIEndPoints = useAPIEndpoints()
  const [anchorEl, setAnchorEl] = useState(null)
  const [popup, setPopup] = useRecoilState(dialogPopUp)
  const [open, setOpen] = useRecoilState(popOpen)
  const getData = useRecoilValue(fetchSelector)
  const [statusList, setStatusList] = useState([])
  const [loading, setLoading] = useRecoilState(loader)
  const [historyData, setHistoryData] = useState(null)
  const [rowData, setRowData] = useState([])
  const [filteredRows, setFilteredRows] = useState(statusList)
  const [crudOperationStatus, setCrudOperationStatus] = useState(false)
  const [rowModesData, setRowModesData] = useState({})
  const [updateRowData, setUpdateRowData] = useState(null)
  const [placement, setPlacement] = React.useState()
  const [crudDetails, setCrudDetails] = useState({
    id: '',
    operation: '',
    editFieldToFocus: '',
  })
  const [accountType, setAccountType] = React.useState(null)
  const [rowId, setRowId] = useState()
  const [toastShown, setToastShown] = useState({
    fieldsRequired: false,
    success: false,
    statusMassage: '',
    contentMessage: '',
  })
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const [azureID, setAzureID] = useRecoilState(aadObjectId)
  const [docTypeAdded, setDocTypeAdded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [userRolesValues, setUserRolesValues] = useState([])
  const [loggedUserData, setLoggedUserData] = useRecoilState(loggedUserDetails)
  useEffect(() => {
    if (loggedUserData) {
      const rolesArray = loggedUserData[0]?.roles?.map((role) => role?.aadValue)
      const userValues = rolesArray?.filter(
        (value) => value.includes('Config') || value.includes('Admin')
      )
      setUserRolesValues(userValues)
    }
  }, [loggedUserData])

  useEffect(() => {
    const rolesToCheck = [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ]
    const hasEditMode = userRolesValues.some((filterValues) =>
      rolesToCheck.includes(filterValues)
    )
    setEditMode(hasEditMode)
  }, [userRolesValues])
  useEffect(() => {
    setLoading(true)

    const getStatus = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetOutcomes.method,
          APIEndPoints.GetOutcomes.url
        )
        setLoading(false)
        setStatusList(
          response.data?.map((value) => ({
            id: value?.id,
            name: value?.name,
            workOutcomeCategory: value?.outcomeCategory,
            outcomeCategoryID: value?.outcomeCategoryID,
            status: value?.enabled ? 'Enable' : 'Disable',
            workOutcome: {
              outcomeCategoryID: value?.outcomeCategoryID,
              title: value?.outcomeCategory,
            },
          }))
        )
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }

    getStatus()
  }, [docTypeAdded])

  // UseEffect sets setRowData
  useEffect(() => {
    const rows = statusList?.map((clients, index) => {
      return {
        id: clients?.id,
        name: clients?.name,
        workOutcomeCategory: clients?.outcomeCategory,
        status: clients?.enabled,
        outcomeCategoryID: clients?.outcomeCategoryID,
        workOutcome: {
          outcomeCategoryID: clients?.outcomeCategoryID,
          title: clients?.outcomeCategory,
        },
      }
    })

    setRowData(rows)
  }, [statusList])

  const handleEditClick = (row) => {
    const { id } = row
    setUpdateStatus((prev) => ({
      ...prev,
      rowId: row?.id,
      outcomeCategoryID: row?.workOutcome?.outcomeCategoryID,
      outcomeCategoryName: row?.workOutcomeCategory,
      status: row?.status === 'Enable' ? true : false,
      workOutcome: row?.workOutcome,
    }))
    setCrudOperationStatus(true)
    setCrudDetails({
      id,
      operation: 'Edit',
      editFieldToFocus: 'typeSelector',
    })
    setUpdateRowData(row)
    setAccountType('editUpdate')
    // setEditingRowId(id)
    setSearchValue('')
  }

  const ConfirmationSavePopupContent = () => {
    const id = rowId
    const handleUpdate = async () => {
      const hasErrors = checkForEmptyFields()
      if (!hasErrors) {
        updateStatusType()
      }
    }

    const updateStatusType = async () => {
      setLoading(true)
      try {
        const response = await getData(
          APIEndPoints?.EditOutcome?.method,
          APIEndPoints?.EditOutcome?.url,
          {
            azureUserObjectID: azureID,
            outcomeID: updateStatus?.rowId,
            outcomeCategoryID: updateStatus?.outcomeCategoryID,
            enabled: updateStatus?.status,
          }
        )
        setDocTypeAdded(!docTypeAdded)
        setLoading(false)
        setPopup(false)
        setAccountType(null)
        setUpdateStatus({
          outcomeCategoryID: '',
          outcomeCategoryName: '',
          status: false,
        })
      } catch (error) {
        console.error('Error adding user:', error)
      }
    }
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Confirm</div>
        <div>Are you sure to update the status?</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => {
              setPopup(false)
              setAccountType(null)
            }}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={handleUpdate}
          />
        </div>
      </div>
    )
  }

  const ConfirmationPopupContent = () => {
    const id = rowId
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Cancel</div>
        <div>Are you sure to cancel the status?</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="YES"
            onClick={() => {
              setAccountType(null)
              setPopup(false)
            }}
          />
          <ButtonComponent
            className="withBackground"
            text="NO"
            onClick={() => {
              setAccountType('editUpdate')
              setPopup(false)
            }}
          />
        </div>
      </div>
    )
  }

  const id = rowId
  const [updateStatus, setUpdateStatus] = useState({
    outcomeCategoryID: '',
    outcomeCategoryName: '',
    status: false,
  })
  const [error, setError] = useState()
  const checkForEmptyFields = () => {
    const newErrorState = {}

    for (const key in updateStatus) {
      newErrorState[key] =
        updateStatus[key] === '' ||
        updateStatus[key] === null ||
        (typeof updateStatus[key] === 'string' &&
          updateStatus[key].trim().length === 0) ||
        updateStatus[key] === 'Invalid Date'
    }

    setError(newErrorState)
    return Object.values(newErrorState).some((value) => value === true)
  }

  const switchChangeHandlerStatus = (event, params) => {
    const { value } = params

    setUpdateStatus((prevDetails) => ({
      ...prevDetails,
      status: value,
    }))
  }

  const trainingAutoCompleteChange = (event, params) => {
    setUpdateStatus((prevValues) => ({
      ...prevValues,
      outcomeCategoryID: params?.trainingId,
      outcomeCategoryName: params,
    }))
    setError((prevError) => ({
      ...prevError,
      outcomeCategoryName: false,
    }))
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
      maxWidth: 150,
    },
    {
      field: 'name',
      headerName: 'Work Outcome Name',
      flex: 1,
    },
    {
      field: 'workOutcomeCategory',
      headerName: 'Work Outcome category',
      renderCell: ({ row }) => {
        return (
          <>
            {(accountType === 'editUpdate' ||
              accountType === 'confirmUpdate' ||
              accountType === 'cancelUpdate') &&
            updateRowData?.id === row?.id ? (
              <AutoCompleteComponent
                placeholder="Select"
                options={trainingNames}
                selectChangeHandler={trainingAutoCompleteChange}
                initialValue={updateStatus?.workOutcome}
                error={error?.outcomeCategoryName}
              />
            ) : (
              <div>
                <p>{row?.workOutcomeCategory}</p>
              </div>
            )}
          </>
        )
      },
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: ({ row }) => {
        return (
          <>
            {(accountType === 'editUpdate' ||
              accountType === 'confirmUpdate' ||
              accountType === 'cancelUpdate') &&
            updateRowData?.id === row?.id ? (
              <SwitchButton
                switchLabel={updateStatus?.status ? 'Enable' : 'Disable'}
                switchValue={updateStatus?.status}
                onChangeHandler={switchChangeHandlerStatus}
              />
            ) : (
              <div
                className={`${
                  row?.status === 'Enable'
                    ? 'agent-statusCell Enable'
                    : 'agent-statusCell Disable'
                }`}
              >
                <div className="statusText">
                  {row?.status === 'Enable' ? 'Enable' : 'Disable'}
                </div>
              </div>
            )}
          </>
        )
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      sortable: false,
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      type: 'actions',

      renderCell: ({ row }) => {
        if (
          (accountType === 'editUpdate' ||
            accountType === 'confirmUpdate' ||
            accountType === 'cancelUpdate') &&
          updateRowData?.id === row?.id
        ) {
          return (
            <div className="actionIcons-container">
              <img
                onClick={() => {
                  setPopup(true)
                  setAccountType('confirmUpdate')
                }}
                src={SaveIcon}
                className="SaveIcon"
                alt="SaveIcon"
              />

              <img
                onClick={() => {
                  setPopup(true)
                  setAccountType('cancelUpdate')
                }}
                src={Cancel}
                className="CancelIcon"
                alt="CancelIcon"
              />
            </div>
          )
        }
        return (
          <div className="actionIcons-container">
            {editMode && (
              <img
                className="EditIcon"
                src={editIcon}
                onClick={() => handleEditClick(row)}
              />
            )}
          </div>
        )
      },
    },
  ]
  const timelineHistory = (newPlacement, id) => (event) => {
    viewHistory(id)
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }
  const viewHistory = async (detailsId) => {
    const getEmploymentTypeDetails = await getData(
      APIEndPoints.GetOutcomeDetails.method,
      ` ${APIEndPoints.GetOutcomeDetails.url}?outcomeID=${detailsId}`
    )
    const details = getEmploymentTypeDetails?.data
    const steps = details?.audits?.map((audits) => {
      let updatedMessage = audits.message
      if (updatedMessage.includes('(Old Value): 0')) {
        updatedMessage = updatedMessage.replace(
          '(Old Value): 0',
          '(Old Value): Disabled'
        )
      } else if (updatedMessage.includes('(Old Value): 1')) {
        updatedMessage = updatedMessage.replace(
          '(Old Value): 1',
          '(Old Value): Enabled'
        )
      }
      if (updatedMessage.includes('(New Value): 0')) {
        updatedMessage = updatedMessage.replace(
          '(New Value): 0',
          '(New Value): Disabled'
        )
      } else if (updatedMessage.includes('(New Value): 1')) {
        updatedMessage = updatedMessage.replace(
          '(New Value): 1',
          '(New Value): Enabled'
        )
      }
      return {
        dateValue: new Date(audits?.createdOn).toISOString().split('T')[0],
        updatedUserName: `Name:- ${audits?.createdBy} `,
        userMessage: `Message:- ${updatedMessage}`,
      }
    })
    return setHistoryData(
      <div className="employmentHistory">
        <TimelineStep
          timelineHead="History / Audit Log"
          timelineContent={steps}
        />
      </div>
    )
  }

  useEffect(() => {
    const closeDialogOnOutsideClick = (event) => {
      if (open && !event.target.closest('.dialog')) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', closeDialogOnOutsideClick)
    return () => {
      document.removeEventListener('mousedown', closeDialogOnOutsideClick)
    }
  }, [open, setOpen])

  const [outcomeCategories, setOutcomeCategories] = useState([])
  useEffect(() => {
    setLoading(true)
    const getTraining = async () => {
      await getData(
        APIEndPoints.GetOutcomeCategories.method,
        APIEndPoints.GetOutcomeCategories.url
      )
        .then((response) => {
          setLoading(false)
          setOutcomeCategories(response.data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
    getTraining()
  }, [])
  const trainingNames = outcomeCategories
    .filter((status) => status?.enabled)
    ?.map((training) => {
      return {
        title: training?.name,
        trainingId: training?.id,
      }
    })
  const AddAccounts = () => {
    const [clientType, setClientType] = useState({
      name: '',
      outcomeCategoryName: null,
      outcomeCategoryID: null,
    })
    const [error, setError] = useState()

    const onchangeHandler = (event, params, rest) => {
      const { id, value } = params
      setClientType((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }))
      setError((prevError) => ({
        ...prevError,
        [id]: value === '',
      }))
    }
    const checkForEmptyFields = () => {
      const newErrorState = {}

      for (const key in clientType) {
        newErrorState[key] =
          clientType[key] === '' ||
          clientType[key] === null ||
          (typeof clientType[key] === 'string' &&
            clientType[key].trim().length === 0) ||
          clientType[key] === 'Invalid Date'
      }

      setError(newErrorState)

      return Object.values(newErrorState).some((value) => value === true)
    }
    const handleAdd = async () => {
      const hasErrors = checkForEmptyFields()
      if (!hasErrors) {
        const normalizedValue = clientType?.name?.trim().toLowerCase()
        const isMatch = await filteredRows.some(
          (obj) => obj?.name.trim().toLowerCase() === normalizedValue
        )
        if (isMatch) {
          setToastOpen(true)
          setToastShown({
            fieldsRequired: true,
            success: false,
            statusMassage: 'Process Declined!',
            contentMessage: 'This name already exists',
          })
        } else {
          addEmploymentType()
        }
      }
    }
    const addEmploymentType = async () => {
      setLoading(true)

      try {
        const response = await getData(
          APIEndPoints?.AddOutcome?.method,
          APIEndPoints?.AddOutcome?.url,
          {
            azureUserObjectID: azureID,
            name: clientType?.name,
            outcomeCategoryID: clientType?.outcomeCategoryID,
          }
        )

        if (response?.status === 200 && response?.statusText === 'OK') {
          setToastShown({
            fieldsRequired: false,
            success: true,
            duplicateName: false,
            statusMassage: 'Success!',
            contentMessage: 'Record Added successfully!',
          })
          setToastOpen(true)
        } else {
          setToastShown({
            fieldsRequired: false,
            success: false,
            statusMassage: 'Error!',
            contentMessage: 'Process Declined!',
          })
          setToastOpen(true)
        }
        setDocTypeAdded(!docTypeAdded)
        setLoading(false)
        setPopup(false)
      } catch (error) {
        console.error('Error adding user:', error)
      }
    }

    const handleClose = () => {
      setPopup(false)
      setClientType({
        name: '',
      })
    }

    const trainingAutoCompleteChange = (event, params) => {
      setClientType((prevValues) => ({
        ...prevValues,
        outcomeCategoryID: params?.trainingId,
        outcomeCategoryName: params,
      }))
      setError((prevError) => ({
        ...prevError,
        outcomeCategoryName: false,
      }))
    }
    return (
      <div className="add_employmentType">
        <div className="employmentTitle">Add Work Outcome</div>
        <TextBox
          label="Work Outcome Name"
          fieldName="name"
          value={clientType?.name}
          onchangeHandler={onchangeHandler}
          error={error?.name}
        />{' '}
        <AutoCompleteComponent
          placeholder="Select"
          options={trainingNames}
          label="Work Outcome Category"
          selectChangeHandler={trainingAutoCompleteChange}
          initialValue={clientType?.outcomeCategoryName}
          error={error?.outcomeCategoryName}
        />
        <div className="employmentAction">
          <ButtonComponent
            className="withoutBackground"
            text="Close"
            onClick={handleClose}
          />
          <ButtonComponent
            className="withBackground"
            text="ADD"
            variant="contained"
            onClick={handleAdd}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="employmentContent">
      <div className="employmentHeader">Work Outcomes</div>
      <div className="employmentContainer">
        <SearchEngine
          value=""
          searchClear={searchValue}
          originalRows={statusList}
          setRows={setFilteredRows}
        />
        {editMode && (
          <ButtonComponent
            startIcon={<img src={AddOutlinedIcon} />}
            text="Add"
            className="withBackground"
            onClick={() => {
              setPopup(true)
              setAccountType('add')
            }}
          />
        )}
      </div>

      <DataGrid
        rowData={filteredRows}
        columnData={columns}
        crudDetails={crudDetails}
        setCrudOperationStatus={setCrudOperationStatus}
        setRowModesData={setRowModesData}
        rowModesData={rowModesData}
        setRowData={setStatusList}
      />

      <div>
        <SidePopup
          className="employmentHistory_PopUp"
          anchorEl={anchorEl}
          placement={placement}
          dialogContent={historyData}
        />
      </div>
      {popOpen && (
        <Popup
          className="cases-addStatus"
          popContent={
            accountType === 'add' ? (
              <AddAccounts />
            ) : accountType === 'confirmUpdate' ? (
              <ConfirmationSavePopupContent />
            ) : accountType === 'cancelUpdate' ? (
              <ConfirmationPopupContent />
            ) : null
          }
        />
      )}

      <div>
        {toastOpen && toastShown.success && (
          <ToastMessage
            statusMassage={
              toastShown?.statusMassage ? toastShown?.statusMassage : 'SUCCESS!'
            }
            contentMessage={
              toastShown?.contentMessage
                ? toastShown?.contentMessage
                : 'Record Added successfully'
            }
          />
        )}
        {toastOpen && toastShown.fieldsRequired && (
          <ToastMessage
            statusMassage={
              toastShown?.statusMassage
                ? toastShown?.statusMassage
                : 'Process Declined'
            }
            contentMessage={
              toastShown?.contentMessage
                ? toastShown?.contentMessage
                : 'All Fields are Required'
            }
            unsuccessStatus
          />
        )}
        {toastOpen && toastShown.duplicateName && (
          <ToastMessage
            statusMassage={
              toastShown?.statusMassage
                ? toastShown?.statusMassage
                : 'Process Declined'
            }
            contentMessage={
              toastShown?.contentMessage
                ? toastShown?.contentMessage
                : 'This name already exists'
            }
            unsuccessStatus
          />
        )}
      </div>
    </div>
  )
}

export default WorkOutcome
