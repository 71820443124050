// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabTitleButton {
  height: 30px;
  padding: 8px;
  /* border-radius: 6px; */
  text-transform: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  font-size: 0.7rem;
  white-space: nowrap;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: #1f3c88;
}
span.MuiTabs-indicator {
  background-color: #1f3c88;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tabTitleButtons {
  background-color: rgba(226, 232, 248, 1);
  padding: 0;
  min-height: 0 !important;
  align-items: flex-start;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  padding: 0;
  background-color: white;
  min-height: 0;
}
.MuiTabs-flexContainer.MuiTabs-flexContainerVertical {
  gap: 5px !important;
}
.MuiTabs-root.tab {
  min-height: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/tabs/Tabs.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,wBAAwB;EACxB,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,wCAAwC;EACxC,UAAU;EACV,wBAAwB;EACxB,uBAAuB;AACzB;AACA;EACE,UAAU;EACV,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,mBAAmB;AACrB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".tabTitleButton {\n  height: 30px;\n  padding: 8px;\n  /* border-radius: 6px; */\n  text-transform: none;\n  font-weight: 500;\n  display: inline-flex;\n  align-items: center;\n  font-size: 0.7rem;\n  white-space: nowrap;\n}\nbutton.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {\n  color: #1f3c88;\n}\nspan.MuiTabs-indicator {\n  background-color: #1f3c88;\n}\nbutton.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tabTitleButtons {\n  background-color: rgba(226, 232, 248, 1);\n  padding: 0;\n  min-height: 0 !important;\n  align-items: flex-start;\n}\nbutton.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {\n  padding: 0;\n  background-color: white;\n  min-height: 0;\n}\n.MuiTabs-flexContainer.MuiTabs-flexContainerVertical {\n  gap: 5px !important;\n}\n.MuiTabs-root.tab {\n  min-height: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
