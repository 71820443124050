const NotifyDataDummy = [
  {
    id: 1,
    userName: 'TS',
    updateMessage: 'User Detail Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Unread',
  },
  {
    id: 2,
    userName: 'JM',
    updateMessage: 'User Time Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Read',
  },
  {
    id: 3,
    userName: 'GS',
    updateMessage: 'User File Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Read',
  },
  {
    id: 4,
    userName: 'SM',
    updateMessage: 'User File Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Unread',
  },
  {
    id: 5,
    userName: 'STR',
    updateMessage: 'User Profile Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Unread',
  },
  {
    id: 5,
    userName: 'STR',
    updateMessage: 'User Profile Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Read',
  },
  {
    id: 6,
    userName: 'XYZ',
    updateMessage: 'User Profile Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Unread',
  },
  {
    id: 7,
    userName: 'STR',
    updateMessage: 'User Profile Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Read',
  },
  {
    id: 5,
    userName: 'STR',
    updateMessage: 'User Profile Updated',
    updateTime: '15 Min',
    userPage: 'Users Page',
    status: 'Unread',
  },
]
export default NotifyDataDummy
