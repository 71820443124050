import React from 'react'
import backgroundImage from '../../../../assets/images/loginPage_M-logo.png'
import errorSomething from '../../../../assets/images/Error/SomethingWentWrong.svg'
import './ErrorLayout.css'
import ButtonComponent from '../button/Button'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getErrorCode } from '../../recoil/atoms/atoms'

const Something = () => {
  const navigate = useNavigate()
  const [errorNull, setErrorNull] = useRecoilState(getErrorCode)

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
      }}
      className="errorContainer"
    >
      <div className="errorImg504">
        <img src={errorSomething} alt="Error 404" />
        <div className="errorMessage504">Something Went Wrong</div>
        <div className="errorMessage">
          The web page have encountered an unknown error. Maintenance team is
          working to fix it.
        </div>
        <div className="errorButton-Container">
          <ButtonComponent
            className="withBackground"
            text="Back to Home"
            onClick={() => {
              navigate('/')
              setErrorNull(null)
              localStorage.clear()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Something
