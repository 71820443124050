import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import './Tabs.css'

const TabPanel = (props) => {
  const { children } = props
  return <div className="tab_content">{children}</div>
}

const HeaderTab = ({
  dataSource,
  selectedTabIndex,
  onChangeHandler,
  orientation,
  TabIndicatorProps,
}) => {
  const onChange = (event, newSelectedIndex) => {
    onChangeHandler(event, newSelectedIndex)
  }

  const generateTabTitleComponent = (item, index) => (
    <div
      className={`tabTitleButton ${
        selectedTabIndex === index ? 'selected' : ''
      }`}
    >
      {item?.tabTitle}
    </div>
  )
  return (
    <div className="Tab_main">
      <div className="Tab_child">
        <Tabs
          className="tab"
          orientation={orientation}
          value={selectedTabIndex}
          onChange={onChange}
          TabIndicatorProps={TabIndicatorProps}
        >
          {dataSource?.map((item, index) => {
            return (
              <Tab
                value={index}
                key={index}
                label={generateTabTitleComponent(item, index)}
                className={`tabTitleButtons ${
                  selectedTabIndex === index ? 'selected' : ''
                }`}
              />
            )
          })}
        </Tabs>
      </div>
      <div className="TabContent-Container">
        {dataSource?.map((item, index) => {
          if (index === selectedTabIndex)
            return (
              <TabPanel value={selectedTabIndex} index={index} key={index}>
                {item?.tabContent}
              </TabPanel>
            )
        })}
      </div>
    </div>
  )
}

export default HeaderTab
