import React from 'react'
import { popOpen } from '../../recoil/atoms/atoms'
import { useRecoilState } from 'recoil'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import './SidePopup.css'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
// import { DataGrid } from '../muiGrid/MUIGrid'

const SidePopup = ({
  dialogTitle,
  dialogContent,
  dialogActions,
  placement,
  anchorEl,
  className,
}) => {
  const [open, setOpen] = useRecoilState(popOpen)

  if (!open || !anchorEl) {
    return null
  }

  return (
    <div className="muiPopUp">
      <Popper
        className="popperContainer"
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={className}>
              <DialogTitle className="dialogTitle">{dialogTitle}</DialogTitle>
              <DialogContent>{dialogContent}</DialogContent>
              <DialogActions>{dialogActions}</DialogActions>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default SidePopup
