import React from 'react'
import backgroundImage from '../../../../assets/images/loginPage_M-logo.png'
import error504 from '../../../../assets/images/Error/Error504.svg'
import './ErrorLayout.css'
import ButtonComponent from '../button/Button'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getErrorCode } from '../../recoil/atoms/atoms'

const MyComponent = () => {
  const navigate = useNavigate()
  const [errorNull, setErrorNull] = useRecoilState(getErrorCode)

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
      }}
      className="errorContainer"
    >
      <div className="errorImg504">
        <img src={error504} alt="Error 404" />
        <div className="errorMessage504">Gateway Timeout</div>
        <div className="errorMessage">
          The server is not reachable. Retry by working back on Home Page.
        </div>
        <div className="errorButton-Container">
          <ButtonComponent
            className="withBackground"
            text="Retry"
            onClick={() => window.location.reload(false)}
          />
          <ButtonComponent
            className="errorPageButton"
            text="Back to Home"
            onClick={() => {
              navigate('/')
              setErrorNull(null)
              localStorage.clear()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MyComponent
