import React from 'react'
import './SlidingPane.css'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { useRecoilState } from 'recoil'
import {
  isPaneOpen,
  jobTitleSlide,
  runNowSlide,
} from '../../recoil/atoms/atoms'

function Slider({ SliderComponent, className, sliderName }) {
  const [panStatus, setPanStatus] = useRecoilState(
    sliderName === 'jobTitle'
      ? jobTitleSlide
      : sliderName === 'runNow'
      ? runNowSlide
      : isPaneOpen
  )

  return (
    <div>
      <SlidingPane
        className={`slidingPane-slider ${className}`}
        overlayClassName="some-custom-overlay-class"
        isOpen={panStatus}
        // title="Hey, it is optional pane title.  I can be React component too."
        // subtitle="Optional subtitle."
        // onRequestClose={() => {
        //   setPanStatus(false)
        // }}
      >
        <div>{SliderComponent}</div>
      </SlidingPane>
    </div>
  )
}
export default Slider
