// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fieldset.MuiOutlinedInput-notchedOutline {
  border-width: 2px;
  border-color: var(--default-border-color) !important;
}
.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
  width: 100%;
}
.autoComplete_main {
  width: 100%;
}
.MuiAutocomplete-option.Mui-focused {
  font-size: var(--default-font-size);
}
.MuiAutocomplete-option {
  font-size: var(--default-font-size);
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  height: 15px;
}
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
  border: 2px solid;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/autoComplete/AutoComplete.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oDAAoD;AACtD;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,mCAAmC;AACrC;AACA;EACE,mCAAmC;AACrC;AACA;EACE,YAAY;AACd;AACA;EACE,gCAAgC;EAChC,iBAAiB;AACnB","sourcesContent":["fieldset.MuiOutlinedInput-notchedOutline {\n  border-width: 2px;\n  border-color: var(--default-border-color) !important;\n}\n.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {\n  width: 100%;\n}\n.autoComplete_main {\n  width: 100%;\n}\n.MuiAutocomplete-option.Mui-focused {\n  font-size: var(--default-font-size);\n}\n.MuiAutocomplete-option {\n  font-size: var(--default-font-size);\n}\nsvg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {\n  height: 15px;\n}\n.Mui-error .MuiOutlinedInput-notchedOutline {\n  border-color: #d32f2f !important;\n  border: 2px solid;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
