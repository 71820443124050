import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import ButtonComponent from '../../../components/base/button/Button'
import DataGrid from '../../../components/base/grid/Grid'
import AddOutlinedIcon from '../../../../assets/images/addIconPlus.svg'
import {
  aadObjectId,
  dialogPopUp,
  loader,
  loggedUserDetails,
  popOpen,
  toastMessage,
} from '../../../components/recoil/atoms/atoms'
import '../accounts/Accounts.css'
import TextBox from '../../../components/base/textBox/TextField'
import Edit from '../../../../assets/images/EditFilled.png'
import SwitchButton from '../../../components/base/switch/SwitchButton'
import SidePopup from '../../../components/base/popper/SidePopup'
import SaveIcon from '../../../../assets/images/Save_Icon_Grid.svg'
import Popup from '../../../components/base/popup/Popup'
import Cancel from '../../../../assets/images/CancelIcon_Grid.png'
import SearchEngine from '../../../components/base/searchBox/SearchBar'
import fetchSelector from '../../../components/recoil/selectors/selectors'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'
import checkIcon from '../../../../assets/images/CheckCircleFilled.svg'
import closeIcon from '../../../../assets/images/cancelClose.svg'
import '../agent/agentPages/Employment.css'
import ToastMessage from '../../../components/base/toastMessage/ToastMessage'
import './CaseManagement.css'

function Status() {
  const APIEndPoints = useAPIEndpoints()
  const [popup, setPopup] = useRecoilState(dialogPopUp)
  const getData = useRecoilValue(fetchSelector)
  const [statusList, setStatusList] = useState([])
  const [loading, setLoading] = useRecoilState(loader)
  const [historyData, setHistoryData] = useState(null)
  const [rowData, setRowData] = useState([])
  const [filteredRows, setFilteredRows] = useState(statusList)
  const [crudOperationStatus, setCrudOperationStatus] = useState(false)
  const [rowModesData, setRowModesData] = useState({})
  const [placement, setPlacement] = React.useState()
  const [crudDetails, setCrudDetails] = useState({
    id: '',
    operation: '',
    editFieldToFocus: '',
  })
  const [accountType, setAccountType] = React.useState(null)
  const [rowId, setRowId] = useState()
  const [toastShown, setToastShown] = useState({
    fieldsRequired: false,
    success: false,
    statusMassage: '',
    contentMessage: '',
  })
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const azureID = useRecoilValue(aadObjectId)
  const [docTypeAdded, setDocTypeAdded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [popupFieldError, setPopupFieldError] = useState({
    clientName: false,
    clientWorkflows: false,
    clientLetterTemplateData: false,
    order: false,
  })
  const [editMode, setEditMode] = useState(false)
  const [userRolesValues, setUserRolesValues] = useState([])
  const [loggedUserData, setLoggedUserData] = useRecoilState(loggedUserDetails)
  useEffect(() => {
    if (loggedUserData) {
      const rolesArray = loggedUserData[0]?.roles?.map((role) => role?.aadValue)
      const userValues = rolesArray?.filter(
        (value) => value.includes('Config') || value.includes('Admin')
      )
      setUserRolesValues(userValues)
    }
  }, [loggedUserData])

  useEffect(() => {
    const rolesToCheck = [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ]
    const hasEditMode = userRolesValues.some((filterValues) =>
      rolesToCheck.includes(filterValues)
    )
    setEditMode(hasEditMode)
  }, [userRolesValues])
  useEffect(() => {
    setLoading(true)

    const getStatus = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetStatusesQualityControl.method,
          APIEndPoints.GetStatusesQualityControl.url
        )
        setLoading(false)
        setStatusList(
          response.data?.map((value) => ({
            id: value?.statusID,
            name: value?.name,
            description: value?.description,
            order: value?.order,
            closed: value?.closed ? 'Yes' : 'No',
            status: value?.enabled ? 'Enable' : 'Disable',
          }))
        )
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }

    getStatus()
  }, [docTypeAdded])

  useEffect(() => {
    const rows = statusList?.map((clients, index) => {
      return {
        id: clients?.id,
        name: clients?.name,
        description: clients?.description,
        order: clients?.order,
        closed: clients?.closed ? 'Yes' : 'No',
        status: clients?.enabled ? 'Enable' : 'Disable',
      }
    })

    setRowData(rows)
  }, [statusList])
  const [updateStatus, setUpdateStatus] = useState({
    status: false,
    closed: false,
    order: '',
  })

  const handleEditClick = (row) => {
    if (accountType === 'editUpdate') {
      setPopup(true)
      setAccountType('cancelUpdate')
    } else {
      setAccountType('editUpdate')
      setRowId(row?.id)
      setUpdateStatus((prev) => ({
        ...prev,
        id: row?.id,
        status: row?.status === 'Disable' ? false : true,
        closed: row?.closed === 'No' ? false : true,
        order: row?.order,
      }))
    }

    setSearchValue('')
  }

  const ConfirmationSavePopupContent = () => {
    const updateStatusType = async () => {
      setLoading(true)
      try {
        await getData(
          APIEndPoints?.EditCaseStatus?.method,
          APIEndPoints?.EditCaseStatus?.url,
          {
            azureUserObjectID: azureID,
            order: updateStatus?.order,
            closed: updateStatus?.closed,
            statusID: id,
            enabled: updateStatus?.status,
          }
        )
        setPopup(false)
        setAccountType(null)
        setDocTypeAdded(!docTypeAdded)
        setLoading(false)
        setUpdateStatus({
          order: '',
          status: false,
          closed: false,
        })
      } catch (error) {
        console.error('Error adding user:', error)
      }
    }
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Confirm</div>
        <div>Are you sure to save ?</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => {
              setPopup(false)
            }}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={updateStatusType}
          />
        </div>
      </div>
    )
  }

  const ConfirmationPopupContent = () => {
    const id = rowId
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Cancel</div>
        <div>Are you sure to cancel ?</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => {
              setAccountType('editUpdate')
              setPopup(false)
            }}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={() => {
              setAccountType(null)
              setPopup(false)
              setError({})
            }}
          />
        </div>
      </div>
    )
  }

  const id = rowId
  const [error, setError] = useState({})

  const checkForEmptyFields = () => {
    const newErrorState = {}

    for (const key in updateStatus) {
      newErrorState[key] =
        updateStatus[key] === '' ||
        updateStatus[key] === null ||
        (typeof updateStatus[key] === 'string' &&
          updateStatus[key].trim().length === 0) ||
        updateStatus[key] === 'Invalid Date'
    }

    setError(newErrorState)

    return Object.values(newErrorState).some((value) => value === true)
  }

  const textBoxchangeHandler = (event, params) => {
    const id = params?.id
    const value = event.target.value
    const valueIsDecimal = value % 1 !== 0
    const valueIsNegative = value < 0

    if (valueIsDecimal) {
      setUpdateStatus((prevInfo) => ({
        ...prevInfo,
        order: Number(value?.toString().split('.')[0]),
      }))
    } else if (valueIsNegative) {
      setUpdateStatus((prevInfo) => ({ ...prevInfo, order: 0 }))
    } else {
      setUpdateStatus((prevInfo) => ({ ...prevInfo, order: value }))
    }

    setPopupFieldError((prevError) => ({
      ...prevError,
      [id]: value === '',
    }))
    if (value?.length === 0) {
      return setError((prev) => ({ ...prev, order: true }))
    } else {
      return setError((prev) => ({ ...prev, order: false }))
    }
  }

  const switchHandler = (event, key) => {
    setUpdateStatus((prevDetails) => ({
      ...prevDetails,
      [key]: event?.target?.checked,
    }))
  }

  const handleUpdate = () => {
    const hasErrors = checkForEmptyFields()

    const removedData = filteredRows?.filter(
      (obj) => obj?.id != updateStatus?.id
    )
    const isMatched = removedData?.some(
      (obj) => Number(obj?.order) === Number(updateStatus?.order)
    )

    if (isMatched) {
      setToastOpen(true)
      return setToastShown({
        fieldsRequired: true,
        success: false,
        statusMassage: 'Process Declined',
        contentMessage: 'Order already exists!',
      })
    } else if (!hasErrors) {
      setPopup(true)
      setAccountType('confirmUpdate')
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
      maxWidth: 150,
    },
    {
      field: 'name',
      headerName: 'Name',
      // editable: true,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      // editable: true,
      flex: 1,
    },
    {
      field: 'order',
      headerName: 'Order',
      // editable: true,
      renderCell: ({ row }) => {
        return (
          <div className="statusText">
            {(accountType === 'editUpdate' ||
              accountType === 'confirmUpdate' ||
              accountType === 'cancelUpdate') &&
            rowId === row?.id ? (
              <TextBox
                fieldName="order"
                value={updateStatus?.order}
                onchangeHandler={textBoxchangeHandler}
                error={error?.order}
                type="number"
              />
            ) : (
              <p>{row?.order}</p>
            )}
          </div>
        )
      },
      flex: 1,
    },
    {
      field: 'closed',
      headerName: 'Closed',
      headerClassName: 'mui-header',
      renderCell: ({ row }) => {
        return (
          <>
            {(accountType === 'editUpdate' ||
              accountType === 'confirmUpdate' ||
              accountType === 'cancelUpdate') &&
            rowId === row?.id ? (
              <SwitchButton
                switchLabel={updateStatus?.closed ? 'Yes' : 'No'}
                switchValue={updateStatus?.closed}
                onChangeHandler={(event) => switchHandler(event, 'closed')}
              />
            ) : (
              <div className="statusText">
                {row?.closed === 'Yes' ? (
                  <div className="agent-statusCell-Yes">
                    Yes <img src={checkIcon} />
                  </div>
                ) : (
                  <div className="agent-statusCell-No">
                    No <img src={closeIcon} />
                  </div>
                )}
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: ({ row }) => {
        return (
          <>
            {(accountType === 'editUpdate' ||
              accountType === 'confirmUpdate' ||
              accountType === 'cancelUpdate') &&
            rowId === row?.id ? (
              <SwitchButton
                switchLabel={updateStatus?.status ? 'Enable' : 'Disable'}
                switchValue={updateStatus?.status}
                onChangeHandler={(event) => switchHandler(event, 'status')}
              />
            ) : (
              <div
                className={`${
                  row?.status === 'Enable'
                    ? 'agent-statusCell Enable'
                    : 'agent-statusCell Disable'
                }`}
              >
                <div className="statusText">
                  {row?.status === 'Enable' ? 'Enable' : 'Disable'}
                </div>
              </div>
            )}
          </>
        )
      },
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   minWidth: 100,
    //   sortable: false,
    //   headerAlign: 'center',
    //   filterable: false,
    //   disableColumnMenu: true,
    //   disableReorder: true,
    //   type: 'actions',
    //   renderCell: ({ row }) => {
    //     return (
    //       <div className="actionIcons-container">
    //         {(accountType === 'editUpdate' ||
    //           accountType === 'confirmUpdate' ||
    //           accountType === 'cancelUpdate') &&
    //         rowId === row?.id ? (
    //           <div>
    //             <img
    //               onClick={() => {
    //                 if (
    //                   updateStatus?.order != row?.order ||
    //                   updateStatus?.closed !=
    //                     (row?.closed === 'Disable' ? false : true) ||
    //                   updateStatus?.status !=
    //                     (row?.status === 'No' ? false : true)
    //                 ) {
    //                   setPopup(true)
    //                   setAccountType('confirmUpdate')
    //                 } else {
    //                   setPopup(false)
    //                   setAccountType(null)
    //                 }
    //               }}
    //               style={{ marginRight: '5px' }}
    //               src={SaveIcon}
    //               className="SaveIcon"
    //               alt="SaveIcon"
    //             />
    //             <img
    //               onClick={() => {
    //                 if (
    //                   updateStatus?.order != row?.order ||
    //                   updateStatus?.closed !=
    //                     (row?.closed === 'Disable' ? false : true) ||
    //                   updateStatus?.status !=
    //                     (row?.status === 'No' ? false : true)
    //                 ) {
    //                   setPopup(true)
    //                   setAccountType('cancelUpdate')
    //                 } else {
    //                   setPopup(false)
    //                   setAccountType(null)
    //                 }
    //               }}
    //               src={Cancel}
    //               className="CancelIcon"
    //               alt="CancelIcon"
    //             />
    //           </div>
    //         ) : (
    //           <div>
    //             <img
    //               className="EditIcon"
    //               src={Edit}
    //               onClick={() => handleEditClick(row)}
    //             />
    //           </div>
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      sortable: false,
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      type: 'actions',
      renderCell: ({ row }) => {
        return (
          <div className="actionIcons-container">
            {(accountType === 'editUpdate' ||
              accountType === 'confirmUpdate' ||
              accountType === 'cancelUpdate') &&
            rowId === row?.id ? (
              <div>
                <img
                  onClick={() => {
                    if (updateStatus?.order?.length === 0) {
                      setToastOpen(true)
                      return setToastShown({
                        fieldsRequired: true,
                        success: false,
                        statusMassage: 'Process Declined',
                        contentMessage: 'Order should not empty!',
                      })
                    } else {
                      handleUpdate()
                    }
                  }}
                  style={{ marginRight: '5px' }}
                  src={SaveIcon}
                  className="SaveIcon"
                  alt="SaveIcon"
                />
                <img
                  onClick={() => {
                    setPopup(true)
                    setAccountType('cancelUpdate')
                  }}
                  src={Cancel}
                  className="CancelIcon"
                  alt="CancelIcon"
                />
              </div>
            ) : (
              <div>
                {editMode && (
                  <img
                    className="EditIcon"
                    src={Edit}
                    onClick={() => handleEditClick(row)}
                  />
                )}
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const AddAccounts = () => {
    const [clientType, setClientType] = useState({
      name: '',
      description: '',
      order: '',
      closed: false,
    })

    const [error, setError] = useState()

    const onchangeHandler = (event, params, rest) => {
      const { id, value } = params
      setClientType((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }))
      setError((prevError) => ({
        ...prevError,
        [id]: value === '',
      }))
    }
    const checkForEmptyFields = () => {
      const newErrorState = {}

      for (const key in clientType) {
        newErrorState[key] =
          clientType[key] === '' ||
          clientType[key] === null ||
          (typeof clientType[key] === 'string' &&
            clientType[key].trim().length === 0) ||
          clientType[key] === 'Invalid Date'
      }

      setError(newErrorState)

      return Object.values(newErrorState).some((value) => value === true)
    }
    const handleAdd = async () => {
      const hasErrors = checkForEmptyFields()
      if (!hasErrors) {
        const normalizedValue = clientType?.name?.trim().toLowerCase()
        const isNameMatch = await filteredRows.some(
          (obj) => obj?.name.trim().toLowerCase() === normalizedValue
        )
        const isOrderMatch = await filteredRows.some(
          (obj) => Number(obj?.order) === Number(clientType?.order)
        )
        if (isNameMatch) {
          setToastOpen(true)
          setToastShown({
            fieldsRequired: true,
            success: false,
            statusMassage: 'Process Declined!',
            contentMessage: 'Name already exists!',
          })
        } else if (isOrderMatch) {
          setToastOpen(true)
          setToastShown({
            fieldsRequired: true,
            success: false,
            statusMassage: 'Process Declined!',
            contentMessage: 'Order already exists!',
          })
        } else {
          addEmploymentType()
        }
      }
    }

    const addEmploymentType = async () => {
      setLoading(true)
      try {
        const response = await getData(
          APIEndPoints?.AddCaseStatus?.method,
          APIEndPoints?.AddCaseStatus?.url,
          {
            azureUserObjectID: azureID,
            name: clientType?.name,
            description: clientType?.description,
            order: clientType?.order,
            closed: clientType?.closed,
          }
        )

        if (response?.status === 200 && response?.statusText === 'OK') {
          setToastShown({
            fieldsRequired: false,
            success: true,
            duplicateName: false,
            statusMassage: 'Success!',
            contentMessage: 'Record Added successfully!',
          })
          setToastOpen(true)
        } else {
          setToastShown({
            fieldsRequired: false,
            success: false,
            statusMassage: 'Error!',
            contentMessage: 'Process Declined!',
          })
          setToastOpen(true)
        }

        setDocTypeAdded(!docTypeAdded)
        setLoading(false)
        setPopup(false)
      } catch (error) {
        console.error('Error adding user:', error)
      }
    }

    const handleClose = () => {
      setPopup(false)
      setClientType({
        name: '',
      })
    }

    const textBoxchangeHandler = async (event, params) => {
      const { id, value } = params
      const finalValue = Number(value)?.toFixed(0)
      const valueIsNegative = finalValue < 0
      if (valueIsNegative) {
        setClientType((prevInfo) => ({ ...prevInfo, order: 0 }))
      } else {
        setClientType((prevInfo) => ({ ...prevInfo, order: finalValue }))
      }
      const isOrderMatch = await filteredRows.some(
        (obj) => Number(obj?.order) === Number(finalValue)
      )

      setError((prevError) => ({
        ...prevError,
        order: finalValue?.length === 0 ? true : isOrderMatch ? true : false,
        orderErrorText: isOrderMatch ? 'Order already Exists!' : null,
      }))
    }

    const switchChangeHandlerClosed = (event, params) => {
      const { value } = params

      setClientType((prevDetails) => ({
        ...prevDetails,
        closed: value,
      }))
    }

    return (
      <div className="add_employmentType">
        <div className="employmentTitle">Add Case Status</div>
        <TextBox
          label="Status Name"
          fieldName="name"
          value={clientType?.name}
          onchangeHandler={onchangeHandler}
          error={error?.name}
        />{' '}
        <TextBox
          label="Description"
          fieldName="description"
          value={clientType?.description}
          onchangeHandler={onchangeHandler}
          error={error?.description}
        />{' '}
        <div>
          <TextBox
            label="Order"
            fieldName="order"
            value={clientType?.order}
            onchangeHandler={textBoxchangeHandler}
            error={error?.order}
            type="number"
          />
          {error?.orderErrorText && error?.order && (
            <p style={{ color: 'red', fontSize: '10px' }}>
              {error?.orderErrorText}
            </p>
          )}
        </div>
        <div className="addStatus-cases-switch">
          <SwitchButton
            title="Closed :"
            switchLabel="Yes"
            switchValue={clientType?.closed}
            onChangeHandler={switchChangeHandlerClosed}
          />
        </div>
        <div className="employmentAction">
          <ButtonComponent
            className="withoutBackground"
            text="Close"
            onClick={handleClose}
          />
          <ButtonComponent
            className="withBackground"
            text="ADD"
            variant="contained"
            onClick={handleAdd}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="employmentContent">
      <div className="employmentHeader">Statuses</div>
      <div className="employmentContainer">
        <SearchEngine
          value=""
          searchClear={searchValue}
          originalRows={statusList}
          setRows={setFilteredRows}
        />
        {editMode && (
          <ButtonComponent
            startIcon={<img src={AddOutlinedIcon} />}
            text="Add"
            className="withBackground"
            onClick={() => {
              setPopup(true)
              setAccountType('add')
            }}
          />
        )}
      </div>

      <DataGrid
        rowData={filteredRows}
        columnData={columns}
        crudDetails={crudDetails}
        setCrudOperationStatus={setCrudOperationStatus}
        setRowModesData={setRowModesData}
        rowModesData={rowModesData}
        setRowData={setStatusList}
      />

      <div>
        <SidePopup
          className="employmentHistory_PopUp"
          placement={placement}
          dialogContent={historyData}
        />
      </div>
      {popup && (
        <Popup
          className="cases-addStatus"
          popContent={
            accountType === 'confirmUpdate' ? (
              <ConfirmationSavePopupContent />
            ) : accountType === 'cancelUpdate' ? (
              <ConfirmationPopupContent />
            ) : accountType === 'add' ? (
              <AddAccounts />
            ) : null
          }
        />
      )}

      <div>
        {toastOpen && toastShown.success && (
          <ToastMessage
            statusMassage={
              toastShown?.statusMassage ? toastShown?.statusMassage : 'SUCCESS!'
            }
            contentMessage={
              toastShown?.contentMessage
                ? toastShown?.contentMessage
                : 'Record Added successfully'
            }
          />
        )}
        {toastOpen && toastShown.fieldsRequired && (
          <ToastMessage
            statusMassage={
              toastShown?.statusMassage
                ? toastShown?.statusMassage
                : 'Process Declined'
            }
            contentMessage={
              toastShown?.contentMessage
                ? toastShown?.contentMessage
                : 'All Fields are Required'
            }
            unsuccessStatus
          />
        )}
        {toastOpen && toastShown.duplicateName && (
          <ToastMessage
            statusMassage={
              toastShown?.statusMassage
                ? toastShown?.statusMassage
                : 'Process Declined'
            }
            contentMessage={
              toastShown?.contentMessage
                ? toastShown?.contentMessage
                : 'This name already exists'
            }
            unsuccessStatus
          />
        )}
      </div>
    </div>
  )
}

export default Status
