import DashBoardActive from '../../../../assets/images/Menu/ActiveMenu/dashboard-Active-icon2.svg'
import AccMgmtActive from '../../../../assets/images/Menu/ActiveMenu/Accnt-mgmt-Active-icon2.svg'
import AgntMgmtActive from '../../../../assets/images/Menu/ActiveMenu/Agent-mgmt-Active-icon2.svg'
import CaseMgmtActive from '../../../../assets/images/Menu/ActiveMenu/case-mgmt-Active-icon2.svg'
import ConfigActive from '../../../../assets/images/Menu/ActiveMenu/Configuration-Active-icon2.svg'
import DataReturnActive from '../../../../assets/images/Menu/ActiveMenu/DataReturn-Active-icon2.svg'
import FinanceActive2 from '../../../../assets/images/Menu/ActiveMenu/Financeicon-Active-icon2.svg'
import LetteringActive from '../../../../assets/images/Menu/ActiveMenu/lettering-Active-icon2.svg'
import UserMgmtActive from '../../../../assets/images/Menu/ActiveMenu/user-mgmt-Active-icon2.svg'
import WorkFlowActive from '../../../../assets/images/Menu/ActiveMenu/workflow-Active-icon2.svg'
import AccMgmt from '../../../../assets/images/Menu/InActiveMenu/Accnt-mgmt-inActive-icon.svg'
import AgtMgmt from '../../../../assets/images/Menu/InActiveMenu/Agent-mgmt-inActive-icon.svg'
import CaseMgmt from '../../../../assets/images/Menu/InActiveMenu/case-mgmt-inActive-icon.svg'
import Config from '../../../../assets/images/Menu/InActiveMenu/Configuration-inActive-icon.svg'
import Dash from '../../../../assets/images/Menu/InActiveMenu/dashboard-inActive-icon.svg'
import DataReturn from '../../../../assets/images/Menu/InActiveMenu/DataReturn-inActive-icon.svg'
import Finance2 from '../../../../assets/images/Menu/InActiveMenu/Financeicon-inActive-icon.svg'
import Lettering from '../../../../assets/images/Menu/InActiveMenu/lettering-inActive-icon.svg'
import UserMgmt from '../../../../assets/images/Menu/InActiveMenu/user-mgmt-inActive-icon.svg'
import WorkFlow from '../../../../assets/images/Menu/InActiveMenu/workflow-inActive-icon.svg'
import AuditActive from '../../../../assets/images/Menu/ActiveMenu/AuditLogicon-Active-icon2.svg'
import AuditInActive from '../../../../assets/images/Menu/InActiveMenu/AuditLogicon-inActive-icon.svg'

const RootMenus = [
  {
    name: 'Dashboard',
    menuIndex: 0,
    menuItemId: 'dashBoard',
    img: DashBoardActive,
    imgInactive: Dash,
    url: '/',
    backGround: 'white',
  },
  {
    name: 'Configuration',
    img: ConfigActive,
    menuIndex: 1,
    menuItemId: 'configuration',
    imgInactive: Config,
    menus: [
      {
        name: 'General',
        url: '/general',
        menuItemId: 'general',
      },
      {
        name: 'Agents',
        url: '/agents',
        menuItemId: 'agents',
      },
      {
        name: 'Lettering',
        url: '/lettering',
        menuItemId: 'lettering',
      },
      {
        name: 'Accounts',
        url: '/accounts',
        menuItemId: 'accounts',
      },
      {
        name: 'Case Management',
        url: '/caseManagement',
        menuItemId: 'caseManagement',
      },
      {
        name: 'Workflow',
        url: '/configWorkflow',
        menuItemId: 'configWorkflow',
      },
      {
        name: 'Data Return Files',
        url: '/dataReturnfiles',
        menuItemId: 'dataReturnfiles',
      },
    ],
  },
  {
    name: 'Agent Management',
    menuIndex: 2,
    img: AgntMgmtActive,
    imgInactive: AgtMgmt,
    url: '/agentManagement',
    menuItemId: 'agentManagement',
    backGround: 'white',
  },
  {
    name: 'Account Management',
    img: AccMgmtActive,
    imgInactive: AccMgmt,
    menuIndex: 3,
    menuItemId: 'accountManagement',
    menus: [
      {
        name: 'Client',
        url: '/client',
        menuItemId: 'client',
      },
      {
        name: 'Vendors',
        url: '/vendors',
        menuItemId: 'vendors',
      },
    ],
  },
  {
    name: 'User Management',
    img: UserMgmtActive,
    imgInactive: UserMgmt,
    menuIndex: 4,
    menuItemId: 'userManagement',
    menus: [
      {
        name: 'User',
        url: '/users',
        menuItemId: 'users',
      },
      {
        name: 'Roles & Permission',
        url: '/roles',
        menuItemId: 'roles',
      },
    ],
  },
  {
    name: 'Lettering',
    img: LetteringActive,
    imgInactive: Lettering,
    menuIndex: 5,
    menuItemId: 'letteringMain',
    menus: [
      {
        name: 'Client Letter',
        url: '/clientLetter',
        menuItemId: 'clientLetter',
      },
      {
        name: 'Agent Letter Order',
        url: '/agentLetterOrder',
        menuItemId: 'agentLetterOrder',
      },
      {
        name: 'Agent Letter',
        url: '/agentLetter',
        menuItemId: 'agentLetter',
      },
      {
        name: 'Agent Usage',
        url: '/agentUsage',
        menuItemId: 'agentUsage',
      },
    ],
  },
  {
    name: 'Finance',
    img: FinanceActive2,
    imgInactive: Finance2,
    menuIndex: 6,
    menuItemId: 'finance',
    menus: [
      {
        name: 'Agent Payment',
        url: '/agentPayment',
        menuItemId: 'agentPayment',
      },
      {
        name: 'Client Invoice',
        url: '/clientInvoice',
        menuItemId: 'clientInvoice',
      },
      {
        name: 'Vendor',
        url: '/financeVendor',
        menuItemId: 'financeVendor',
      },
    ],
  },
  {
    name: 'Case Management',
    img: CaseMgmtActive,
    imgInactive: CaseMgmt,
    menuIndex: 7,
    menuItemId: 'caseManagementMain',
    menus: [
      {
        name: 'Cases',
        url: '/cases',
        menuItemId: 'cases',
      },
      {
        name: 'Communication',
        url: '/communication',
        menuItemId: 'communication',
      },
      {
        name: 'Court Booking',
        url: '/courtBooking',
        menuItemId: 'courtBooking',
      },

      {
        name: 'Planning',
        url: '/planning',
        menuItemId: 'planning',
      },
      {
        name: 'Appointment Scheduler',
        url: '/appointmentScheduler',
        menuItemId: 'appointmentScheduler',
      },
      {
        name: 'Quality Control',
        url: '/qualityControl',
        menuItemId: 'qualityControl',
      },
    ],
  },
  {
    name: 'Workflow',
    img: WorkFlowActive,
    imgInactive: WorkFlow,
    menuIndex: 8,
    menuItemId: 'workflowMain',
    menus: [
      {
        name: 'Workflow',
        url: '/workflow',
        menuItemId: 'workflow',
      },
      {
        name: 'Stages',
        url: '/stages',
        menuItemId: 'stages',
      },
    ],
  },
  {
    name: 'Audit Logs',
    img: AuditActive,
    imgInactive: AuditInActive,
    url: '/auditLog',
    menuIndex: 9,
    menuItemId: 'auditLog',
    backGround: 'white',
  },
  {
    name: 'Data Return',
    img: DataReturnActive,
    imgInactive: DataReturn,
    url: '/dataReturn',
    menuIndex: 10,
    menuItemId: 'dataReturn',
    backGround: 'white',
  },

  {
    name: 'Data Return Files',
    img: DataReturnActive,
    imgInactive: DataReturn,
    menuIndex: 12,
    menuItemId: 'dataReturnMain',
    menus: [
      {
        name: 'Template',
        url: '/templateReturn',
        menuItemId: 'templateReturn',
      },
      {
        name: 'Schedule',
        url: '/scheduleDataReturn',
        menuItemId: 'scheduleDataReturn',
      },
      {
        name: 'Return Files',
        url: '/returnFile',
        menuItemId: 'returnFile',
      },
    ],
  },
]

export default RootMenus
