import React from 'react'
import backgroundImage from '../../../../assets/images/loginPage_M-logo.png'
import error403 from '../../../../assets/images/Error/Error403.svg'
import './ErrorLayout.css'
import ButtonComponent from '../button/Button'
import { useRecoilState } from 'recoil'
import { getErrorCode, loginStatus } from '../../recoil/atoms/atoms'

const AccessDenied = () => {
  const [errorNull, setErrorNull] = useRecoilState(getErrorCode)
  const [loginStatusValue, setLoginStatusValue] = useRecoilState(loginStatus)
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
      }}
      className="errorContainer"
    >
      <div className="errorImg404">
        <img src={error403} alt="Error 403" />
        <div className="header_text">Access Denied</div>
        <div className="errorMessage">
          Please get in touch with your system administrator
        </div>
        <div className="errorButton-Container">
          <ButtonComponent
            className="withBackground"
            text="Retry"
            onClick={() => window.location.reload(false)}
          />
          <ButtonComponent
            className="errorPageButton"
            text="Back to Home"
            onClick={() => {
              localStorage.clear()
              sessionStorage.clear()
              window.location.href = '/'
              setErrorNull(null)
              setLoginStatusValue(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AccessDenied
