import * as React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import './Grid.css'
import { GridRowModes } from '@mui/x-data-grid'

const DataGrid = ({
  rowData,
  setRowData,
  columnData,
  crudDetails,
  setCrudOperationStatus,
  crudOperationStatus,
  setRowModesData,
  rowModesData,
  className,
  getDetailPanelContent,
  expendContentHeight,
  checkboxSelection,
  onRowClick,
  rowsperPageCount,
  currentlyUpdateRow,
  rowSelectionModel,
  onRowSelectionModelChange,
  disableRowSelectionOnClick,
  isRowSelectable,
}) => {
  const handleRowEditStop = (params, event) => {
    if (params.reason == 'rowFocusOut') {
      event.defaultMuiPrevented = true
      setRowModesData({
        ...rowModesData,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      })
    }
  }
  React.useEffect(() => {
    if (crudDetails) {
      const { id, operation, editFieldToFocus, rowDataToAdd } = crudDetails
      if (operation === 'Add') {
        // const id = Math.random()
        setRowData((oldRows) => [
          ...oldRows,
          {
            ...rowDataToAdd,
          },
        ])
        setRowModesData((oldModel) => ({
          ...oldModel,
          [rowDataToAdd?.id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: editFieldToFocus,
          },
        }))
      } else if (operation === 'Edit') {
        setCrudOperationStatus(false)
        setRowModesData({
          ...rowModesData,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: editFieldToFocus },
        })
      } else if (operation === 'Delete') {
        setRowData(rowData?.filter((row) => row.id !== id))
        setCrudOperationStatus(false)
      } else if (operation === 'Cancel') {
        const editedRow = rowData?.find((row) => row.id === id)
        if (editedRow?.isNew) {
          setRowData(rowData?.filter((row) => row.id !== id))
        }

        setRowModesData({
          ...rowModesData,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })
        setCrudOperationStatus(false)
      } else if (operation === 'Save') {
        setRowModesData({
          ...rowModesData,
          [id]: { mode: GridRowModes.View },
        })
        setCrudOperationStatus(false)
      } else {
        setCrudOperationStatus(false)
      }
    }
  }, [crudDetails, crudOperationStatus])

  const processRowUpdate = (newRow) => {
    currentlyUpdateRow && currentlyUpdateRow(newRow)
    const updatedRow = { ...newRow, isNew: false }
    setRowData(rowData?.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesData(newRowModesModel)
  }

  const handleCellEditCommit = ({ id, field, props }) => {
    const updatedRows = rowData?.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: props.value }
      }
      return row
    })

    setRowData(updatedRows)
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 2 : 2,
      bottom: params.isLastVisible ? 0 : 2,
    }
  }, [])
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
    React.useState([])
  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])
  function NoRowsOverlay() {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        No records found
      </div>
    )
  }
  const onRowClickFunc = (params) => {
    onRowClick && onRowClick(params)
  }
  // const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  // console.log('rowSelectionModel', rowSelectionModel)

  return (
    <div
      className={`gridContainer ${
        rowData?.length > 0 ? 'haveRecords' : ''
      } ${className}`}
      style={{ height: rowData?.length > 0 ? '100%' : '100px' }}
    >
      <DataGridPro
        onCellDoubleClick={(event, params) => {
          params.defaultMuiPrevented = true
        }}
        className={className}
        rows={rowData}
        columns={columnData}
        hideFooter={
          rowsperPageCount
            ? rowData?.length <= rowsperPageCount
              ? true
              : false
            : rowData?.length < 11
        }
        editMode="row"
        rowModesModel={rowModesData ? rowModesData : null}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onCellEditCommit={handleCellEditCommit}
        disableColumnResize={true}
        getRowSpacing={getRowSpacing}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => expendContentHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        checkboxSelection={checkboxSelection}
        rowHeight={40}
        columnHeaderHeight={40}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: rowsperPageCount ? rowsperPageCount : 10,
              page: 0,
            },
          },
        }}
        pagination
        onRowClick={onRowClickFunc}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        // isRowSelectable={(params) => console.log('params', params)}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        isRowSelectable={isRowSelectable}
      />
    </div>
  )
}
export default DataGrid
