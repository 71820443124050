import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { toastMessage } from '../../recoil/atoms/atoms'
import { useRecoilState } from 'recoil'
import CheckCircle from '../../../../assets/images/CheckCircleOutlined.svg'
import CloseIcon from '../../../../assets/images/CloseFilled.svg'
import './ToastMessage.css'
function ToastMessage({ statusMassage, contentMessage, unsuccessStatus }) {
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setToastOpen(false)
  }
  const ContentMessage = () => {
    return (
      <div className="toast-message-content">
        <div className="toast-message-content-header">
          <div className="toast-message-content-header-left">
            <div>{!unsuccessStatus && <img src={CheckCircle}></img>}</div>
            <div>{statusMassage}</div>
          </div>
          <div>
            <img
              onClick={() => setToastOpen(false)}
              src={CloseIcon}
              className="toastClosIcon"
            ></img>
          </div>
        </div>
        <div
          className={`toast-message-content-message ${
            unsuccessStatus && 'unsuccessfull'
          }`}
        >
          {contentMessage}
        </div>
      </div>
    )
  }
  return (
    <div className={`toast-message-main ${unsuccessStatus && 'unsuccessfull'}`}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        message={<ContentMessage />}
      />
    </div>
  )
}
export default ToastMessage
