import React, { useEffect, useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Badge from '@mui/material/Badge'
import '../header/Header.css'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { useLocation } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  loginStatus,
  tabsHeaderTitle,
  loader,
  aadObjectId,
  runNowSlide,
  configData,
} from '../../recoil/atoms/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useMsal } from '@azure/msal-react'
import logOutIcon from '../../../../assets/images/logout.svg'
import CloseIcon from '../../../../assets/images/clear-close.svg'
import Popover from '@mui/material/Popover'
import Notification from './Notification'
import NotifyDataDummy from './NotifyMessages'
import Slider from '../../base/slidingPane/SlidingPane'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'
import fetchSelector from '../../recoil/selectors/selectors'
import HeaderTab from '../../base/tabs/Tabs'
import DataGrid from '../../base/grid/Grid'
import dayjs from 'dayjs'

function HeaderFile() {
  let location = useLocation()
  const [heading, setHeading] = useState('Dashboard')
  const [loginStatusValue, setLoginStatusValue] = useRecoilState(loginStatus)
  const tabsHeader = useRecoilValue(tabsHeaderTitle)
  const { instance, accounts } = useMsal()
  const [anchorEl, setAnchorEl] = useState({ notify: null, logout: null })
  const [panStatus, setPanStatus] = useRecoilState(runNowSlide)
  const [userDetails, setUserDetails] = useState([])
  const getData = useRecoilValue(fetchSelector)
  const azureID = useRecoilValue(aadObjectId)
  const APIEndPoints = useAPIEndpoints()
  const [loading, setLoading] = useRecoilState(loader)
  const [msalConfigAtom, setMsalConfigAtom] = useRecoilState(configData)

  useEffect(() => {
    let title = location.pathname
    if (tabsHeader) {
      setHeading('Case Details')
    } else {
      switch (title) {
        case '/':
          setHeading('Dashboard')
          break
        case '/agents':
          setHeading('Agents')
          break
        case '/client':
        case '/addClients':
        case '/editClients':
          setHeading('Clients')
          break
        case '/users':
        case '/addUser':
        case '/editUser':
          setHeading('Users')
          break
        case '/roles':
        case '/editJobTitle':
        case '/addUserRole':
        case '/editUserRole':
          setHeading('Roles & Permission')
          break
        case '/agentManagement':
        case '/addAgent':
        case '/editAgent':
          setHeading('Agent Management')
          break
        case '/agentLetterOrder':
        case '/addAgentLetterOrder':
        case '/editAgentLetterOrder':
          setHeading('Agent Letter Order')
          break
        case '/accounts':
        case '/AddAccounts':
          setHeading('Accounts')
          break
        case '/configWorkflow':
          setHeading('Workflow')
          break
        case '/vendors':
        case '/addVendors':
        case '/editVendors':
          setHeading('Vendors')
          break
        case '/clientLetter':
        case '/addClientLetter':
        case '/editClientLetter':
        case '/viewClientLetter':
          setHeading('Client Letter')
          break
        case '/agentLetter':
        case '/addAgentLetter':
        case '/editAgentLetter':
        case '/viewAgentLetter':
          setHeading('Agent Letter')
          break
        case '/agentUsage':
        case '/viewSingleAgentDetails':
          setHeading('Agent Usage')
          break
        case '/general':
        case '/addRegions':
        case '/editRegion':
        case '/editEquipment':
          setHeading('General')
          break
        case '/lettering':
          setHeading('Lettering')
          break
        case '/caseManagement':
        case '/AddServices':
        case '/viewCourts':
        case '/addCourt':
        case '/editCourt':
          setHeading('Case Management')
          break
        case '/cases':
          setHeading('Cases')
          break
        case '/communication':
          setHeading('Communication')
          break
        case '/courtBooking':
          setHeading('Court Booking')
          break
        case '/workflow':
        case '/addworkflow':
        case '/editworkflow':
          setHeading('Workflow')
          break
        case '/appointmentScheduler':
          setHeading('Appointment Scheduler')
          break
        case '/editappointment':
          setHeading('Re-Schedule Appointment')
          break
        case '/addappointment':
          setHeading('Schedule Appointment')
          break
        case '/planning':
          setHeading('Planning')
          break
        case '/addplanning':
          setHeading('Create Plan')
          break
        case '/editplan':
          setHeading('Re-Plan')
          break
        case '/stages':
        case '/editStages':
        case '/addStages':
          setHeading('Stages')
          break
        case '/auditLog':
          setHeading('Audit Logs')
          break
        case '/qualityControl':
          setHeading('Quality Control')
          break
        case '/dataReturn':
        case '/addDataReport':
        case '/editReport':
        case '/duplicateDataReturn':
          setHeading('Data Return Files')
          break
        case '/agentPayment':
          setHeading('Agent Payment')
          break
        case '/clientInvoice':
          setHeading('Client Invoice')
          break
        case '/financeVendor':
          setHeading('Vendor')
          break
        case '/dataReturnfiles':
          setHeading('Data Return Files')
          break
        case '/templateReturn':
          setHeading('Templates')
          break
        case '/scheduleDataReturn':
        case '/addSchedule':
          setHeading('Schedule')
          break
        case '/returnFile':
          setHeading('Return Files')
          break
        default:
          setHeading('') // Or handle default case appropriately
          break
      }
    }
  }, [location.pathname, tabsHeader])

  const unreadItemsLength = NotifyDataDummy.filter(
    (item) => item.status === 'Unread'
  ).length

  const receiveNewNotification = (event) => {
    setAnchorEl((prev) => ({ ...prev, notify: event.currentTarget }))
  }

  const handleClose = () => {
    setAnchorEl((prev) => ({ ...prev, notify: null }))
  }
  const handleLogPopupClose = () => {
    setAnchorEl((prev) => ({ ...prev, logout: null }))
  }
  const logOutHandler = async () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: msalConfigAtom?.auth?.redirectUri,
    }
    setTimeout(() => {
      setLoginStatusValue(false)
      instance.logoutRedirect(logoutRequest)
    }, 1000)
    localStorage.clear()
    sessionStorage.clear()
  }

  const getPersonDetail = async () => {
    setLoading(true)
    const getUserDetailsID = await getData(
      APIEndPoints.GetUserDetails.method,
      ` ${APIEndPoints.GetUserDetails.url}?aadObjectID=${
        accounts[0]?.localAccountId
      }&active=${true}`
    )
    setUserDetails(getUserDetailsID?.data)
    setLoading(false)
  }
  const SliderComponent = () => {
    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
    const tabSelectionChangeHandler = (event, selectedIndex) => {
      setTabSelectedIndex(selectedIndex)
    }

    const DelegatedUsers = () => {
      const delegateRows =
        userDetails[0]?.delegatedUsers?.map((delegateDetails, index) => {
          return {
            id: delegateDetails?.id,
            userName: delegateDetails?.recipientName,
            jobTitle: delegateDetails?.jobTitleName,
            startDate: dayjs(delegateDetails?.startDate).format('DD-MM-YYYY'),
            endDate: dayjs(delegateDetails?.endDate).format('DD-MM-YYYY'),
            startDateCheck: delegateDetails?.startDate.split('T')[0],
            endDateCheck: delegateDetails?.endDate.split('T')[0],
          }
        }) || []

      const delegateColumns = [
        {
          field: 'userName',
          headerName: 'Name',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
          hideable: false,
        },
        {
          field: 'jobTitle',
          headerName: 'Job Title',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
        },
      ]
      return (
        <div>
          <DataGrid
            className="delegateGrid"
            rowData={delegateRows}
            columnData={delegateColumns}
            rowsperPageCount={4}
          />
        </div>
      )
    }
    const MyDelegatedUsers = () => {
      const myDelegateRows =
        userDetails[0]?.delegations?.map((delegateDetails, index) => {
          return {
            id: delegateDetails?.id,
            userName: delegateDetails?.sourceName,
            jobTitle: delegateDetails?.jobTitleName,
            startDate: dayjs(delegateDetails?.startDate).format('DD-MM-YYYY'),
            endDate: dayjs(delegateDetails?.endDate).format('DD-MM-YYYY'),
          }
        }) || []
      const myDelegateColumns = [
        {
          field: 'userName',
          headerName: 'Name',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
          hideable: false,
        },
        {
          field: 'jobTitle',
          headerName: 'Job Title',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          flex: 1,
          // minWidth: 150,
          headerClassName: 'mui-header',
          editable: false,
        },
        // {
        //   field: 'action',
        //   headerName: 'Action',
        //   minWidth: 100,
        //   headerClassName: 'mui-header',
        //   editable: false,
        // },
      ]
      return (
        <div>
          <DataGrid
            className="delegateGrid"
            rowData={myDelegateRows}
            columnData={myDelegateColumns}
            rowsperPageCount={4}
          />
        </div>
      )
    }
    let tabDataSource = [
      {
        tabTitle: 'Delegated Users',
        tabContent: <DelegatedUsers />,
      },
      {
        tabTitle: 'My Delegations',
        tabContent: <MyDelegatedUsers />,
      },
    ]

    return (
      <div className="myProfileContainer">
        <div className="myProfileHeader">
          <div className="myProfileClose" onClick={() => setPanStatus(false)}>
            <img
              src={CloseIcon}
              alt="closeIcon"
              style={{ cursor: 'pointer' }}
              className="myProfileSlideClose"
            />
            <div>Close</div>
          </div>
          <div className="userMyProfile-Header">My Profile</div>
        </div>
        {userDetails?.map((userValue) => (
          <div className="myProfileDetails">
            <div className="userProfileDetails">
              <div className="userFirstChar">{userValue?.name[0]}</div>
              <div className="userDetail-Container">
                <div className="userFirstName">{userValue?.name}</div>
                <div className="userEmailAddress">
                  {userValue?.emailAddress}
                </div>
                <div className="userRoles">{userValue?.jobTitleName}</div>
              </div>
            </div>
            <div
              className={`${
                userValue?.active === true
                  ? 'userActive Active'
                  : 'userActive InActive'
              }`}
            >
              {userValue?.active ? 'Active' : 'In-Active'}
            </div>
          </div>
        ))}
        <div className="userAuthority">
          <div className="authorityName">Authority Delegation</div>
          <div className="userAuthorityDetails">
            <HeaderTab
              dataSource={tabDataSource}
              selectedTabIndex={tabSelectedIndex}
              onChangeHandler={tabSelectionChangeHandler}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="header_main">
      <div className="header_text"> {heading}</div>
      <div className="notification_profile">
        <div className="bell_icon">
          <Badge badgeContent={unreadItemsLength} color="error">
            <NotificationsIcon
              className="badge"
              onClick={receiveNewNotification}
            />
          </Badge>
        </div>
        <div className="profile_main">
          <div
            onClick={(event) =>
              setAnchorEl((prev) => ({
                ...prev,
                logout: event.currentTarget,
              }))
            }
            className="profileContainer"
          >
            <div className="user_profile_icon">
              <PersonOutlineOutlinedIcon></PersonOutlineOutlinedIcon>{' '}
            </div>
            <div className="Profile_name">
              {accounts[0]?.name || instance?.getAllAccounts()[0]?.name}
            </div>{' '}
            <div className="logoutIcon-div">
              {/* onClick={() => setLoginStatusValue(false)} */}
              <KeyboardArrowDownIcon className="arrowIcon" />
              <Popover
                open={Boolean(anchorEl?.logout)}
                anchorEl={anchorEl?.logout}
                onClose={handleLogPopupClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className="logout-popup">
                  <div onClick={() => {}} className="my-profile-btn">
                    <div className="my-profile-icon">
                      <div className="user_profile_icon-popup">
                        <PersonOutlineOutlinedIcon></PersonOutlineOutlinedIcon>
                      </div>
                    </div>
                    <div
                      className="my-profile-text"
                      onClick={() => {
                        setPanStatus(true)
                        getPersonDetail()
                      }}
                    >
                      My Profile
                    </div>
                  </div>
                  <div onClick={logOutHandler} className="sign-out-btn">
                    <img className="logoutimg" src={logOutIcon}></img>
                    <div className="my-profile-text"> Sign Out</div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <Popover
            open={Boolean(anchorEl?.notify)}
            anchorEl={anchorEl?.notify}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Notification />
          </Popover>
        </div>
        {panStatus && (
          <Slider
            sliderName="runNow"
            className="myProfile-slider"
            SliderComponent={<SliderComponent />}
          />
        )}
      </div>
    </div>
  )
}

export default HeaderFile
