import { PublicClientApplication } from '@azure/msal-browser'
import React from 'react'
import { App } from '../../../app/App'
import { LicenseInfo } from '@mui/x-license-pro'
import { RecoilRoot } from 'recoil'

const SignInBridge = ({ msalConfig }) => {
  LicenseInfo.setLicenseKey(msalConfig?.auth?.muiGridKey)
  const msalInstance = new PublicClientApplication(msalConfig)

  return (
    <RecoilRoot>
      <App instance={msalInstance} msalConfig={msalConfig} />
    </RecoilRoot>
  )
}

export default SignInBridge
