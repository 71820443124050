import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import ButtonComponent from '../../../../components/base/button/Button'
import DataGrid from '../../../../components/base/grid/Grid'
import AddOutlinedIcon from '../../../../../assets/images/addIconPlus.svg'
import {
  loader,
  loggedUserDetails,
} from '../../../../components/recoil/atoms/atoms'
import '../../accounts/Accounts.css'
import Edit from '../../../../../assets/images/EditFilled.png'
import SearchEngine from '../../../../components/base/searchBox/SearchBar'
import fetchSelector from '../../../../components/recoil/selectors/selectors'
import useAPIEndpoints from '../../../../../models/api/apiEndpoints'

function Courts() {
  const APIEndPoints = useAPIEndpoints()
  const navigate = useNavigate()
  const getData = useRecoilValue(fetchSelector)
  const [statusList, setStatusList] = useState([])
  const [loading, setLoading] = useRecoilState(loader)
  const [rowData, setRowData] = useState([])
  const [filteredRows, setFilteredRows] = useState(statusList)
  const [crudOperationStatus, setCrudOperationStatus] = useState(false)
  const [rowModesData, setRowModesData] = useState({})
  const [updateRowData, setUpdateRowData] = useState(null)
  const [crudDetails, setCrudDetails] = useState({
    id: '',
    operation: '',
    editFieldToFocus: '',
  })
  const [docTypeAdded, setDocTypeAdded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [rowId, setRowId] = useState()
  const [editMode, setEditMode] = useState(false)
  const [userRolesValues, setUserRolesValues] = useState([])
  const [loggedUserData, setLoggedUserData] = useRecoilState(loggedUserDetails)
  useEffect(() => {
    if (loggedUserData) {
      const rolesArray = loggedUserData[0]?.roles?.map((role) => role?.aadValue)
      const userValues = rolesArray?.filter(
        (value) => value.includes('Config') || value.includes('Admin')
      )
      setUserRolesValues(userValues)
    }
  }, [loggedUserData])

  useEffect(() => {
    const rolesToCheck = [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ]
    const hasEditMode = userRolesValues.some((filterValues) =>
      rolesToCheck.includes(filterValues)
    )
    setEditMode(hasEditMode)
  }, [userRolesValues])
  useEffect(() => {
    setLoading(true)

    const getStatus = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetCourts.method,
          APIEndPoints.GetCourts.url
        )
        setLoading(false)
        setStatusList(
          response.data
            ?.sort((a, b) => a?.id - b?.id)
            ?.map((value) => ({
              id: value?.id,
              name: value?.name,
              status: value?.active ? 'Enable' : 'Disable',
            }))
        )
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }

    getStatus()
  }, [docTypeAdded])

  // UseEffect sets setRowData
  useEffect(() => {
    const rows = statusList?.map((clients, index) => {
      return {
        id: clients?.id,
        name: clients?.name,
        status: clients?.active,
      }
    })
    setRowData(rows)
  }, [statusList])

  const handleEditClick = (row) => () => {
    const { id, name } = row
    setRowId(id)
    setUpdateRowData(row)
    navigate('/editCourt', {
      state: {
        courtID: id,
      },
    })
    setSearchValue('')
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
      maxWidth: 150,
    },
    {
      field: 'name',
      headerName: 'Name',
      // editable: true,
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: ({ row }) => {
        return (
          <div
            className={`${
              row?.status === 'Enable'
                ? 'agent-statusCell Enable'
                : 'agent-statusCell Disable'
            }`}
          >
            <div className="statusText">
              {row?.status === 'Enable' ? 'Enable' : 'Disable'}
            </div>
          </div>
        )
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      sortable: false,
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      type: 'actions',
      renderCell: ({ row }) => {
        return (
          <div className="actionIcons-container">
            {editMode && (
              <img
                className="EditIcon"
                src={Edit}
                onClick={handleEditClick(row)}
              />
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className="employmentContent">
      <div className="employmentHeader">Courts</div>
      <div className="employmentContainer">
        <SearchEngine
          value=""
          searchClear={searchValue}
          originalRows={statusList}
          setRows={setFilteredRows}
        />
        {editMode && (
          <ButtonComponent
            startIcon={<img src={AddOutlinedIcon} />}
            text="Add"
            className="withBackground"
            onClick={() => {
              navigate('/addCourt')
            }}
          />
        )}
      </div>

      <DataGrid
        rowData={filteredRows}
        columnData={columns}
        crudDetails={crudDetails}
        setCrudOperationStatus={setCrudOperationStatus}
        setRowModesData={setRowModesData}
        rowModesData={rowModesData}
        setRowData={setStatusList}
        onRowClick={(params) => {
          navigate('/viewCourts', {
            state: {
              courtID: params?.row?.id,
              courtName: params?.row?.name,
            },
          })
        }}
      />
    </div>
  )
}

export default Courts
