import React from 'react'
import backgroundImage from '../../../../assets/images/loginPage_M-logo.png'
import error401 from '../../../../assets/images/Error/Error401.svg'
import './ErrorLayout.css'
import ButtonComponent from '../button/Button'
import { useRecoilState } from 'recoil'
import { getErrorCode } from '../../recoil/atoms/atoms'

const Unauthorized = () => {
  const [errorNull, setErrorNull] = useRecoilState(getErrorCode)
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
      }}
      className="errorContainer"
    >
      <div className="errorImg500">
        <img src={error401} alt="Error 401" />
        <div className="header_text">Unauthorized Access</div>
        <div className="errorMessage">This page is not publicly available.</div>
        <div className="errorButton-Container">
          <ButtonComponent
            className="errorPageButton"
            text="Back to Home"
            onClick={() => {
              window.location.href = '/'
              setErrorNull(null)
              localStorage.clear()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
