import React from 'react'
import Switch from '@mui/material/Switch'
import './SwitchButton.css'
const SwitchButton = ({
  switchName,
  switchValue,
  onChangeHandler,
  disabled,
  switchLabel,
  title,
}) => {
  function onChange(event) {
    if (onChangeHandler)
      onChangeHandler(event, {
        name: switchName,
        value: event.target.checked,
      })
  }
  return (
    <div className="switch_main">
      <div className="switch-title">{title}</div>
      <div className="switch">
        <Switch
          id={switchName}
          color="primary"
          checked={switchValue}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="switch-label">{switchLabel}</div>
      </div>
    </div>
  )
}

export default SwitchButton
