import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import Lettering from '../pages/configPage/lettering/Lettering'
import CaseManagement from '../pages/configPage/caseManagement/CaseManagement'
import RouteMiddleware from './RouteMiddleware'
import SpinnerComponent from '../components/base/spinner/Spinner'

const DashBoard = lazy(() => import('../pages/dashBoard/DashBoard'))
const Users = lazy(() => import('../pages/userManagement/user/Users'))
const Roles = lazy(() => import('../pages/userManagement/roles/Roles'))
const AddUser = lazy(() => import('../pages/userManagement/user/AddUser'))
const EditUser = lazy(() => import('../pages/userManagement/user/EditUser'))
const AgentManagement = lazy(() =>
  import('../pages/agentManagement/AgentManagement')
)
const AddAgent = lazy(() => import('../pages/agentManagement/AddAgent'))
const EditJobTitle = lazy(() =>
  import('../pages/userManagement/roles/EditJobTitle')
)
const Agents = lazy(() => import('../pages/configPage/agent/Agents'))
const Client = lazy(() =>
  import('../pages/accountManagement/clients/clientsLandingView/Clients')
)
const EditClient = lazy(() =>
  import('../pages/accountManagement/clients/editClients/EditClients')
)
const AddUserRole = lazy(() =>
  import('../pages/userManagement/roles/addUserRole/AddUserRole')
)
const EditUserRole = lazy(() =>
  import('../pages/userManagement/roles/editRole/EditRole')
)
const EditAgent = lazy(() => import('../pages/agentManagement/EditAgent'))
const AgentLetterOrder = lazy(() =>
  import('../pages/lettering/agentLetterOrder/AgentLetterOrder')
)
const AddClient = lazy(() =>
  import('../pages/accountManagement/clients/addClients/AddClients')
)

const Accounts = lazy(() => import('../pages/configPage/accounts/AccountsMain'))
const Vendors = lazy(() =>
  import('../pages/accountManagement/vendors/vendorsLandingView/Vendors')
)
const AddVendors = lazy(() =>
  import('../pages/accountManagement/vendors/addVendors/AddVendors')
)
const EditVendors = lazy(() =>
  import('../pages/accountManagement/vendors/editVendors/EditVendors')
)
const EditAgentLetterOrder = lazy(() =>
  import('../pages/lettering/agentLetterOrder/EditAgentLetterOrder')
)
const AddAgentLetterOrder = lazy(() =>
  import('../pages/lettering/agentLetterOrder/AddAgentLetterOrder')
)
// const EditClient = lazy(() =>
//   import('../pages/accountManagement/client/EditClient')
// )
const AgentLetter = lazy(() =>
  import('../pages/lettering/agentLetter/AgentLetter')
)
const ClientLetter = lazy(() =>
  import('../pages/lettering/clientLetter/ClientLetter')
)
const AgentUsage = lazy(() =>
  import('../pages/lettering/agentUsage/AgentUsage')
)
const AddAgentLetter = lazy(() =>
  import('../pages/lettering/agentLetter/AddAgentLetter')
)
const AddClientLetter = lazy(() =>
  import('../pages/lettering/clientLetter/AddClientLetter')
)
const GetAgentDetails = lazy(() =>
  import('../pages/lettering/agentUsage/AgentDetails')
)
const GeneralConfig = lazy(() => import('../pages/configPage/general/General'))
const AddRegions = lazy(() =>
  import('../pages/configPage/general/regions/addRegions/AddRegions')
)
const EditAgentLetter = lazy(() =>
  import('../pages/lettering/agentLetter/EditAgentLetter')
)
const EditClientLetter = lazy(() =>
  import('../pages/lettering/clientLetter/EditClientLetter')
)
const ViewAgentLetter = lazy(() =>
  import('../pages/lettering/agentLetter/ViewAgentLetter')
)
const ViewClientLetter = lazy(() =>
  import('../pages/lettering/clientLetter/ViewClientLetter')
)
const EditRegion = lazy(() =>
  import('../pages/configPage/general/regions/addRegions/EditRegion')
)
const EditEquipment = lazy(() =>
  import('../pages/configPage/general/equipments/EditEquipment')
)
const AddCourts = lazy(() =>
  import('../pages/configPage/caseManagement/courts/AddCourt')
)
const ViewCourts = lazy(() =>
  import('../pages/configPage/caseManagement/courts/ViewCourts')
)
const Cases = lazy(() => import('../pages/caseManagement/cases/Cases'))
const Communication = lazy(() =>
  import('../pages/caseManagement/communication/Communication')
)
const CourtBooking = lazy(() =>
  import('../pages/caseManagement/courtBooking/CourtBooking')
)
const CaseDetails = lazy(() =>
  import('../pages/caseManagement/cases/CaseDetails')
)
const WorkflowLanding = lazy(() =>
  import('../pages/workflow/workflow/workflowLanding/WorkflowLanding')
)
const AddWorkflow = lazy(() =>
  import('../pages/workflow/workflow/addWorkflow/AddWorkflow')
)
const EditWorkflow = lazy(() =>
  import('../pages/workflow/workflow/editWorkflow/EditWorkflow')
)
const Process = lazy(() => import('../pages/workflow/process/Process'))

const AppointmentScheduler = lazy(() =>
  import(
    '../pages/caseManagement/appointmentScheduler/AppointmentSchedulerLanding/AppointmentScheduler'
  )
)
const AddAppointment = lazy(() =>
  import(
    '../pages/caseManagement/appointmentScheduler/addAppointment/AddAppointment'
  )
)
const EditAppointment = lazy(() =>
  import(
    '../pages/caseManagement/appointmentScheduler/appointmentSchedulerEdit/AppointmentSchedulerEdit'
  )
)
const Planning = lazy(() =>
  import('../pages/caseManagement/planning/landingView/Planning')
)
const CreatePlan = lazy(() =>
  import('../pages/caseManagement/planning/addPlan/CreatePlan')
)
const AgentPayment = lazy(() => import('../pages/finance/AgentPayment'))
const ClientInvoice = lazy(() => import('../pages/finance/ClientInvoice'))
const FinanceVendor = lazy(() => import('../pages/finance/Vendor'))
const EditPlan = lazy(() =>
  import('../pages/caseManagement/planning/editPlan/EditPlan')
)
const QualityDetail = lazy(() =>
  import('../pages/caseManagement/qualityControl/QualityDetail')
)
const QualityControl = lazy(() =>
  import('../pages/caseManagement/qualityControl/QualityControl')
)
const AuditLog = lazy(() => import('../pages/auditLog/AuditLog'))
const ErrorBoundaryBase = lazy(() =>
  import('../components/base/errorPage/erroBoundary/ErrorBoundaryBase')
)
const ConfigWorkflow = lazy(() =>
  import('../pages/configPage/workflow/Workflow')
)
const EditCourt = lazy(() =>
  import('../pages/configPage/caseManagement/courts/EditCourt')
)
const ConfigDataReturnFiles = lazy(() =>
  import('../pages/configPage/dataReturnFiles/DataReturnfiles')
)
const EditStages = lazy(() =>
  import('../pages/workflow/process/editStage/EditStages')
)
const AddStages = lazy(() =>
  import('../pages/workflow/process/addStage/AddStages')
)
const TemplateDataReturn = lazy(() =>
  import('../pages/dataReturn/dataReturn/Template')
)
const Schedule = lazy(() => import('../pages/dataReturn/dataReturn/Schedule'))
const AddSchedule = lazy(() =>
  import('../pages/dataReturn/dataReturn/AddSchedule')
)
const ReturnFile = lazy(() =>
  import('../pages/dataReturn/dataReturn/ReturnFile')
)

const CheckRender = (component) => {
  return <RouteMiddleware>{component}</RouteMiddleware>
}

const RoutesFile = () => {
  return (
    <React.Suspense fallback={<SpinnerComponent />}>
      <Routes>
        <Route path="/" element={CheckRender(<DashBoard />)} />
        <Route path="/users" element={CheckRender(<Users />)} />
        <Route path="/roles" element={CheckRender(<Roles />)} />
        <Route path="/addUser" element={CheckRender(<AddUser />)} />
        <Route path="/editUser" element={CheckRender(<EditUser />)} />
        <Route
          path="/agentManagement"
          element={CheckRender(<AgentManagement />)}
        />
        <Route path="/addAgent" element={CheckRender(<AddAgent />)} />
        <Route path="/editJobTitle" element={CheckRender(<EditJobTitle />)} />
        <Route path="/agents" element={CheckRender(<Agents />)} />
        <Route path="/editUserRole" element={CheckRender(<EditUserRole />)} />
        <Route path="/addUserRole" element={CheckRender(<AddUserRole />)} />
        <Route path="/editAgent" element={CheckRender(<EditAgent />)} />
        <Route
          path="/agentLetterOrder"
          element={CheckRender(<AgentLetterOrder />)}
        />
        <Route path="/client" element={CheckRender(<Client />)} />
        <Route path="/editClients" element={CheckRender(<EditClient />)} />
        <Route path="/addClients" element={CheckRender(<AddClient />)} />
        <Route path="/accounts" element={CheckRender(<Accounts />)} />
        <Route path="/vendors" element={CheckRender(<Vendors />)} />
        <Route path="/addVendors" element={CheckRender(<AddVendors />)} />
        <Route path="/editVendors" element={CheckRender(<EditVendors />)} />
        <Route
          path="/addAgentLetterOrder"
          element={CheckRender(<AddAgentLetterOrder />)}
        />
        <Route path="/editClient" element={CheckRender(<EditClient />)} />
        <Route
          path="/editAgentLetterOrder"
          element={CheckRender(<EditAgentLetterOrder />)}
        />
        <Route path="/agentLetter" element={CheckRender(<AgentLetter />)} />
        <Route path="/clientLetter" element={CheckRender(<ClientLetter />)} />
        <Route path="/agentUsage" element={CheckRender(<AgentUsage />)} />
        <Route
          path="/addAgentLetter"
          element={CheckRender(<AddAgentLetter />)}
        />
        <Route
          path="/addClientLetter"
          element={CheckRender(<AddClientLetter />)}
        />
        <Route
          path="/viewSingleAgentDetails"
          element={CheckRender(<GetAgentDetails />)}
        />
        <Route path="/general" element={CheckRender(<GeneralConfig />)} />
        <Route path="/addRegions" element={CheckRender(<AddRegions />)} />
        <Route
          path="/editAgentLetter"
          element={CheckRender(<EditAgentLetter />)}
        />
        <Route
          path="/editClientLetter"
          element={CheckRender(<EditClientLetter />)}
        />
        <Route
          path="/viewAgentLetter"
          element={CheckRender(<ViewAgentLetter />)}
        />
        <Route
          path="/viewClientLetter"
          element={CheckRender(<ViewClientLetter />)}
        />
        <Route path="/lettering" element={CheckRender(<Lettering />)} />
        <Route
          path="/caseManagement"
          element={CheckRender(<CaseManagement />)}
        />
        <Route path="/editRegion" element={CheckRender(<EditRegion />)} />
        <Route path="/editEquipment" element={CheckRender(<EditEquipment />)} />
        <Route path="/workflow" element={CheckRender(<WorkflowLanding />)} />
        <Route path="/addworkflow" element={CheckRender(<AddWorkflow />)} />
        <Route path="/editworkflow" element={CheckRender(<EditWorkflow />)} />
        <Route path="/addCourt" element={CheckRender(<AddCourts />)} />
        <Route path="/viewCourts" element={CheckRender(<ViewCourts />)} />
        <Route path="/cases" element={CheckRender(<Cases />)} />
        <Route path="/communication" element={CheckRender(<Communication />)} />
        <Route path="/courtBooking" element={CheckRender(<CourtBooking />)} />
        <Route path="/caseDetails" element={CheckRender(<CaseDetails />)} />
        <Route path="/workflow" element={CheckRender(<WorkflowLanding />)} />
        <Route path="/addworkflow" element={CheckRender(<AddWorkflow />)} />
        <Route path="/editworkflow" element={CheckRender(<EditWorkflow />)} />
        <Route path="/process" element={CheckRender(<Process />)} />
        <Route
          path="/appointmentScheduler"
          element={CheckRender(<AppointmentScheduler />)}
        />
        <Route
          path="/addappointment"
          element={CheckRender(<AddAppointment />)}
        />
        <Route
          path="/editappointment"
          element={CheckRender(<EditAppointment />)}
        />
        <Route path="/planning" element={CheckRender(<Planning />)} />
        <Route path="/addplanning" element={CheckRender(<CreatePlan />)} />
        <Route path="/agentpayment" element={CheckRender(<AgentPayment />)} />
        <Route path="/clientinvoice" element={CheckRender(<ClientInvoice />)} />
        <Route path="/financevendor" element={CheckRender(<FinanceVendor />)} />
        <Route path="/editplan" element={CheckRender(<EditPlan />)} />
        <Route
          path="/qualityControl"
          element={CheckRender(<QualityControl />)}
        />
        <Route path="/QualityDetail" element={CheckRender(<QualityDetail />)} />
        <Route path="/auditLog" element={CheckRender(<AuditLog />)} />
        <Route
          path="/configWorkflow"
          element={CheckRender(<ConfigWorkflow />)}
        />
        <Route path="/editCourt" element={CheckRender(<EditCourt />)} />
        <Route
          path="/dataReturnfiles"
          element={CheckRender(<ConfigDataReturnFiles />)}
        />
        <Route path="/editStages" element={CheckRender(<EditStages />)} />
        <Route path="/addStages" element={CheckRender(<AddStages />)} />
        <Route path="/stages" element={CheckRender(<Process />)} />
        <Route
          path="/templateReturn"
          element={CheckRender(<TemplateDataReturn />)}
        />
        <Route path="/scheduleDataReturn" element={CheckRender(<Schedule />)} />
        <Route path="/addSchedule" element={CheckRender(<AddSchedule />)} />
        <Route path="/returnFile" element={CheckRender(<ReturnFile />)} />
        <Route
          path="*"
          element={CheckRender(<ErrorBoundaryBase errorCode={404} />)}
        />
      </Routes>
    </React.Suspense>
  )
}

export default RoutesFile
