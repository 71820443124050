// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 450px;
  height: auto;
  background-color: white;
  box-shadow: var(--box-shadow);

  outline: none;
  display: flex;
  padding: 24px;
  border-radius: 10px;
}

.popup-container.confirmation_popup {
  width: 300px;
}
.popup_close_save_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.confirmation_popup_main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.confirmation_question {
  margin-bottom: 10px;
}
/* .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
} */
`, "",{"version":3,"sources":["webpack://./src/views/components/base/popup/Popup.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,oBAAoB;EACpB,gCAAgC;EAChC,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,6BAA6B;;EAE7B,aAAa;EACb,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,SAAS;AACX;AACA;EACE,mBAAmB;AACrB;AACA;;GAEG","sourcesContent":[".popup-container {\n  position: absolute !important;\n  top: 50% !important;\n  left: 50% !important;\n  transform: translate(-50%, -50%);\n  width: 450px;\n  height: auto;\n  background-color: white;\n  box-shadow: var(--box-shadow);\n\n  outline: none;\n  display: flex;\n  padding: 24px;\n  border-radius: 10px;\n}\n\n.popup-container.confirmation_popup {\n  width: 300px;\n}\n.popup_close_save_button {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 15px;\n}\n.confirmation_popup_main {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 10px;\n}\n.confirmation_question {\n  margin-bottom: 10px;\n}\n/* .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {\n  background-color: transparent !important;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
