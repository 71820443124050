// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slidingPane-slider .slide-pane__header {
  display: none;
}
.slidingPane-slider.SliderChildComponent {
  height: 500px;
  width: 76% !important;
}
.slide-pane__content {
  padding: 0 !important;
}
.slide-pane.slide-pane_from_right.slidingPane-slider.content-after-open {
  width: 70% !important;
}
.slide-pane.slide-pane_from_right.slidingPane-slider.communication.content-after-open {
  width: 25% !important;
  padding: 0;
}
.slider-component {
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/slidingPane/SlidingPane.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;EACrB,UAAU;AACZ;AACA;EACE,aAAa;AACf","sourcesContent":[".slidingPane-slider .slide-pane__header {\n  display: none;\n}\n.slidingPane-slider.SliderChildComponent {\n  height: 500px;\n  width: 76% !important;\n}\n.slide-pane__content {\n  padding: 0 !important;\n}\n.slide-pane.slide-pane_from_right.slidingPane-slider.content-after-open {\n  width: 70% !important;\n}\n.slide-pane.slide-pane_from_right.slidingPane-slider.communication.content-after-open {\n  width: 25% !important;\n  padding: 0;\n}\n.slider-component {\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
