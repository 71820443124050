import React from 'react'
import ButtonComponent from '../../base/button/Button'
import './ConfirmationPopupContent.css'

export const ConfirmationPopupContent = ({
  title,
  message,
  buttonYesText,
  buttonNoText,
  allowClose,
  customDescribedBy,
}) => {
  return {
    dialogProps: {
      'aria-describedby': title
        ? ''
        : customDescribedBy
        ? customDescribedBy
        : `noTitle_CenterAligned`,
      className: 'unsaved-popup',
    },
    title: title ? <div className="confirmPopupTitle">{title}</div> : null,
    description: (
      <div className="confirmation_popup_main">
        <div className="popup_header">Confirm</div>
        <div className="confirmation_question">
          {message || 'Your work is unsaved. Are you sure you want to cancel?'}
        </div>
      </div>
    ),
    confirmationText: (
      <ButtonComponent
        text="CONFIRM"
        className="withBackground"
        onClick={() => {}}
      />
    ),
    cancellationText: (
      <ButtonComponent
        text="CANCEL"
        className="withoutBackground"
        onClick={() => {}}
      />
    ),
    allowClose: allowClose || false,
  }
}
