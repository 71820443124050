/* This file is used to configure Authentication configuration detail like - clientId, authority, redirectURL, cache and scope. This will be used in MsalAuthenticationTemplate
 */
export const msalConfig = {
  auth: {
    clientId: '',
    // signInPolicy: '',
    AuthScope: '',
    authority: '',
    redirectUri: '',
    validateAuthority: false,
    knownAuthorities: [''],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where our cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if we are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 60000,
  },
}
