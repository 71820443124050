import React from 'react'
import Error400 from '../Error400'
import Error404 from '../Error404'
import Error500 from '../Error500'
import Error503 from '../Error503'
import Error504 from '../Error504'
import SomethingWentWrong from '../Something'
import Error401 from '../Error401'
import Error403 from '../Error403'

const ErrorBoundary = ({ errorCode }) => {
  const errorComponents = {
    400: Error400,
    401: Error401,
    403: Error403,
    404: Error404,
    500: Error500,
    503: Error503,
    504: Error504,
  }

  const ErrorComponent = errorComponents[errorCode] || SomethingWentWrong

  return ErrorComponent ? <ErrorComponent /> : null
}

export default ErrorBoundary
