// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation_popup_main {
  font-size: 12px;
  font-family: 'Roboto';
  color: black;
}
.unsaved-popup .MuiPaper-root {
  width: 300px;
  border-radius: 10px;
}
.unsaved-popup .MuiDialogContent-root {
  padding: 24px 24px 0px;
  font-size: var(--default-font-size);
}
.unsaved-popup .confirmation_question {
  margin-bottom: 0px;
  line-height: normal;
}
.unsaved-popup .MuiDialogActions-root {
  padding: 8px 8px 14px;
  gap: 15px;
}
.unsaved-popup .buttonContainer {
  padding: 0;
}
.button_type_withoutBackground:hover {
  background-color: #1565c00a;
  box-shadow: none;
}
.unsaved-popup .MuiButtonBase-root {
  padding: 0;
  margin-left: 0px;
}
.buttonContainer .button_type_withBackground {
  padding: var(--button-padding);
}
.buttonContainer .button_type_withoutBackground {
  padding: var(--button-padding);
}
`, "",{"version":3,"sources":["webpack://./src/views/components/custom/confirmationPopup/ConfirmationPopupContent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,mCAAmC;AACrC;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,SAAS;AACX;AACA;EACE,UAAU;AACZ;AACA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;AAChC","sourcesContent":[".confirmation_popup_main {\n  font-size: 12px;\n  font-family: 'Roboto';\n  color: black;\n}\n.unsaved-popup .MuiPaper-root {\n  width: 300px;\n  border-radius: 10px;\n}\n.unsaved-popup .MuiDialogContent-root {\n  padding: 24px 24px 0px;\n  font-size: var(--default-font-size);\n}\n.unsaved-popup .confirmation_question {\n  margin-bottom: 0px;\n  line-height: normal;\n}\n.unsaved-popup .MuiDialogActions-root {\n  padding: 8px 8px 14px;\n  gap: 15px;\n}\n.unsaved-popup .buttonContainer {\n  padding: 0;\n}\n.button_type_withoutBackground:hover {\n  background-color: #1565c00a;\n  box-shadow: none;\n}\n.unsaved-popup .MuiButtonBase-root {\n  padding: 0;\n  margin-left: 0px;\n}\n.buttonContainer .button_type_withBackground {\n  padding: var(--button-padding);\n}\n.buttonContainer .button_type_withoutBackground {\n  padding: var(--button-padding);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
