// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generalConfig-TabContainer .Tab_main {
  display: grid;
  grid-template-columns: 15% 85%;
}
/* .generalConfig-TabContainer .TabContent-Container {
  background-color: white;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px 24px;
} */
.generalConfig-TabContainer .users-statusCell.Enable,
.generalConfig-TabContainer .users-statusCell.Disable {
  border-radius: 15px;
  padding: 2px 8px;
  letter-spacing: 0.16px;
  font-size: var(--default-font-size);
}
.generalConfig-TabContainer .users-statusCell.Enable {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(31, 60, 136, 1);
}
.generalConfig-TabContainer .users-statusCell.Disable {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.08);
}
.lettering-grid-container .Tab_main {
  display: grid;
  grid-template-columns: 15% 85%;
}
.RegionsParent-Container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: rgba(0, 0, 0, 0.87);
  font-size: var(--sub-title-FontSize);
  letter-spacing: 0.15px;
  font-weight: 600;
  line-height: 32px;
  padding: 10px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/views/pages/configPage/general/General.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;;;;;;GAMG;AACH;;EAEE,mBAAmB;EACnB,gBAAgB;EAChB,sBAAsB;EACtB,mCAAmC;AACrC;AACA;EACE,6BAA6B;EAC7B,sCAAsC;AACxC;AACA;EACE,yBAAyB;EACzB,qCAAqC;AACvC;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,uBAAuB;EACvB,2CAA2C;EAC3C,0BAA0B;EAC1B,oCAAoC;EACpC,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".generalConfig-TabContainer .Tab_main {\n  display: grid;\n  grid-template-columns: 15% 85%;\n}\n/* .generalConfig-TabContainer .TabContent-Container {\n  background-color: white;\n  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12),\n    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.2);\n  border-radius: 5px;\n  padding: 10px 24px;\n} */\n.generalConfig-TabContainer .users-statusCell.Enable,\n.generalConfig-TabContainer .users-statusCell.Disable {\n  border-radius: 15px;\n  padding: 2px 8px;\n  letter-spacing: 0.16px;\n  font-size: var(--default-font-size);\n}\n.generalConfig-TabContainer .users-statusCell.Enable {\n  color: rgba(255, 255, 255, 1);\n  background-color: rgba(31, 60, 136, 1);\n}\n.generalConfig-TabContainer .users-statusCell.Disable {\n  color: rgba(0, 0, 0, 0.6);\n  background-color: rgba(0, 0, 0, 0.08);\n}\n.lettering-grid-container .Tab_main {\n  display: grid;\n  grid-template-columns: 15% 85%;\n}\n.RegionsParent-Container {\n  background-color: white;\n  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n  color: rgba(0, 0, 0, 0.87);\n  font-size: var(--sub-title-FontSize);\n  letter-spacing: 0.15px;\n  font-weight: 600;\n  line-height: 32px;\n  padding: 10px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
