// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
  display: flex;
  align-items: center;
}
.basic-switch-main {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
}
.Mui-checked {
  color: #1f3c88 !important;
}
.Mui-checked + .MuiSwitch-track {
  background-color: #1f3c88 !important;
}
.switch-title {
  font-size: var(--default-font-size);
}
.switch-label {
  font-size: var(--default-font-size);
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/switch/SwitchButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,oCAAoC;AACtC;AACA;EACE,mCAAmC;AACrC;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".switch {\n  display: flex;\n  align-items: center;\n}\n.basic-switch-main {\n  display: flex;\n  gap: 50px;\n  flex-wrap: wrap;\n}\n.Mui-checked {\n  color: #1f3c88 !important;\n}\n.Mui-checked + .MuiSwitch-track {\n  background-color: #1f3c88 !important;\n}\n.switch-title {\n  font-size: var(--default-font-size);\n}\n.switch-label {\n  font-size: var(--default-font-size);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
