import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import SidePopup from '../../../components/base/popper/SidePopup'
import Popup from '../../../components/base/popup/Popup'
import ButtonComponent from '../../../components/base/button/Button'
import TextBox from '../../../components/base/textBox/TextField'
import MuiGrid from '../../../components/base/grid/Grid'
import {
  aadObjectId,
  dialogPopUp,
  loader,
  loggedUserDetails,
  toastMessage,
} from '../../../components/recoil/atoms/atoms'
import editIcon from '../../../../assets/images/EditFilled.png'
import SaveIcon from '../../../../assets/images/Save.png'
import Cancel from '../../../../assets/images/CancelIcon_Grid.png'
import '../agent/agentPages/Employment.css'
import fetchSelector from '../../../components/recoil/selectors/selectors'
import AddOutlinedIcon from '../../../../assets/images/addIconPlus.svg'
import SearchEngine from '../../../components/base/searchBox/SearchBar'
import SwitchButton from '../../../components/base/switch/SwitchButton'
import ToastMessage from '../../../components/base/toastMessage/ToastMessage'
import useAPIEndpoints from '../../../../models/api/apiEndpoints'

function JobType() {
  const APIEndPoints = useAPIEndpoints()
  const [anchorEl, setAnchorEl] = useState(null)
  const [popup, setPopup] = useRecoilState(dialogPopUp)
  const getData = useRecoilValue(fetchSelector)
  const [employmentList, setEmploymentList] = useState([])
  const [loading, setLoading] = useRecoilState(loader)
  const [historyData, setHistoryData] = useState(null)
  const [rowData, setRowData] = useState([])
  const [filteredRows, setFilteredRows] = useState(employmentList)
  const [checked, setChecked] = useState({})
  const [updateRowData, setUpdateRowData] = useState(null)
  const [popId, setPopId] = useState()
  const [rowId, setRowId] = useState()
  const [toastShown, setToastShown] = useState({
    fieldsRequired: false,
    success: false,
    duplicateName: false,
  })
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const azureID = useRecoilValue(aadObjectId)
  const [docTypeAdded, setDocTypeAdded] = useState(false)
  const [finalCheck, setFinalCheck] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [editingRowId, setEditingRowId] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [userRolesValues, setUserRolesValues] = useState([])
  const [loggedUserData, setLoggedUserData] = useRecoilState(loggedUserDetails)
  useEffect(() => {
    if (loggedUserData) {
      const rolesArray = loggedUserData[0]?.roles?.map((role) => role?.aadValue)
      const userValues = rolesArray?.filter(
        (value) => value.includes('Config') || value.includes('Admin')
      )
      setUserRolesValues(userValues)
    }
  }, [loggedUserData])

  useEffect(() => {
    const rolesToCheck = [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ]
    const hasEditMode = userRolesValues.some((filterValues) =>
      rolesToCheck.includes(filterValues)
    )
    setEditMode(hasEditMode)
  }, [userRolesValues])
  useEffect(() => {
    setLoading(true)

    const getEmploymentTypes = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetJobTypes.method,
          APIEndPoints.GetJobTypes.url
        )
        setLoading(false)
        setEmploymentList(
          response?.data
            ?.sort((a, b) => a?.name?.trim().localeCompare(b?.name?.trim()))
            ?.map((value) => ({
              id: value?.id,
              name: value?.name,
              status: value?.enabled ? 'Enable' : 'Disable',
            }))
        )

        // Set the switch values based on API response status
        const switchValues = {}
        response.data?.forEach((value) => {
          switchValues[`switchValue${value?.id}`] = value?.enabled
        })

        setChecked(switchValues)
        setFinalCheck(switchValues)
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }

    getEmploymentTypes()
  }, [docTypeAdded])

  useEffect(() => {
    const rows = employmentList?.map((employmentList, index) => {
      return {
        id: employmentList?.id,
        name: employmentList?.name,
        status: employmentList?.status,
      }
    })

    setRowData(rows)
  }, [employmentList])

  const [crudDetails, setCrudDetails] = useState({
    id: '',
    operation: '',
    editFieldToFocus: '',
  })
  const [crudOperationStatus, setCrudOperationStatus] = useState(false)
  const [rowModesData, setRowModesData] = useState({})
  const [editRowID, setEditRowID] = useState(null)

  const [placement, setPlacement] = React.useState()

  const handleEditClick = (row) => () => {
    const { id } = row
    setCrudOperationStatus(true)
    setCrudDetails({
      id,
      operation: 'Edit',
      editFieldToFocus: 'name',
    })

    setEditingRowId((prevId) => {
      if (prevId !== null && prevId !== id) {
        setRowModesData((prevModes) => ({
          ...prevModes,
          [prevId]: { mode: 'view' },
        }))
        setChecked(finalCheck)
      }
      return id
    })

    setRowId(id)
    setUpdateRowData(row)
    setEditingRowId(id)
    setSearchValue('')
  }
  const ConfirmationSavePopupContent = () => {
    const id = rowId

    const handleSave = async () => {
      try {
        const updatedRow = {
          ...updateRowData,
          status:
            updateRowData?.status === true
              ? true
              : updateRowData?.status === false
              ? false
              : updateRowData?.status === 'Enable'
              ? true
              : updateRowData?.status === 'Disable'
              ? false
              : '',
        }

        await getData(
          APIEndPoints?.EditJobType?.method,
          APIEndPoints?.EditJobType?.url,
          {
            azureUserObjectID: azureID,
            enabled: updatedRow?.status,
            jobTypeID: updatedRow?.id,
          }
        )

        // Update the employmentList and set the necessary states
        setEmploymentList((prevList) =>
          prevList.map((item) =>
            item.id === updatedRow.id ? updatedRow : item
          )
        )
        setDocTypeAdded(!docTypeAdded)
        setLoading(false)
        setPopup(false)
        setSearchValue('ClearSearch')
        setCrudOperationStatus(true)
        setCrudDetails({
          id,
          operation: 'Save',
          crudOperationStatus: !crudOperationStatus,
        })
      } catch (error) {
        setLoading(false)
        console.error('Error updating employment type:', error)
      }
    }
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Confirm</div>
        <div>Are you sure to update the status</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => setPopup(false)}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={handleSave}
          />
        </div>
      </div>
    )
  }

  const handleSaveClick = (id) => () => {
    setPopup(true)
    setPopId('Save')
    setRowId(id)
  }
  useEffect(() => {
    // This function runs whenever rowData or editRowID changes
    let foundRow = null
    if (rowData && editRowID) {
      foundRow = rowData.find((value) => value?.id === editRowID)
    }
    if (foundRow) {
      const updatedRow = {
        ...foundRow,
        status: foundRow?.status === true ? true : false,
      }
      setUpdateRowData(updatedRow)
    }
  }, [rowData, editRowID])

  const ConfirmationPopupContent = () => {
    const id = rowId
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Confirm</div>
        <div>Are you sure to update the status</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => setPopup(false)}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={() => {
              setPopup(false)
              setCrudOperationStatus(true)
              setCrudDetails({
                id,
                operation: 'Cancel',
                crudOperationStatus: !crudOperationStatus,
              })
              setChecked(finalCheck)
            }}
          />
        </div>
      </div>
    )
  }

  const handleCancelClick = (id) => () => {
    setPopup(true)
    setRowId(id)
    setPopId('Cancel')
  }

  const SwitchHandler = (e, params, rowId) => {
    const { name, value } = params

    // Update the checked state
    setChecked((prev) => ({
      ...prev,
      [name]: value,
    }))

    // Update the rowData
    setRowData((prevRowData) =>
      prevRowData.map((row) =>
        row.id === rowId
          ? { ...row, status: value ? 'Enable' : 'Disable' }
          : row
      )
    )

    // Update updateRowData
    setUpdateRowData((prevRowData) =>
      prevRowData?.id === rowId
        ? { ...prevRowData, status: value }
        : prevRowData
    )
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'mui-header',
      flex: 1,

      renderCell: ({ row }) => {
        const isInEditMode =
          rowModesData[row.id]?.mode === 'edit' && row.id === editingRowId
        const [rowId, setRowId] = useState(row.id)
        if (isInEditMode) {
          return (
            <div>
              <SwitchButton
                switchName={`switchValue${rowId}`}
                switchValue={checked ? checked[`switchValue${rowId}`] : null}
                onChangeHandler={(e, params) =>
                  SwitchHandler(e, params, row.id)
                }
                switchLabel={
                  checked
                    ? checked[`switchValue${rowId}`]
                      ? 'Enable'
                      : 'Disable'
                    : null
                }
              />
            </div>
          )
        }
        return (
          <div
            className={`${
              row?.status === 'Enable'
                ? 'agent-statusCell Enable'
                : 'agent-statusCell Disable'
            }`}
          >
            <div className="statusText">
              {row?.status === 'Enable' ? 'Enable' : 'Disable'}
            </div>
          </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      sortable: false,
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      type: 'actions',
      renderCell: ({ row }) => {
        const isInEditMode = rowModesData[row.id]?.mode === 'edit'
        // const isInEditMode = false
        if (isInEditMode) {
          return (
            <div className="actionIcons-container">
              <img
                onClick={handleSaveClick(row.id)}
                src={SaveIcon}
                className="SaveIcon"
                alt="SaveIcon"
              />
              <img
                onClick={handleCancelClick(row.id)}
                src={Cancel}
                className="CancelIcon"
                alt="CancelIcon"
              />
            </div>
          )
        }
        return (
          <div className="actionIcons-container">
            {editMode && (
              <img
                className="EditIcon"
                src={editIcon}
                onClick={handleEditClick(row)}
              />
            )}
          </div>
        )
      },
    },
  ]

  const AgentAddContent = () => {
    const [employment, setEmployment] = useState({ name: '' })
    const [htmlEditorRef, setHtmlEditorRef] = useState(null)
    const handleClose = () => {
      setPopup(false)
      setEmployment({ name: '' })
    }

    const handleAdd = async () => {
      const details = {
        ...employment,
        name: employment.name.trim(),
      }

      const errors = {}
      for (const key in employment) {
        if (key !== 'name') {
          if (
            employment[key] === '' ||
            employment[key] === null ||
            employment[key].length === 0
          ) {
            errors[key] = 'This field is required'
          }
        }
      }
      if (!details.name) {
        errors.name = 'Name is required'
      }
      /*Don't allow Duplicate value  */
      const nameExists = employmentList.some(
        (type) => type.name.trim().toLowerCase() === details.name.toLowerCase()
      )

      if (nameExists) {
        errors.name = 'An employment type with this name already exists'
      }

      if (Object.keys(errors).length === 0) {
        await addEmploymentType(details)
        setToastShown({ fieldsRequired: false, success: true })
        setToastOpen(true)
        setPopup(false)
      } else {
        // There are errors, show toast
        if (
          errors.name === 'An employment type with this name already exists'
        ) {
          setToastShown({
            fieldsRequired: false,
            success: false,
            duplicateName: true,
          })
        } else {
          setToastShown({
            fieldsRequired: true,
            success: false,
            duplicateName: false,
          })
        }
        setToastOpen(true)
      }
    }
    const onchangeHandler = (event, params, rest) => {
      const { id, value } = params
      setEmployment((prevInfo) => ({ ...prevInfo, [id]: value }))
    }
    const addEmploymentType = async () => {
      try {
        const response = await getData(
          APIEndPoints?.AddJobType?.method,
          APIEndPoints?.AddJobType?.url,
          {
            azureUserObjectID: azureID,
            name: employment?.name,
          }
        )
        setDocTypeAdded(!docTypeAdded)
        setLoading(false)
      } catch (error) {
        console.error('Error adding user:', error)
      }
    }
    useEffect(() => {
      htmlEditorRef?.current?.focus()
    }, [htmlEditorRef])

    return (
      <div className="add_employmentType">
        <div className="employmentTitle">Add Job Type</div>
        <TextBox
          label="Job Type Name"
          fieldName="name"
          value={employment?.name}
          onchangeHandler={onchangeHandler}
          setForwardRef={setHtmlEditorRef}
        />
        <div className="employmentAction">
          <ButtonComponent
            className="withoutBackground"
            text="Close"
            onClick={handleClose}
          />
          <ButtonComponent
            className="withBackground"
            text="ADD"
            variant="contained"
            onClick={handleAdd}
            disabled={
              !employment?.name.trim() || employment?.name.trim().length > 100
            }
          />
        </div>
      </div>
    )
  }

  const onAddHandler = () => {
    setPopup(true)
    setPopId('Add')
  }
  return (
    <div className="employmentContent">
      <div className="employmentHeader">Job Types</div>
      <div className="employmentContainer">
        <div>
          <SearchEngine
            value=""
            searchClear={searchValue}
            originalRows={employmentList}
            setRows={setFilteredRows}
            className="Usersearch-engine"
          />{' '}
        </div>
        {editMode && (
          <div>
            <ButtonComponent
              onClick={onAddHandler}
              className="withBackground"
              text="ADD"
              startIcon={<img src={AddOutlinedIcon} />}
            />
          </div>
        )}
      </div>
      <MuiGrid
        // className="jobGrid"
        rowData={filteredRows}
        columnData={columns}
        crudDetails={crudDetails}
        setCrudOperationStatus={setCrudOperationStatus}
        setRowModesData={setRowModesData}
        rowModesData={rowModesData}
        setRowData={setEmploymentList}
      />
      <div>
        {popup && (
          <Popup
            className="employmentPopup"
            popContent={
              popId === 'Add' ? (
                <AgentAddContent />
              ) : popId === 'Cancel' ? (
                <ConfirmationPopupContent />
              ) : popId === 'Save' ? (
                <ConfirmationSavePopupContent />
              ) : null
            }
          />
        )}
      </div>
      <div>
        <SidePopup
          className="employmentHistory_PopUp"
          anchorEl={anchorEl}
          placement={placement}
          dialogContent={historyData}
        />
      </div>
      <div>
        {' '}
        {toastOpen && toastShown.success && (
          <ToastMessage
            statusMassage="SUCCESS !"
            contentMessage="Record Added successfully"
          />
        )}
        {toastOpen && toastShown.fieldsRequired && (
          <ToastMessage
            statusMassage="Process Declined"
            contentMessage="All Fields are Required"
            unsuccessStatus
          />
        )}
        {toastOpen && toastShown.duplicateName && (
          <ToastMessage
            statusMassage="Process Declined"
            contentMessage="This name already exists"
            unsuccessStatus
          />
        )}
      </div>
    </div>
  )
}

export default JobType
