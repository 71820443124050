import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Popup from '../../../../components/base/popup/Popup'
import ButtonComponent from '../../../../components/base/button/Button'
import TimelineStep from '../../../../components/base/timeLine/TimelineStep'
import TextBox from '../../../../components/base/textBox/TextField'
import AutoComplete from '../../../../components/base/autoComplete/AutoComplete'
import MuiGrid from '../../../../components/base/grid/Grid'
import {
  aadObjectId,
  dialogPopUp,
  loader,
  loggedUserDetails,
  popOpen,
  toastMessage,
} from '../../../../components/recoil/atoms/atoms'
import EditIcon from '../../../../../assets/images/EditFilled.png'
import SaveIcon from '../../../../../assets/images/Save_Icon_Grid.svg'
import Cancel from '../../../../../assets/images/CancelIcon_Grid.png'
import '../Employment.css'
import fetchSelector from '../../../../components/recoil/selectors/selectors'
import useAPIEndpoints from '../../../../../models/api/apiEndpoints'
import AddOutlinedIcon from '../../../../../assets/images/addIconPlus.svg'
import SearchEngine from '../../../../components/base/searchBox/SearchBar'
import SwitchButton from '../../../../components/base/switch/SwitchButton'
import ToastMessage from '../../../../components/base/toastMessage/ToastMessage'

function CommunicationType() {
  const APIEndPoints = useAPIEndpoints()
  const [anchorEl, setAnchorEl] = useState(null)
  const [popup, setPopup] = useRecoilState(dialogPopUp)
  const [open, setOpen] = useRecoilState(popOpen)
  const getData = useRecoilValue(fetchSelector)
  const [loading, setLoading] = useRecoilState(loader)
  const [historyData, setHistoryData] = useState(null)
  const [rowData, setRowData] = useState([])
  const [communicationTypes, setCommunicationTypes] = useState([])
  const [filteredRows, setFilteredRows] = useState(communicationTypes)
  const [updateRowData, setUpdateRowData] = useState(null)
  const [toastShown, setToastShown] = useState({
    fieldsRequired: false,
    success: false,
    duplicateName: false,
  })
  const [toastOpen, setToastOpen] = useRecoilState(toastMessage)
  const [popId, setPopId] = useState()
  const [rowId, setRowId] = useState({})
  const [docTypeAdded, setDocTypeAdded] = useState(false)
  const azureID = useRecoilValue(aadObjectId)
  const [searchValue, setSearchValue] = useState('')
  const [category, setCategory] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [userRolesValues, setUserRolesValues] = useState([])
  const [loggedUserData, setLoggedUserData] = useRecoilState(loggedUserDetails)
  useEffect(() => {
    if (loggedUserData) {
      const rolesArray = loggedUserData[0]?.roles?.map((role) => role?.aadValue)
      const userValues = rolesArray?.filter(
        (value) => value.includes('Config') || value.includes('Admin')
      )
      setUserRolesValues(userValues)
    }
  }, [loggedUserData])

  useEffect(() => {
    const rolesToCheck = [
      'Magnus.Admin',
      'Config.CaseManagement.ReadWrite',
      'Config.All.ReadWrite',
    ]
    const hasEditMode = userRolesValues.some((filterValues) =>
      rolesToCheck.includes(filterValues)
    )
    setEditMode(hasEditMode)
  }, [userRolesValues])
  useEffect(() => {
    setLoading(true)
    const getCommunicationTypes = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetCommunicationTypes.method,
          APIEndPoints.GetCommunicationTypes.url
        )
        setLoading(false)
        setCommunicationTypes(
          response?.data
            ?.sort((a, b) => a?.name?.trim().localeCompare(b?.name?.trim()))
            ?.map((value) => ({
              id: value?.id,
              name: value?.name,
              categoryID: value?.categoryID,
              categoryName: value?.categoryName,
              status: value?.enabled ? 'Enable' : 'Disable',
            }))
        )
        const switchValues = {}
        response.data?.forEach((value) => {
          switchValues[`switchValue${value?.id}`] = value?.enabled
        })
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }
    const getCategory = async () => {
      try {
        const response = await getData(
          APIEndPoints.GetCommunicationCategories.method,
          APIEndPoints.GetCommunicationCategories.url
        )
        setLoading(false)
        setCategory(
          response.data?.map((value) => ({
            id: value?.id,
            name: value?.name,
            status: value?.enabled ? 'Enable' : 'Disable',
          }))
        )
        const switchValues = {}
        response.data?.forEach((value) => {
          switchValues[`switchValue${value?.id}`] = value?.enabled
        })
      } catch (error) {
        console.error('Error occurred while fetching vendor status:', error)
      }
    }
    getCommunicationTypes()
    getCategory()
  }, [docTypeAdded])
  const categoryData = category?.map((category) => {
    return {
      title: category?.name,
      categoryID: category?.id,
    }
  })
  useEffect(() => {
    const closeDialogOnOutsideClick = (event) => {
      if (open && !event.target.closest('.dialog')) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', closeDialogOnOutsideClick)
    return () => {
      document.removeEventListener('mousedown', closeDialogOnOutsideClick)
    }
  }, [open, setOpen])
  useEffect(() => {
    const rows = communicationTypes?.map((communicationTypes) => {
      return {
        id: communicationTypes?.id,
        name: communicationTypes?.name,
        categoryID: communicationTypes?.categoryID,
        categoryName: communicationTypes?.categoryName,
        status: communicationTypes?.status,
      }
    })
    setRowData(rows)
  }, [communicationTypes])
  const [rowModesData, setRowModesData] = useState({})
  const [editRowID, setEditRowID] = useState(null)
  const [placement, setPlacement] = React.useState()
  const handleEditClick = (row) => () => {
    const { status } = row
    if (popId === 'edit') {
      setPopup(true)
      setPopId('Cancel')
    } else {
      setUpdateRowData((prev) => ({
        ...prev,
        ...row,
        status: status === 'Disable' ? false : true,
      }))
      setPopId('edit')
      setSearchValue('')
    }
  }
  const ConfirmationSavePopupContent = () => {
    const id = rowId?.id
    const handleSave = async () => {
      try {
        const updatedRow = {
          ...updateRowData,
          status: updateRowData?.status,
        }
        const response = await getData(
          APIEndPoints.UpdateCommunicationTypes.method,
          APIEndPoints.UpdateCommunicationTypes.url,
          {
            azureUserObjectID: azureID,
            communicationTypeID: updatedRow?.id,
            enabled: updatedRow?.status,
          }
        )
        if (response?.status === 200 && response?.statusText === 'OK') {
          setCommunicationTypes((prevList) =>
            prevList.map((item) =>
              item.id === updatedRow.id ? updatedRow : item
            )
          )
          setDocTypeAdded(!docTypeAdded)
          setLoading(false)
          setPopup(false)
          setPopId(null)
          setSearchValue('ClearSearch')
        } else {
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.error('Error updating employment type:', error)
      }
    }
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Confirm</div>
        <div>Are you sure to save ?</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => setPopup(false)}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={handleSave}
          />
        </div>
      </div>
    )
  }
  const handleSaveClick = (id) => () => {
    setPopup(true)
    setPopId('Save')
    setRowId(id)
  }
  useEffect(() => {
    let foundRow = null
    if (rowData && editRowID) {
      foundRow = rowData.find((value) => value?.id === editRowID)
    }
    if (foundRow) {
      const updatedRow = {
        ...foundRow,
        status: foundRow?.status,
      }
      setUpdateRowData(updatedRow)
    }
  }, [rowData, editRowID])
  const ConfirmationPopupContent = () => {
    const id = rowId?.id
    return (
      <div className="goBackConfirm-Container">
        <div className="confirmHeader">Confirm</div>
        <div>Are you sure to cancel ?</div>
        <div className="cancelConfirm-Btn">
          <ButtonComponent
            className="withoutBackground"
            text="CANCEL"
            onClick={() => setPopup(false)}
          />
          <ButtonComponent
            className="withBackground"
            text="CONFIRM"
            onClick={() => {
              setPopup(false)
              setPopId(null)
            }}
          />
        </div>
      </div>
    )
  }
  const handleCancelClick = (id) => () => {
    setPopup(true)
    setRowId(id)
    setPopId('Cancel')
  }
  const timelineHistory = (newPlacement, id) => (event) => {
    viewHistory(id)
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }
  const SwitchHandler = (e, params, rowId) => {
    const { name, value } = params
    setUpdateRowData((prevRowData) => ({ ...prevRowData, status: value }))
  }
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      hideable: false,
      maxWidth: 150,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'categoryName',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'mui-header',
      flex: 1,
      minWidth: 150,

      renderCell: ({ row }) => {
        const isInEditMode =
          updateRowData?.id === row?.id &&
          (popId === 'edit' || popId === 'Cancel' || popId === 'Save')
        if (isInEditMode) {
          return (
            <div>
              <SwitchButton
                switchValue={updateRowData?.status}
                onChangeHandler={(e, params) =>
                  SwitchHandler(e, params, row.id)
                }
                switchLabel={updateRowData?.status ? 'Enable' : 'Disable'}
              />
            </div>
          )
        }
        return (
          <div
            className={`${
              row?.status === 'Enable'
                ? 'agent-statusCell Enable'
                : 'agent-statusCell Disable'
            }`}
          >
            <div className="statusText">{row?.status}</div>
          </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      sortable: false,
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      type: 'actions',
      renderCell: ({ row }) => {
        const isInEditMode =
          updateRowData?.id === row?.id &&
          (popId === 'edit' || popId === 'Cancel' || popId === 'Save')
        if (isInEditMode) {
          return (
            <div className="actionIcons-container">
              <img
                onClick={handleSaveClick(row)}
                src={SaveIcon}
                className="SaveIcon"
                alt="SaveIcon"
              />
              <img
                onClick={handleCancelClick(row)}
                src={Cancel}
                className="CancelIcon"
                alt="CancelIcon"
              />
            </div>
          )
        }
        return (
          <div className="actionIcons-container">
            {editMode && (
              <img
                className="EditIcon"
                src={EditIcon}
                onClick={handleEditClick(row)}
              />
            )}
          </div>
        )
      },
    },
  ]
  const AgentAddContent = () => {
    const [addCategory, setAddCategory] = useState({
      name: '',
      categoryID: null,
      categoryName: null,
    })
    const [error, setError] = useState()
    const [htmlEditorRef, setHtmlEditorRef] = useState(null)
    const handleClose = () => {
      setPopup(false)
      setAddCategory({
        name: '',
        categoryName: null,
      })
    }
    const onchangeHandler = (event, params, rest) => {
      const { id, value } = params
      setAddCategory((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }))
      setError((prevError) => ({
        ...prevError,
        [id]: value === '',
      }))
    }
    const checkForEmptyFields = () => {
      const ErrorState = {}
      for (const key in addCategory) {
        ErrorState[key] =
          addCategory[key] === '' ||
          addCategory[key] === null ||
          (typeof addCategory[key] === 'string' &&
            addCategory[key].trim().length === 0)
      }
      setError(ErrorState)

      return Object.values(ErrorState).some((value) => value === true)
    }
    const handleAdd = async () => {
      const hasErrors = checkForEmptyFields()
      if (!hasErrors) {
        addCommunication()
      }
    }
    const addCommunication = async () => {
      setLoading(true)
      const isNamePresent = filteredRows.some(
        (obj) =>
          obj.name.trim().toLowerCase() === addCategory?.name.toLowerCase()
      )
      if (isNamePresent) {
        setToastOpen(true)
        setLoading(false)
        return setToastShown({
          fieldsRequired: false,
          success: false,
          duplicateName: true,
        })
      }
      try {
        const response = await getData(
          APIEndPoints.AddCommunicationTypes.method,
          APIEndPoints.AddCommunicationTypes.url,
          {
            azureUserObjectID: azureID,
            name: addCategory?.name,
            categoryID: addCategory?.categoryID,
          }
        )
        if (response?.status === 200 && response?.statusText === 'OK') {
          setToastOpen(true)
          setDocTypeAdded(!docTypeAdded)
          handleClose()
          return setToastShown({
            fieldsRequired: false,
            success: true,
            duplicateName: false,
          })
        } else {
          setToastOpen(true)
          return setToastShown({
            fieldsRequired: false,
            success: false,
            duplicateName: false,
          })
        }
      } catch (error) {
        setLoading(false)
        console.error('Error adding user:', error)
      }
      setToastOpen(true)
      setDocTypeAdded(!docTypeAdded)
      handleClose()
    }

    const categoryAutoComplete = (event, params) => {
      setAddCategory((prev) => ({
        ...prev,
        categoryID: params?.categoryID,
        categoryName: params,
      }))
      setError((prevError) => ({
        ...prevError,
        categoryName: false,
      }))
    }

    useEffect(() => {
      htmlEditorRef?.current?.focus()
    }, [htmlEditorRef])
    return (
      <div className="add_employmentType">
        <div className="employmentTitle">Add Communication Type</div>
        <TextBox
          label="Name"
          fieldName="name"
          value={addCategory?.name}
          onchangeHandler={onchangeHandler}
          error={error?.name}
          setForwardRef={setHtmlEditorRef}
        />
        <AutoComplete
          placeholder="Select"
          options={categoryData}
          label="Category"
          selectChangeHandler={categoryAutoComplete}
          initialValue={addCategory?.categoryName}
          error={error?.categoryName}
        />
        <div className="employmentAction">
          <ButtonComponent
            className="withoutBackground"
            text="Close"
            onClick={handleClose}
          />
          <ButtonComponent
            className="withBackground"
            text="ADD"
            variant="contained"
            onClick={handleAdd}
          />
        </div>
      </div>
    )
  }
  const viewHistory = async (detailsId) => {
    const getEmploymentTypeDetails = await getData(
      APIEndPoints.GetDistributionTypeDetails.method,
      ` ${APIEndPoints.GetDistributionTypeDetails.url}?distributionTypeID=${detailsId}`
    )
    const details = getEmploymentTypeDetails?.data
    const steps = details?.audits?.map((audits) => {
      let updatedMessage = audits.message
      if (updatedMessage.includes('(Old Value): 0')) {
        updatedMessage = updatedMessage.replace(
          '(Old Value): 0',
          '(Old Value): Disabled'
        )
      } else if (updatedMessage.includes('(Old Value): 1')) {
        updatedMessage = updatedMessage.replace(
          '(Old Value): 1',
          '(Old Value): Enabled'
        )
      }
      if (updatedMessage.includes('(New Value): 0')) {
        updatedMessage = updatedMessage.replace(
          '(New Value): 0',
          '(New Value): Disabled'
        )
      } else if (updatedMessage.includes('(New Value): 1')) {
        updatedMessage = updatedMessage.replace(
          '(New Value): 1',
          '(New Value): Enabled'
        )
      }
      return {
        dateValue: new Date(audits?.createdOn).toISOString().split('T')[0],
        updatedUserName: `Name:- ${audits?.createdBy} `,
        userMessage: `Message:- ${updatedMessage}`,
      }
    })
    return setHistoryData(
      <div className="employmentHistory">
        <TimelineStep
          timelineHead="History / Audit Log"
          timelineContent={steps}
        />
      </div>
    )
  }
  const onAddHandler = () => {
    setPopId('Add')
    setPopup(true)
  }
  return (
    <div className="employmentContent">
      <div className="employmentHeader">Communication Types</div>
      <div className="employmentContainer">
        <div>
          <SearchEngine
            value=""
            searchClear={searchValue}
            originalRows={communicationTypes}
            setRows={setFilteredRows}
            className="Usersearch-engine"
          />
        </div>
        {editMode && (
          <div>
            <ButtonComponent
              onClick={onAddHandler}
              className="withBackground"
              text="ADD"
              startIcon={<img src={AddOutlinedIcon} />}
            />
          </div>
        )}
      </div>
      <MuiGrid
        // className="jobGrid"
        rowData={filteredRows}
        columnData={columns}
        setRowModesData={setRowModesData}
        rowModesData={rowModesData}
        setRowData={setCommunicationTypes}
      />
      <div>
        {popup && (
          <Popup
            className="employmentPopup"
            popContent={
              popId === 'Add' ? (
                <AgentAddContent />
              ) : popId === 'Cancel' ? (
                <ConfirmationPopupContent />
              ) : popId === 'Save' ? (
                <ConfirmationSavePopupContent />
              ) : null
            }
          />
        )}
      </div>
      <div>
        {' '}
        {toastOpen && toastShown.success && (
          <ToastMessage
            statusMassage="SUCCESS !"
            contentMessage="Record Added successfully"
          />
        )}
        {toastOpen && toastShown.fieldsRequired && (
          <ToastMessage
            statusMassage="Process Declined"
            contentMessage="All Fields are Required"
            unsuccessStatus
          />
        )}
        {toastOpen && toastShown.duplicateName && (
          <ToastMessage
            statusMassage="Process Declined"
            contentMessage="This name already exists"
            unsuccessStatus
          />
        )}
      </div>
    </div>
  )
}

export default CommunicationType
