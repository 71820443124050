import React, { useState, useEffect } from 'react'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import './SearchBox.css'

function SearchEngine({
  value,
  onRequestSearch,
  onCancelSearch,
  originalRows,
  setRows,
  className,
  doubleSearch,
  searchClear,
}) {
  const [searchTerm, setSearchTerm] = useState('')

  const [cancelClicked, setCancelClicked] = useState(false)
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      // onRequestSearch(searchTerm)
      searchTerm?.length > 0 || cancelClicked ? requestSearch(searchTerm) : null
    }, 300) // Adjust the debounce timeout as needed
    return () => clearTimeout(debounceTimeout)
  }, [searchTerm])

  useEffect(() => {
    !searchTerm && setRows(originalRows)
  }, [searchTerm])

  useEffect(() => {
    !doubleSearch && setRows(originalRows)
  }, [originalRows])

  useEffect(() => {
    if (searchClear === 'ClearSearch') {
      setSearchTerm('')
    }
  }, [searchClear])

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value)
  }
  const requestSearch = (searchedVal) => {
    const filteredRows = originalRows?.filter((row) => {
      for (const key in row) {
        if (
          row[key]?.toString().toLowerCase().includes(searchedVal.toLowerCase())
        ) {
          return true
        }
      }
      return false
    })

    searchTerm?.length > 0 || !cancelClicked
      ? setRows(filteredRows)
      : setRows(originalRows)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    requestSearch(searchTerm)
  }
  return (
    <div>
      <Paper
        component="form"
        className="searchEngine"
        onSubmit={handleSubmit}
        // sx={{ display: 'flex', alignItems: 'center', width: 300 }}
      >
        <IconButton sx={{ p: '10px' }} aria-label="menu">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleInputChange}
          className={className}
          inputProps={{ 'aria-label': 'searchData' }}
        />
        <div className={`${!searchTerm ? 'hideClearIcon' : 'searchClearIcon'}`}>
          <ClearIcon
            onClick={() => {
              setCancelClicked(true)
              setSearchTerm('')
            }}
          />
        </div>
      </Paper>
    </div>
  )
}

export default SearchEngine
