import React, { useEffect, useRef } from 'react'
import TextField from '@mui/material/TextField'
import './TextField.css'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#dde1e6',
            '--TextField-brandBorderHoverColor': '#dde1e6',
            '--TextField-brandBorderFocusedColor': '#dde1e6',
            '--TextField-brandBorderErrorColor': '#d32f2f',
            '.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            '&.Mui-error label': {
              color: 'red !important',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
            [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderErrorColor)',
            },
          },
        },
      },
    },
  })
const TextBox = ({
  value,
  onchangeHandler,
  error,
  label,
  className,
  errorText,
  placeholder,
  maxRows,
  multiline,
  rows,
  fieldName,
  inputProps,
  disabled,
  readOnly,
  required,
  maxLength,
  passWordField,
  showPassword,
  handleClickShowPassword,
  setForwardRef,
  ...rest
}) => {
  const outerTheme = useTheme()
  const textfieldChangeHandler = (event) => {
    const params = {
      event,
      id: fieldName,
      value:
        rest?.type === 'number'
          ? event.target.value !== ''
            ? Number(event.target.value)
            : ''
          : event.target.value,
    }
    if (onchangeHandler) {
      onchangeHandler(event, params, rest, maxLength)
    }
  }
  const inputRef = useRef(null)
  useEffect(() => {
    if (setForwardRef) setForwardRef(inputRef)
  }, [])
  return (
    <div
      className={`TextFieldParent ${error && 'TextFieldError'} ${className}`}
    >
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          multiline={multiline}
          label={label}
          variant="outlined"
          fullWidth
          value={value}
          onChange={(event) => textfieldChangeHandler(event)}
          error={error}
          helperText={error && errorText && errorText}
          focused
          placeholder={placeholder}
          maxRows={maxRows}
          size="small"
          rows={rows}
          autoComplete="off"
          type={rest?.type || null}
          // InputLabelProps={{ shrink: disabled }}
          InputProps={{
            inputRef: inputRef,
            readOnly: readOnly,
            endAdornment: passWordField && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOff className="eyeIcon" />
                  ) : (
                    <Visibility className="eyeIcon" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required={required}
          inputProps={inputProps}
          disabled={disabled}
          // onInput={(e) => {
          //   e.target.value = Math.max(0, parseInt(e.target.value))
          //     .toString()
          //     .slice(0, maxLength)
          // }}
        />
      </ThemeProvider>
    </div>
  )
}

export default TextBox
