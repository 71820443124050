// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--bodyBackgroundColor);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
}
.main_page {
  padding: 10px 10px 10px 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #80808082;
}
.MuiInputBase-input {
  font-size: var(--default-font-size) !important;
}
legend {
  font-size: 9px !important;
}
.MuiInputLabel-shrink {
  top: 4px !important;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  height: 18px;
}
.inActive-content--main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  height: 100vh;
}
.docPreviewerIcon {
  height: 18px;
  width: 20px;
}
.docPreviewerIcon:hover {
  cursor: pointer;
}
.searchClearIcon {
  display: flex;
  align-items: center;
}
.popup_header {
  font-weight: 600;
  font-size: var(--sub-title-FontSize);
  color: var(--grid-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;AACxB;AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,4CAA4C;EAC5C,uCAAuC;EACvC,mCAAmC;AACrC;AACA;EACE,2BAA2B;EAC3B,SAAS;EACT,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,8CAA8C;AAChD;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,6BAA6B;AAC/B","sourcesContent":["*,\n::after,\n::before {\n  box-sizing: border-box;\n}\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  background-color: var(--bodyBackgroundColor);\n  font-family: var(--default-font-family);\n  font-size: var(--default-font-size);\n}\n.main_page {\n  padding: 10px 10px 10px 5px;\n  gap: 10px;\n  display: flex;\n  flex-direction: column;\n}\n::-webkit-scrollbar {\n  width: 2px;\n  height: 2px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #80808082;\n}\n.MuiInputBase-input {\n  font-size: var(--default-font-size) !important;\n}\nlegend {\n  font-size: 9px !important;\n}\n.MuiInputLabel-shrink {\n  top: 4px !important;\n}\nsvg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {\n  height: 18px;\n}\n.inActive-content--main {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  justify-content: center;\n  height: 100vh;\n}\n.docPreviewerIcon {\n  height: 18px;\n  width: 20px;\n}\n.docPreviewerIcon:hover {\n  cursor: pointer;\n}\n.searchClearIcon {\n  display: flex;\n  align-items: center;\n}\n.popup_header {\n  font-weight: 600;\n  font-size: var(--sub-title-FontSize);\n  color: var(--grid-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
