// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delegatePopup .popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  outline: none;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root.dialogTitle {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/popper/SidePopup.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,2CAA2C;EAC3C,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".delegatePopup .popup-container {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: auto;\n  height: auto;\n  background-color: white;\n  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n  outline: none;\n  display: flex;\n  /* justify-content: center; */\n  /* align-items: center; */\n}\n\nh2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root.dialogTitle {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
